import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

// EXPORTING VALUES TO CSV FORMAT PENDING
export function createCSV(filename, arrData) {
    try {
        var message = ""
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
            Object.keys(arrData[0]).join(","),
            ...arrData.map(item => Object.values(item).join(","))
        ]
            .join("\n")
            .replace(/(^\[)|(\])/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", filename);
        link.click();
        message = "Downloading the CSV"
    }
    catch (error) {
        message = error
    }
    return message
}

// EXPORTING CHART TO PDF
export function createPDF2(filename, content) {
    try {
        var message = ""
        var doc = new jsPDF("l", "pt", "a3", true);
        html2canvas(content).then(function (canvas) {
            const img = canvas.toDataURL("image/jpeg", 1);
            // doc.text("Hello World", 10, 10);
            doc.addImage(img, "JPEG", 30, 30);
            doc.save(filename);
        });
        message = "Downloading the PDF"
    }
    catch (error) {
        message = error
    }
    return message
}

// EXPORTING CHART TO PDF
export function createPDF(filename, text, content, content1 = null, content2 = null, isTableSecond = false, contentHeader = null) {
    try {

        var message = ""
        var doc = new jsPDF("l", "pt", "a3");
        // doc.setFont("Calibri");
        doc.fromHTML(text, 15, 15, {
            'width': 900
        });
        doc.addPage();

        html2canvas(content).then(function (canvas) {
            const img = canvas.toDataURL("image/jpeg", 1);
            doc.addImage(img, "JPEG", 50, 50);

            if (content1 != null && !isTableSecond) {
                html2canvas(content1).then(function (canvas1) {
                    const img1 = canvas1.toDataURL("image/jpeg", 1);
                    doc.addImage(img1, "JPEG", 50, canvas.height - 80);

                    if (content2 != null) {
                        doc.addPage();
                        html2canvas(content2).then(function (canvas2) {
                            const img2 = canvas2.toDataURL("image/jpeg", 1);
                            doc.addImage(img2, "JPEG", 50, 50);
                            doc.save(filename);
                        });
                    }
                    else {
                        doc.save(filename);
                    }
                    //doc.save(filename);
                });
            }
            else if (isTableSecond && content2 != null) {
                if (content1 != null) {
                    //doc.addPage();
                    html2canvas(content1).then(function (canvasDiv) {
                        const imgDiv = canvasDiv.toDataURL("image/jpeg", 1);
                        doc.addImage(imgDiv, "JPEG", 50, canvas.height + 100);

                        doc.addPage();
                        var data = doc.autoTableHtmlToJson(document.getElementById('table'));
                        doc.autoTable(data.columns, data.rows, {
                            tableLineWidth: 0.75,
                            startY: 100
                        });

                        doc.addPage();
                        if (contentHeader != null) {

                            html2canvas(contentHeader).then(function (canvasHeader) {
                                const imgDivHeader = canvasHeader.toDataURL("image/jpeg", 1);
                                doc.addImage(imgDivHeader, "JPEG", 50, canvas.height + 30);

                                var data2 = doc.autoTableHtmlToJson(document.getElementById('table2'));

                                doc.autoTable(data2.columns, data2.rows, {
                                    tableLineWidth: 0.75,
                                    startY: canvasHeader.height + 150,
                                    drawCell: function (cell, opts) {
                                        if (opts >= 200)
                                            cell.styles.fillColor = '#add8e6';

                                    }
                                });

                                doc.save(filename);
                            });
                        }
                    });
                }
                else {
                    doc.addPage();
                    var data = doc.autoTableHtmlToJson(document.getElementById('table'));
                    doc.autoTable(data.columns, data.rows, {
                        tableLineWidth: 0.75,
                        startY: 100
                    });
                    doc.save(filename);
                }
            }
            else {
                doc.save(filename);
            }

        });

        message = "Downloading the PDF"
    }
    catch (error) {
        message = error
    }
    return message
}

// EXPORTING CHART TO PDF
export function createSitePDF(filename, text, content, content1, content2, content3) {
    try {

        var message = ""
        var doc = new jsPDF("l", "pt", "a3");
        // doc.setFont("Calibri");
        doc.fromHTML(text, 15, 15, {
            'width': 900
        });
        doc.addPage();

        html2canvas(content).then(function (canvas) {
            const img = canvas.toDataURL("image/jpeg", 1);
            doc.addImage(img, "JPEG", 50, 50);

            if (content1 != null) {
                html2canvas(content1).then(function (canvas1) {
                    const img1 = canvas1.toDataURL("image/jpeg", 1);
                    doc.addImage(img1, "JPEG", 50, canvas.height - 80);

                    if (content2 != null) {
                        doc.addPage();
                        html2canvas(content2).then(function (canvas2) {
                            const img2 = canvas2.toDataURL("image/jpeg", 1);
                            doc.addImage(img2, "JPEG", 50, 50);
                            //doc.save(filename);
                            if (content2 != null) {
                                html2canvas(content3).then(function (canvas3) {
                                    const img3 = canvas3.toDataURL("image/jpeg", 1);
                                    doc.addImage(img3, "JPEG", 50, canvas2.height);
                                    doc.save(filename);
                                });
                            }
                            else
                                doc.save(filename);
                        });
                    }
                    else {
                        doc.save(filename);
                    }
                    //doc.save(filename);
                });
            }
            else {
                doc.save(filename);
            }

        });

        message = "Downloading the PDF"
    }
    catch (error) {
        message = error
    }
    return message
}


export function createHomePDF(filename, text, boxes, content, contentHeader) {
    try {

        var message = ""
        var doc = new jsPDF("l", "pt", "a3");
        // doc.setFont("Calibri");
        doc.fromHTML(text, 15, 15, {
            'width': 900
        });
        doc.addPage();

        html2canvas(boxes,{
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            windowWidth: document.documentElement.scrollWidth,
            windowHeight: document.documentElement.scrollHeight,
        }).then(function (canvas) {
            const imgbox = canvas.toDataURL("image/jpeg", 1);
            doc.addImage(imgbox, "JPEG", 50, 50);

            html2canvas(content,{
                scrollX: -window.scrollX,
                scrollY: -window.scrollY,
                windowWidth: document.documentElement.scrollWidth,
                windowHeight: document.documentElement.scrollHeight,
            }).then(function (canvasContent) {
                const imgContent = canvasContent.toDataURL("image/jpeg", 1);
                doc.addImage(imgContent, "JPEG", 50, canvas.height + 120);
                //doc.save(filename);
                doc.addPage();
                var data = doc.autoTableHtmlToJson(document.getElementById('table'));
                doc.autoTable(data.columns, data.rows, {
                    tableLineWidth: 0.75,
                    startY: 100
                });

                doc.addPage();
                html2canvas(contentHeader).then(function (canvasHeader) {
                    const imgDivHeader = canvasHeader.toDataURL("image/jpeg", 1);
                    doc.addImage(imgDivHeader, "JPEG", 30, 50);

                    var dataTable2 = doc.autoTableHtmlToJson(document.getElementById('table2'));
                    doc.autoTable(dataTable2.columns, dataTable2.rows, {
                        tableLineWidth: 0.75,
                        startY: 100,
                        didParseCell: function (cell) {
                            if (cell.section == 'body') {
                                // if (cell.cell.text[0] <= 200 && cell.cell.text[0] != '' && cell.column.index > 0) {
                                //     cell.cell.styles.fillColor = [173, 216, 230];
                                // }
                                if (cell.cell.text[0] && cell.cell.text[0] != '' && cell.column.index > 0) {
                                    if (cell.cell.text[0] >= 200 && cell.cell.text[0] <= 599) {
                                        cell.cell.styles.fillColor = [173, 216, 230];
                                    }
                                    else if (cell.cell.text[0] >= 600) {
                                        cell.cell.styles.fillColor = [67, 105, 117];
                                    }
                                }
                            }
                        }
                    });
                    doc.save(filename);
                });
            });
        });
        message = "Downloading the PDF"
    }
    catch (error) {
        message = error
    }
    return message
}

export function createZonePDF(filename, text, content, content1) {
    try {

        var message = ""
        var doc = new jsPDF("l", "pt", "a3");
        // doc.setFont("Calibri");
        doc.fromHTML(text, 15, 15, {
            'width': 900
        });
        doc.addPage();

        html2canvas(content).then(function (canvas) {
            const img = canvas.toDataURL("image/jpeg", 1);
            doc.addImage(img, "JPEG", 50, 50);

            if (content1 != null) {
                html2canvas(content1).then(function (canvas1) {
                    const img1 = canvas1.toDataURL("image/jpeg", 1);
                    doc.addImage(img1, "JPEG", 50, canvas.height - 80);
                    doc.save(filename);
                });
            }
            else {
                doc.save(filename);
            }

        });
        message = "Downloading the PDF"
    }
    catch (error) {
        message = error
    }
    return message
}

export function createGatePDF(filename, text, content, content1, contentHeader) {
    try {

        var message = ""
        var doc = new jsPDF("l", "pt", "a3");
        // doc.setFont("Calibri");
        doc.fromHTML(text, 15, 15, {
            'width': 900
        });
        doc.addPage();

        html2canvas(content).then(function (canvas) {
            const img = canvas.toDataURL("image/jpeg", 1);
            doc.addImage(img, "JPEG", 50, 50);

            if (content1 != null) {
                html2canvas(content1).then(function (canvas1) {
                    const img1 = canvas1.toDataURL("image/jpeg", 1);
                    doc.addImage(img1, "JPEG", 50, canvas.height - 80);

                    doc.addPage();

                    html2canvas(contentHeader).then(function (canvasHeader) {
                        const imgDivHeader = canvasHeader.toDataURL("image/jpeg", 1);
                        doc.addImage(imgDivHeader, "JPEG", 50, 50);
                        var images = [];
                        var data = doc.autoTableHtmlToJson(document.querySelector("#summaryTable table"));
                        doc.autoTable(data.columns, data.rows, {
                            tableLineWidth: 0.75,
                            startY: 100,
                            didDrawCell: function (cell) {
                                var idx = cell.column.index;
                                var imagsource = "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAAWJLR0QAiAUdSAAAAAd0SU1FB+QCGwkiNDUO+dAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDItMjdUMDk6MzQ6NTIrMDA6MDCZDttvAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAyLTI3VDA5OjM0OjUyKzAwOjAw6FNj0wAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACySURBVFhH7ZdBDoQgEAQHX443fbXbveGABpEZFQ5OJQYFTNWJaNiADGRK4zA8wAM8YJJ1FQlhzDXPIjyItmXhadT3ivGvxl2iZ0SSEzxl9IjI5AQzB96MOMgJZgu8EVGQE6yc8GTEiZxgtcITERU5wY4L7kRcyAl2NWCJaJAT7GxEE9EoJ9itoCVCISd4Q0ktQikneMtAKcIgJ7YAkkcY5eTeZ3mM+9GA/xd4gAd8PUDkB7PPTG38invWAAAAAElFTkSuQmCC"
                                if (cell.section == 'body' && (idx == 2 || idx == 3 || idx == 5 || idx == 6)) {                                                                       
                                    if (parseInt(cell.cell.text[0]) > 0) {                                        
                                        imagsource = "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAAWJLR0QAiAUdSAAAAAd0SU1FB+QCGwkgKBM5xx0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDItMjdUMDk6MzI6NDArMDA6MDDPJbqfAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAyLTI3VDA5OjMyOjQwKzAwOjAwvngCIwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADHSURBVFhH7dc7DoJAFIXho27MXUHHULImTdRiVuImKExQyByjyDBzL6/C+RNCoDkfFCTsmlfYsL07b1YCJEACTAKYq0F+zt2VsvZDpKm4FA0MuiM7Ze6uPNUbaJ+8vJXuCqhspX4TYkB/nGkRIoBvnGkQ0YDQOJMiogCx40yCCAKk4ywWMQrQjrMYhBcwdZyFEIOAucbZGOIHMPc48yG+AEuNsyHEG7D0OOsjOsBa4+wTccARZs1xZu8W9aNG+i9IgAT4dwDwBIHA5r7EWuzTAAAAAElFTkSuQmCC";
                                    }
                                    images.push({ image: imagsource, x: cell.cell.x, y: cell.cell.y })
                                }
                            },
                            didDrawPage: function () {

                                for (var i = 0; i < images.length; i++) {
                                    doc.addImage(images[i].image, images[i].x - 10, images[i].y, 10, 10);
                                }
                            }
                        });
                        doc.save(filename);
                    });

                });
            }
            else {
                doc.save(filename);
            }

        });
        message = "Downloading the PDF"
    }
    catch (error) {
        message = error
    }
    return message
}
