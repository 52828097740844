import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
window.axios = axios


const state = {
    sites: [],
    zones: [],
    selectedSites: [],
    selectedZones: [],
    consolidation: "dayOfMonth",
    dates: [],
    dates2: [],
    operationalHour: false
};
const mutations = {
    sites(state, value) {
        state.sites = value;
    },
    zones(state, value) {
        state.zones = value;
    },
    selectedSites(state, value) {
        state.selectedSites = value;
    },
    selectedZones(state, value) {
        state.selectedZones = value;
    },
    consolidation(state, value) {
        state.consolidation = value;
    },
    dates(state, value) {
        state.dates = value;
    },
    dates2(state, value) {
        state.dates2 = value;
    },
    operationalHour(state, value) {
        state.operationalHour = value;
    }
};
const getters = {
    getSiteIds: state => {
        return state.sites.map(site => {
            return site._id
        })
    },
    getZoneBySite: (state) => (siteIds) => {
        var filterZones = []
        state.zones.forEach(zone => {
            siteIds.forEach(siteId => {
                if (zone["siteId"] == siteId || zone["siteId"] == siteId.value) {
                    filterZones.push(zone);
                }
            })
        });
        return filterZones.filter(zone => zone.zoneName != "Master Zone")
    },
    selectedPeriodDate: state => {
        var dates = []
        var startDate = moment(state.dates[0])
            .format("YYYY-MM-DD") + " 00:00:00";

        var endDate = moment(state.dates[1])
            .format("YYYY-MM-DD") + " 23:59:59";

        // startDate = moment(startDate).add(-4, 'hours').format("YYYY-MM-DD HH:mm:ss");

        dates.push(startDate)
        dates.push(endDate)
        return dates
    },
    comparePeriodDate: state => {
        var dates = []
        // var startDate = moment(state.dates2[0])
        //     .subtract(1, "days")
        //     .format("YYYY-MM-DD") + " 21:00:00";

        // var date = new Date(state.dates2[0]);
        // var retDate = new Date(state.dates2[0]);
        // retDate.setYear(retDate.getFullYear() - 1);

        // var diff = date.getDay() - retDate.getDay();
        // retDate.setDate(retDate.getDate() + diff);

        // var startDate = moment(retDate)
        //     .format("YYYY-MM-DD") + " 21:00:00";

        var startDate = state.dates2[0] + " 00:00:00";
        var endDate = state.dates2[1] + " 23:59:59";
        dates.push(startDate)
        dates.push(endDate)
        return dates
    },
    priorPeriodDate: () => {
        //eslint-disable-next-line        
        var dates = []
        var startDate = moment()
            .subtract(2, "week")
            .format("YYYY-MM-DD") + " 00:00:00";

        var endDate = moment()
            .subtract(8, "days")
            .format("YYYY-MM-DD") + " 23:59:59";
        dates.push(startDate)
        dates.push(endDate)
        return dates
    },
    priorYearDate: state => {
        var dates = [];
        // var startDate = moment(state.dates2[0]).subtract(7, "days").format("YYYY-MM-DD") + " 01:00:00";
        // var endDate = moment(state.dates2[0]).subtract(1, "days").format("YYYY-MM-DD") + " 21:00:00";
        var startDate = state.dates2[0] + " 00:00:00";
        var endDate = state.dates2[1] + " 23:59:59";
        dates.push(startDate)
        dates.push(endDate)
        return dates;
    },
    selectedSites: state => {
        return state.sites.filter(function (site) {
            return state.selectedSites.includes(site._id);
        });
    },
    selectedZones: state => {
        return state.zones.filter(function (zone) {
            return state.selectedZones.includes(zone._id);
        });
    }

};
const actions = {
    // GET ALL THE SITES BY THE USER
    async getSites({
        commit
    }) {
        var config = {
            headers: { 'Authorization': "bearer " + localStorage.getItem('token') }
        };
        try {
            const resp = await axios.get('/site', config);
            let data = resp.data;
            commit('sites', data);
            var filterData = [];
            if (window.location.hash == '#/' || window.location.hash.includes('gate-comparision'))
                filterData = _.filter(data, function (zone) { return zone.siteName == "Mall Count" });
            else if (window.location.hash.includes('zones'))
                filterData = _.filter(data, function (zone) { return zone.siteName == "Zones" });
            else if (window.location.hash.toLowerCase() == '#/anchor')
                filterData = _.filter(data, function (zone) { return zone.siteName == "Anchor Stores" });
            else
                filterData = _.filter(data, function () { return true; });

            commit('selectedSites', _.map(filterData, '_id'))
        }
        catch (err) {
            //eslint-disable-next-line
            console.log(err)
        }

        // new Promise(() => {
        //     axios.get('/site', config)
        //         .then(r => r.data)
        //         .then(data => {
        //             commit('sites', data);
        //             var filterData = [];
        //             if (window.location.pathname == '/' || window.location.pathname.includes('gate-comparision'))
        //                 filterData = _.filter(data, function (zone) { return zone.siteName == "Mall Count" });
        //             else if (window.location.pathname.includes('zones'))
        //                 filterData = _.filter(data, function (zone) { return zone.siteName == "Zones" });
        //             else if (window.location.pathname.toLowerCase()=='/anchor')
        //                 filterData = _.filter(data, function (zone) { return zone.siteName == "Anchor Stores" });
        //             else
        //                 filterData = _.filter(data, function () { return true; });

        //             commit('selectedSites', _.map(filterData, '_id'))
        //         })
        // })
        //     .catch(err => {
        //         //eslint-disable-next-line
        //         console.log(err)
        //     })
    },

    // GET ALL THE ZONES BY THE USER
    async getZones({
        commit
    }) {
        var config = {
            headers: { 'Authorization': "bearer " + localStorage.getItem('token') }
        };
        try {
            const resp = await axios.get('/zone', config);
            let data = resp.data;
            commit('zones', data);
            var filterData = data;
            if (window.location.hash.includes('gate-comparision')) {
                filterData = _.filter(data, function (zone) {
                    return zone.zoneName.includes("Gate");
                });
            }
            filterData = _.filter(filterData, function (zone) { return zone.zoneName != "Master Zone" && zone.zoneName!="Total Mall" });
            commit('selectedZones', _.map(filterData, '_id'))
        }
        catch (err) {
            //eslint-disable-next-line
            console.log(err)
        }
        // new Promise(() => {
        //     axios.get('/zone', config)
        //         .then(r => r.data)
        //         .then(data => {
        //             commit('zones', data);
        //             var filterData = [];
        //             if (window.location.pathname.includes('gate-comparision')) {
        //                 filterData = _.filter(data, function (zone) {
        //                     return zone.zoneName.includes("Gate");
        //                 });
        //             }
        //             filterData = _.filter(filterData, function (zone) { return zone.zoneName != "Master Zone" });
        //             commit('selectedZones', _.map(filterData, '_id'))
        //         })
        // })
        //     .catch(err => {
        //         //eslint-disable-next-line
        //         console.log(err)
        //     })
    },
    getSiteIds({ commit }, siteIds) {
        commit('selectedSites', siteIds)
    },
    getZoneIds({ commit }, zoneIds) {
        commit('selectedZones', zoneIds)
    },
    getConsolidation({ commit }, consolidation) {
        commit('consolidation', consolidation)
    },
    getDates({ commit }, dates) {
        commit('dates', dates)
    },
    getDates2({ commit }, dates) {
        commit('dates2', dates)
    },
    getOperationalHour({ commit }, operationalHour) {
        commit('operationalHour', operationalHour)
    }

}

export default {
    state,
    getters,
    actions,
    mutations
};