import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Sites from './views/Sites.vue'
import Zones from './views/Zones.vue'
import Events from './views/Events.vue'
import WeekComparision from './views/WeekComparision.vue'
import Anchor from './views/Anchor.vue'
import PageNotFound from './views/PageNotFound.vue'
import About from './views/About.vue'

import Overview from './views/Overview.vue'
import GateComparision from './views/GateComparison.vue'


Vue.use(Router)

export default new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresVisitors: true,
                reload: true,
            }
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                requiresAuth: true,
                reload: true,
            }
        },

        {
            path: '/sites',
            name: 'sites',
            component: Sites,
            meta: {
                requiresAuth: true,
                reload: true,
            }
        },
        {
            path: '/zones',
            name: 'zones',
            component: Zones,
            meta: {
                requiresAuth: true,
                reload: true,
            }
        },
        {
            path: '/Events',
            name: 'Events',
            component: Events,
            meta: {
                requiresAuth: true,
                reload: true,
            }
        },
        {
            path: '/week-comparision',
            name: 'week-comparision',
            component: WeekComparision,
            meta: {
                requiresAuth: true,
                reload: true,
            }
        },
        {
            path: '/about',
            name: 'about',
            component: About,
            meta: {
                requiresAuth: true,
                reload: true,
            }
        },
        {
            path: '*',
            name: 'pageNotFound',
            component: PageNotFound,
            meta: {
                requiresVisitors: true,
                reload: true,
            }
        },
        {
            path: '/overview',
            name: 'overview',
            component: Overview,
            meta: {
                requiresAuth: true,
                reload: true,
            }
        },
        {
            path: '/gate-comparision',
            name: 'gateComparision',
            component: GateComparision,
            meta: {
                requiresAuth: true,
                reload: true,
            }
        },
        {
            path: '/anchor',
            name: 'anchorreport',
            component: Anchor,
            meta: {
                requiresAuth: true,
                reload: true,
            }
        }
    ]
})
