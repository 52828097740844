import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import axios from 'axios'
import vuetify from './plugins/vuetify';
// import HighchartsVue from 'highcharts-vue'

import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'

// import VCalendar from 'v-calendar'; //Uninstall this packeges
// Vue.use(VCalendar, {
//   componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
// });
// Vue.use(HighchartsVue)
Vue.use(HighchartsVue, {
  highcharts: Highcharts
})
Vue.config.productionTip = false

Vue.prototype.$http = axios;
window.axios = axios
axios.defaults.baseURL = 'https://xpandretail.online/api/'
// axios.defaults.baseURL = 'http://localhost:4000/'
// window.axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters.token}`

import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });




function checkStorageExpiry() {
  var hours = 24; // Reset when storage is more than 24hours
  var now = new Date().getTime();
  var setupTime = localStorage.getItem('setupTime');
  if (setupTime == null) {
    localStorage.setItem('setupTime', now)
  } else {
    // now - setupTime > hours * 60 * 60 * 1000
    if (now - setupTime > hours * 60 * 60 * 1000) {
      alert("Your session has expired. Please login to continue.")
      localStorage.clear();
      localStorage.setItem('setupTime', now);
    }
  }
}
checkStorageExpiry()
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({
        name: 'login',
      })
    } else {
      next()

    }
  } else {
    next() // make sure to always call next()!
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  watch: {
    '$route'(to) {      
      if (to.meta.reload == true) { window.location.reload() }
    }
  }
}).$mount('#app');
