<template>
  <v-container mb-12>
    <div class="float-right d-flex">
      <v-toolbar dense>
        <v-toolbar-items>
          <!-- SITE AND ZONE SELECTION -->
          <v-select
            placeholder="SITES"
            no-data-text="No site(s) found"
            class="border overflow ma-2"
            multiple
            v-model="selectedSites"
            :items="setSites"
            @change="onSiteChange()"
            dense
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggle">
                <v-list-item-action>
                  <v-icon
                    :color="selectedSites.length > 0 ? 'indigo darken-4' : ''"
                    >{{ icon }}</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>

          <v-divider vertical></v-divider>
          <v-select
            placeholder="ZONES"
            no-data-text="No zone(s) found"
            class="border overflow ma-2"
            multiple
            v-model="selectedZones"
            :items="setZones"
            @change="onZoneChange()"
            dense
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggle2">
                <v-list-item-action>
                  <v-icon
                    :color="selectedZones.length > 0 ? 'indigo darken-4' : ''"
                    >{{ icon2 }}</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>

            <template slot="item" scope="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-action>
                  <v-checkbox
                    @click.capture.stop="0"
                    :value="data.item.value"
                    :color="data.item.value ? 'indigo darken-4' : ''"
                    v-model="selectedZones"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="data.item.text"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="data.item.group"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-select>
          <v-divider vertical></v-divider>
          <!-- CONSOLIDATION -->
          <v-btn-toggle v-model="consolidation" mandatory group class="mt-n1">
            <v-btn value="hour" icon>
              <v-icon>mdi-timer</v-icon>
            </v-btn>

            <v-btn text value="dayOfMonth">DAY</v-btn>
            <v-btn text value="week">WEEK</v-btn>
            <v-btn text value="month">MONTH</v-btn>
            <v-btn text value="year">YEAR</v-btn>
          </v-btn-toggle>
          <v-divider vertical></v-divider>
          <!-- DATE SELECTION -->
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                class="border ma-2"
              ></v-text-field>
            </template>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col>
                    <div class="title text-center">CURRENT PERIOD DATE</div>
                    <v-date-picker
                      v-model="dates"
                      range
                      no-title
                      scrollable
                      :max="getEndDate"
                    ></v-date-picker>
                  </v-col>
                  <v-col>
                    <div class="title text-center">COMPARISION PERIOD DATE</div>
                    <v-date-picker
                      v-model="dates2"
                      range
                      no-title
                      scrollable
                      :max="getEndDate"
                    ></v-date-picker>
                  </v-col>
                </v-row>
                <div style="padding-left: 458px">
                  <v-btn text color="primary" @click="menu = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="onDateChange(dates, dates2)"
                    >OK</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-divider vertical></v-divider>
          <v-btn class="primary" @click="applyClick(dates)">
            <span>Apply</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </div>
    <br />
    <br />

    <v-switch
      v-model="operationalHour"
      label="OPERATING HOURS"
      @change="onOperationalHourChange"
      hide-details
      class="float-right"
    ></v-switch>

    <v-snackbar :timeout="6000" :top="true" v-model="showAlert">
      {{ message }}
      <v-btn color="red" text @click="showAlert = false">Close</v-btn>
    </v-snackbar>
    <!-- {{this.selectedSites}} -->
    <!-- {{this.selectedZones}} -->
  </v-container>
</template>

<script>
// import { mapActions } from "vuex";
import moment from "moment";
import _ from "lodash";
export default {
  name: "site-filter",
  data() {
    return {
      showAlert: false,
      message: "",
      menu: false,
      consolidation: "",
      dates: "",
      dates2: "",
      operationalHour: false,
      datesChanged: "",
      datesChanged2: "",
    };
  },
  methods: {
    // ...mapActions(["getSites", "getZones"]),

    // METHOD FOR SELECT-ALL/UNSELECT-ALL SITES IN V-SELECT TOOLBAR
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllSite) {
          this.selectedSites = [];
        } else {
          this.selectedSites = this.setSites.map((site) => {
            return site.value;
          });
        }
      });
    },
    toggle2() {
      this.$nextTick(() => {
        if (this.selectAllZone) {
          this.selectedZones = [];
        } else {
          this.selectedZones = this.setZones.map((site) => {
            return site.value;
          });
        }
      });
    },
    // CALLING VUEX STORE TO GET ALL THE SITES BY THE USER USING TOKEN
    getSites() {
      this.$store.dispatch("getSites");
    },
    getZones() {
      this.$store.dispatch("getZones");
    },
    onSiteChange() {
      this.$store.dispatch("getSiteIds", this.selectedSites);
      var zoneIds = _.map(
        this.$store.getters.getZoneBySite(this.selectedSites),
        "_id"
      );
      this.$store.dispatch("cancelRequest");
      this.$store.dispatch("getZoneIds", zoneIds);
    },
    onZoneChange() {
      this.$store.dispatch("getZoneIds", this.selectedZones);
      this.$store.dispatch("cancelRequest");
    },
    // onConsolidationChange(consolidation) {
    //   console.log(consolidation);
    //   console.log(this.consolidation);

    //   //this.consolidationChanged = consolidation;
    //   // this.$store.dispatch("cancelRequest");
    //   // this.$store.dispatch("getConsolidation", consolidation);
    // },
    onOperationalHourChange(operationalHour) {
      this.$store.dispatch("cancelRequest");
      this.$store.dispatch("getOperationalHour", operationalHour);

      this.$store.state.home.selectedPeriodData = [];
      this.$store.state.home.comparePeriodData = [];
      this.$store.state.home.priorPeriodData = [];
      this.$store.state.home.priorYearData = [];
      this.$store.state.home.footfallData = [];
      this.$store.state.home.adultInData = [];
      this.$store.state.home.adultOutData = [];
      this.$store.state.home.childOutData = [];
      //this.$store.state.home.events = [];

      this.$root.$refs.current_page.PageReady();
    },
    onDateChange(dates, dates2) {
      this.datesChanged = dates;
      this.datesChanged2 = dates2;
      this.menu = false;
      this.$refs.menu.save(dates, dates2);
      // console.log(this.datesFormatted);
      // console.log(this.dates);
      // if (
      //   new Date(dates[0]) > new Date(dates[1]) ||
      //   new Date(dates2[0]) > new Date(dates2[1])
      // ) {
      //   this.showAlert = true;
      //   this.message = "start date should not be greater than end date";
      // } else if (dates.length < 2 || dates2.length < 2) {
      //   this.showAlert = true;
      //   this.message = "select end date";
      // } else {
      //   this.$refs.menu.save(dates, dates2);
      //   localStorage.setItem("dates", dates);
      //   localStorage.setItem("dates2", dates2);
      //   this.$store.dispatch("cancelRequest");
      //   this.$store.dispatch("getDates", dates);
      //   this.$store.dispatch("getDates2", dates2);
      // }
    },
    applyClick() {
      //Site Change Function
      // this.$store.dispatch("getSiteIds", this.selectedSites);
      // var zoneIds = _.map(
      //   this.$store.getters.getZoneBySite(this.selectedSites),
      //   "_id"
      // );
      // this.$store.dispatch("getZoneIds", zoneIds);
      // this.$store.dispatch("cancelRequest");

      //Zone Change Data
      // this.$store.dispatch("getZoneIds", this.selectedZones);
      // this.$store.dispatch("cancelRequest");

      //On Consolidation Change
      this.$store.dispatch("cancelRequest");
      this.$store.dispatch("getConsolidation", this.consolidation);

      //On Date Change
      if (
        new Date(this.datesChanged[0]) > new Date(this.datesChanged[1]) ||
        new Date(this.datesChanged2[0]) > new Date(this.datesChanged2[1])
      ) {
        this.showAlert = true;
        this.message = "start date should not be greater than end date";
      } else if (
        this.datesChanged.length < 2 ||
        this.datesChanged2.length < 2
      ) {
        this.showAlert = true;
        this.message = "select end date";
      } else {
        //this.$refs.menu.save(this.dates, this.datesChanged2);
        localStorage.setItem("dates", this.datesChanged);
        localStorage.setItem("dates2", this.datesChanged2);
        // this.$store.dispatch("cancelRequest");
        this.$store.dispatch("getDates", this.datesChanged);
        this.$store.dispatch("getDates2", this.datesChanged2);
      }

      this.$store.state.home.selectedPeriodData = [];
      this.$store.state.home.comparePeriodData = [];
      this.$store.state.home.priorPeriodData = [];
      this.$store.state.home.priorYearData = [];
      this.$store.state.home.footfallData = [];
      this.$store.state.home.adultInData = [];
      this.$store.state.home.adultOutData = [];
      this.$store.state.home.childOutData = [];
      // this.$store.state.home.events = [];
      this.$root.$refs.current_page.PageReady();
    },
    // SELECT PREVIOUS WEEK DATE BY DEFAULT
    getDefaultDates() {
      var datesU = localStorage.getItem("dates");
      var startDate, endDate;
      if (datesU != null) {
        startDate = moment(datesU.split(",")[0]).format("YYYY-MM-DD");
        endDate = moment(datesU.split(",")[1]).format("YYYY-MM-DD");
      } else {
        var currentDate = moment();
        // var startDate = currentDate.subtract(6, "days").format("YYYY-MM-DD");
        // var endDate = currentDate.add(6, "days").format("YYYY-MM-DD");

        startDate = currentDate.subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      }
      this.dates.push(startDate);
      this.dates.push(endDate);
      this.$store.dispatch("getDates", this.dates);
    },
    getDefaultDates2() {
      //var currentDate = moment();
      // var startDate = currentDate.subtract(20, "days").format("YYYY-MM-DD");
      // var endDate = currentDate.add(6, "days").format("YYYY-MM-DD");
      //var startDate = currentDate.subtract(1, "days").format("YYYY-MM-DD");
      var datesU = localStorage.getItem("dates2");
      var startDate, endDate;
      if (datesU != null) {
        startDate = moment(datesU.split(",")[0]).format("YYYY-MM-DD");
        endDate = moment(datesU.split(",")[1]).format("YYYY-MM-DD");
      } else {
       var date = new Date();
        var retDate = new Date();
        retDate.setYear(retDate.getFullYear() - 1);

        var diff = date.getDay() - retDate.getDay();
        retDate.setDate(retDate.getDate() + diff);

        startDate = moment(retDate).subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment(retDate).subtract(1, "days").format("YYYY-MM-DD");
      }
      this.dates2.push(startDate);
      this.dates2.push(endDate);
      this.$store.dispatch("getDates2", this.dates2);
    },
  },

  computed: {
    selectedSites: {
      get() {
        return this.$store.state.filter.selectedSites;
      },
      set(value) {
        this.$store.commit("selectedSites", value);
      },
    },
    selectedZones: {
      get() {
        return this.$store.state.filter.selectedZones;
      },
      set(value) {
        this.$store.commit("selectedZones", value);
      },
    },

    // COMPUTED PROPERTY FOR SELECTING ALL THE SITES IN THE V-SELECT TOOLBAR
    selectAllSite() {
      var zoneIds = _.map(
        this.$store.getters.getZoneBySite(this.selectedSites),
        "_id"
      );
      zoneIds = zoneIds.filter(x => x != '5e32824283da3f3606c9ef39');
      this.$store.dispatch("getZoneIds", zoneIds);
      return this.selectedSites.length === this.setSites.length;
    },
    // COMPUTED PROPERTY FOR SELECTING ALL THE ZONES IN THE V-SELECT TOOLBAR
    selectAllZone() {
      return this.selectedZones.length === this.setZones.length;
    },
    selectSomeSite() {
      var zoneIds = _.map(
        this.$store.getters.getZoneBySite(this.selectedSites),
        "_id"
      );
      zoneIds = zoneIds.filter(x => x != '5e32824283da3f3606c9ef39');
      this.$store.dispatch("getZoneIds", zoneIds);
      return this.selectedSites.length > 0 && !this.selectAllSite;
    },
    selectSomeZone() {
      return this.selectedZones.length > 0 && !this.selectAllZone;
    },
    icon() {
      if (this.selectAllSite) return "mdi-close-box";
      if (this.selectSomeSite) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icon2() {
      if (this.selectAllZone) return "mdi-close-box";
      if (this.selectSomeZone) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    setSites() {
      return this.$store.state.filter.sites.map((site) => {
        return { text: site.siteName, value: site._id };
      });
    },
    setZones() {
      return this.$store.getters
        .getZoneBySite(this.selectedSites)
        .map((zone) => {
          return {
            text: zone.zoneName,
            value: zone._id,
            group: zone.siteName,
            siteId: zone.siteId,
          };
        });
    },
    getEndDate() {
      var d = new Date();
      var endDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
      return endDate.toISOString().slice(0, 10);
    },
    dateRangeText() {
      return this.dates.join(" - ");
    },
  },
  created() {
    this.consolidation = this.$store.state.filter.consolidation;
    this.dates = this.$store.state.filter.dates;
    this.dates2 = this.$store.state.filter.dates2;
    this.operationalHour = this.$store.state.filter.operationalHour;
    this.datesChanged = this.dates;
    this.datesChanged2 = this.dates2;
    this.getDefaultDates();
    this.getDefaultDates2();
    this.getSites();
    this.getZones();
    // this.$root.$refs.compname_component.BhenchodVue();
    setTimeout(() => {
      this.$root.$refs.current_page.PageReady();
    }, 1);
    // window.ComponentLoaded();
    // console.log(this.$parent.BhenchodVue());
    // console.log(this.$parent.$children);
    //  if (window.location.pathname == "/" || window.location.pathname.includes("gate-comparision")) {
    //       filterData = _.filter(this.setSites, function(site) {
    //         return zone.siteName == "Mall Count";
    //       });
    // if (window.location.pathname == "/" || window.location.pathname.includes("gate-comparision")) {
    //   filterData = _.filter(data, function(zone) {
    //     return zone.siteName == "Mall Count";
    //   });
    // } else if (window.location.pathname.includes("zones")) {
    //   filterData = _.filter(data, function(zone) {
    //     return zone.siteName == "Zones";
    //   });
    // }
  },
  watch: {
    $route(to) {
      if (to.name == "zones") {
        // console.log("this.setSites", this.setSites);
        this.selectedSites = _.filter(this.setSites, function (zone) {
          // console.log("zone.text", zone.text);
          return zone.text == "Zones";
        });
      } else if (to.name == "home" || to.name == "gateComparision") {
        this.selectedSites = _.filter(this.setSites, function (zone) {
          return zone.text == "Mall Count";
        });
      } else {
        this.selectedSites = this.setSites.map((site) => {
          return site.value;
        });

        this.selectedZones = this.setZones.map((site) => {
          return site.value;
        });
      }
      this.onSiteChange();
      if (to.name == "gateComparision") {
        this.selectedZones = _.filter(this.setZones, function (zone) {
          return zone.text == "Mall Count";
        });

        this.onZoneChange();
      }
      // console.log(this.selectedSites);
      // console.log("selectedZones", this.selectedZones);
      //this.selectSomeSite();
      // var zoneIds = _.map(
      //   this.$store.getters.getZoneBySite(this.selectedSites),
      //   "_id"
      // );
      // this.$store.dispatch("getZoneIds", zoneIds);
      //this.getZones();
      //this.getSites();
    },
  },
};
</script>
<style>
.overflow {
  overflow: hidden;
  width: 150px;
}
.border.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.border.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0 4px 16px;
}
/* .v-application .accent--text {
  color: #083d59 !important;
  caret-color: #083d59 !important;
} */
</style>