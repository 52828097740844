<template>
  <v-container>
    <!-- <div class="pa-1 title">WEEKS</div> -->
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <v-card>
      <div ref="content">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-compare</v-icon>
              <span class="pa-4">WEEKDAYS WEEKEND COMPARISION</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="pdfClick()">
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="csvClick()">
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts class="chart" :options="chartOptions"></highcharts>
            </v-flex>
            <v-flex></v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>
    <!-- <v-card> -->
    <div ref="custable" class="pt-2">
      <table id="table" style="width: 100%">
        <thead>
          <tr>
            <th>Month</th>
            <th>Year</th>
            <th>Total WeekDays</th>
            <th>WeekDays Average</th>
            <th>Total Weekends</th>
            <th>Weekends Average</th>
            <th>Weekdays Variance %</th>
            <th>Weekend Variance %</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="data in getTableData">
            <tr :key="data.priorPeriodYear">
              <td rowspan="2">{{ data.month }}</td>
              <td>{{ data.priorPeriodYear }}</td>
              <td>{{ data.priorPeriodWeekdayTotal }}</td>
              <td>{{ data.priorPeriodWeekDaysAverage }}</td>
              <td>{{ data.priorPeriodWeekendTotal }}</td>
              <td>{{ data.priorPeriodWeekEndAverage }}</td>
              <td rowspan="2" :class="getColor(data.weekDayVariance)">
                {{ data.weekDayVariance }}
              </td>
              <td rowspan="2" :class="getColor(data.weekEndVariance)">
                {{ data.weekEndVariance }}
              </td>
            </tr>
            <tr :key="data.currentPeriodYear">
              <td>{{ data.currentPeriodYear }}</td>
              <td>{{ data.currentPeriodWeekdayTotal }}</td>
              <td>{{ data.currentPeriodWeekDaysAverage }}</td>
              <td>{{ data.currentPeriodWeekendTotal }}</td>
              <td>{{ data.currentPeriodWeekEndAverage }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <!-- </v-card> -->

    <v-snackbar :timeout="6000" :top="true" v-model="showAlert">
      {{ alertMessage }}
      <v-btn color="red" text @click="showAlert = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
           
<script>
import moment from "moment";
import _ from "lodash";
const expo = require("../plugins/export-2");
export default {
  name: "weeks",
  data() {
    return {
      timer1: "",
      timer2: "",
      timer3: "",
      overlay: true,
      computedCall: 3,
      passedCall: 1,
      showAlert: false,
      alertMessage: "",
      // CHART DATA
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          width: 1200,
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
      },
      // KPI DATA PARAMETERS
      params: {
        kpiType: "numeric",
        fetchDataFor: "line",
        dataFilter: [
          {
            kpiGroupId: "5a41fd655bca800604b146cc",
            kpiId: "5a41fd655bca80kpi14b147cc",
            elementId: "",
            label: "ADULT:HOURLY",
            color: "#7F186E",
            showOnAxis: "y1",
            mapped: [
              {
                mappedFullSite: 0,
                siteId: "full",
                zones: [],
              },
            ],
          },
          {
            kpiGroupId: "5a41fd655bca800604b146cc",
            kpiId: "5a41fd655bca80kpi14b147cc",
            elementId: "Child",
            label: "CHILD:HOURLY",
            color: "#7F186E",
            showOnAxis: "y1",
            mapped: [
              {
                mappedFullSite: 0,
                siteId: "full",
                zones: [],
              },
            ],
          },
        ],
        from: "",
        to: "",
        timeFrame: "dayOfMonth",
        consolidateSiteData: 1,
        consolidateData: 1,
        localTimezone: "+04:00",
        showDataByOperationalHours: true,
      },
    };
  },
  methods: {
    // STORE CHART DATA INTO VUEX STORE
    setChartData() {
      // if (this.passedCall > this.computedCall) this.overlay = false;
      // this.passedCall += 1;
      var params = this.getParameters;
      
      params.forEach((param) => {
        this.$store.dispatch("getKpiData", param);
      });
    },

    // ASSIGNING DATA TO CHART
    setChart() {
      var data = this.getChartData;
      data.chartCategories.splice(0, 1);
      data.chartCategories.splice(1, 1);
      for (const innerData of data["chartData"]) {
        innerData.data.splice(0, 1);
        innerData.data.splice(1, 1);
      }
      this.chartOptions.yAxis.title.text = "";
      this.chartOptions.xAxis.categories = data["chartCategories"];
      this.chartOptions.series = data["chartData"];
      this.overlay = false;
    },
     getsiteIdByZone(zoneId) {
      var zones = this.$store.state.filter.zones;
      var siteId = _.filter(zones, { _id: zoneId })[0]["siteId"];
      return siteId;
    },

    // SORTING THE MONTH ARRAY
    sortByMonth(arr) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      arr.sort(function (a, b) {
        return months.indexOf(a) - months.indexOf(b);
      });
    },

    // GET COLOR FOR TABLE (WEEK VARIANCE)
    getColor(number) {
      return number > 0
        ? "green--text"
        : number == 0
        ? "black--text"
        : "red--text";
    },

    csvClick() {
      this.showAlert = true;
      if (this.isData) {
        var filename = this.getFileName + ".csv";
        var data = this.getTableData;
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    pdfClick() {
      var that = this;
      if (
        document.querySelector(".v-navigation-drawer button").offsetParent !=
        null
      )
        document.querySelector(".v-navigation-drawer button").click();

      setTimeout(function () {
        that.showAlert = true;
        if (that.isData) {
          var filename = that.getFileName + ".pdf";
          var content = that.$refs.content;
          var content1 = that.$refs.custable;
          var text = that.pdfFilterData();
          var response = expo.createPDF(
            filename,
            text,
            content,
            null,
            content1,
            true
          );
          //var response = expo.createPDF(filename, text, content);
          that.alertMessage = response;
        } else {
          that.alertMessage = "No data to export";
        }
      }, 500);
    },
    pdfFilterData() {
      var reportName = this.getFileName;
      var generationDate = new Date();
      var siteNames = _.map(this.$store.getters.selectedSites, "siteName");
      var zoneNames = _.map(this.$store.getters.selectedZones, "zoneName");
      var operationalHour = this.$store.state.filter.operationalHour;
      var consolidation = this.getChartName;
      var selectedPeriodDate =
        moment(this.$store.getters.selectedPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.selectedPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");
      var priorPeriodDate =
        moment(this.$store.getters.priorPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");
      var priorYearDate =
        moment(this.$store.getters.priorYearDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorYearDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("YYYY-MM-DD");
      var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Selected Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Perior Period Date</td> <td> ${priorPeriodDate}</td> </tr> <tr> <td>Prior Year Date</td> <td> ${priorYearDate}</td> </tr> </tbody> </table>`;
      return table;
    },
    PageReady(loader = true) {
      if (loader) this.overlay = true;
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);

      this.timer2 = setInterval(() => {
        if (this.$store.state.filter.selectedZones.length > 0) {
          clearInterval(this.timer2);
          this.setChartData();
          this.timer2 = setInterval(() => {
            var data = this.getChartData;

            if (
              data["chartData"].length > 0 &&
              Object.keys(data["selectedPeriod"]).length > 0 &&
              Object.keys(data["priorPeriod"]).length > 0
            ) {
              clearInterval(this.timer2);
              this.setChart();
              // this.timer3 = setInterval(() => {
              //   this.PageReady(false);
              // }, 20000);
            }
          }, 100);
        }
      }, 100);
    },
  },
  computed: {
    // GET PARAMETERS
    getParameters() {
      var payloads = [];
      var slelectedPeriodPayload = {};
      var priorPeriodPayload = {};
      var priorYearPayload = {};
      var params = JSON.parse(JSON.stringify(this.params));
      params.dataFilter[0].mapped[0].zones = this.$store.state.filter.selectedZones;
      params.dataFilter[1].mapped[0].zones = this.$store.state.filter.selectedZones;
      params.showDataByOperationalHours = this.$store.state.filter.operationalHour;

      //SELECTED PERIOD
      var params1 = JSON.parse(JSON.stringify(params));
      params1.from = this.$store.getters.selectedPeriodDate[0];
      params1.from =moment(params1.from).add(-4,'hours').format("YYYY-MM-DD HH:mm:ss");
      params1.to = this.$store.getters.selectedPeriodDate[1];
      slelectedPeriodPayload["params"] = params1;
      slelectedPeriodPayload["mutationId"] = "selectedPeriodData";
      slelectedPeriodPayload["action"] = "merge";
      payloads.push(slelectedPeriodPayload);

      //PRIOR PERIOD
      var params2 = JSON.parse(JSON.stringify(params));
      params2.from = this.$store.getters.priorPeriodDate[0];
      params2.to = this.$store.getters.priorPeriodDate[1];
      priorPeriodPayload["params"] = params2;
      priorPeriodPayload["mutationId"] = "priorPeriodData";
      priorPeriodPayload["action"] = "merge";
      payloads.push(priorPeriodPayload);

      //PRIOR YEAR
      var params3 = JSON.parse(JSON.stringify(params));
      params3.from = this.$store.getters.priorYearDate[0];
      params3.to = this.$store.getters.priorYearDate[1];
      priorYearPayload["params"] = params3;
      priorYearPayload["mutationId"] = "priorYearData";
      priorYearPayload["action"] = "merge";
      payloads.push(priorYearPayload);

      // console.log(this.$store.getters.priorYearDate)
      // console.log(this.$store.getters.comparePeriodDate)
      return payloads;
    },
    // GET STORE DATA FROM VUEX STORE
    getChartData() {
      var data = {};
      var selectedPeriodData = {};
      var priorPeriodData = {};
      var priorYearData = {};

      var weekdays = [0, 1, 2, 3];

      var selectedPeriodWeekDay = [];
      var selectedPeriodWeekEnd = [];
      this.$store.state.home.selectedPeriodData.forEach((site, i) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {            
            var isWeekday = weekdays.includes(moment(kpi.dateFrom).weekday());
            var month = moment(kpi.dateFrom).format("MMMM");
            var array = {};
            array["month"] = month;
            array["value"] = kpi.value;
            if (isWeekday) {
              selectedPeriodWeekDay.push(array);
            } else {
              selectedPeriodWeekEnd.push(array);
            }
          });
          var groupByWeekDayMonth = _.map(
            _.groupBy(selectedPeriodWeekDay, "month"),
            (o, idx) => {
              return { month: idx, values: _.sumBy(o, "value") };
            }
          );
          var groupByWeekEndMonth = _.map(
            _.groupBy(selectedPeriodWeekEnd, "month"),
            (o, idx) => {
              return { month: idx, values: _.sumBy(o, "value") };
            }
          );
          if (i == 0) {
            selectedPeriodData["weekDayCount"] = selectedPeriodWeekDay.length;
            selectedPeriodData["weekEndCount"] = selectedPeriodWeekEnd.length;
            selectedPeriodData["weekDay"] = groupByWeekDayMonth;
            selectedPeriodData["weekEnd"] = groupByWeekEndMonth;
          } else {
            selectedPeriodData["weekDay"].forEach((d, i) => {
              selectedPeriodData["weekDay"][i]["value"] +=
                groupByWeekDayMonth[i]["value"];
            });
            selectedPeriodData["weekEnd"].forEach((d, i) => {
              selectedPeriodData["weekEnd"][i]["value"] +=
                groupByWeekEndMonth[i]["value"];
            });
          }
        }
      });
      // PRIOR PEROD DATA
      var weekDayPriorPeriod = [];
      var weekEndPriorPeriod = [];
      this.$store.state.home.priorPeriodData.forEach((site, i) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var isWeekday = weekdays.includes(moment(kpi.dateFrom).weekday());
            var month = moment(kpi.dateFrom).format("MMMM");
            var array = {};
            if (isWeekday) {
              array["month"] = month;
              array["value"] = kpi.value;
              weekDayPriorPeriod.push(array);
            } else {
              array["month"] = month;
              array["value"] = kpi.value;
              weekEndPriorPeriod.push(array);
            }
          });

          var groupByWeekDayMonth = _.map(
            _.groupBy(weekDayPriorPeriod, "month"),
            (o, idx) => {
              return { month: idx, values: _.sumBy(o, "value") };
            }
          );
          var groupByWeekEndMonth = _.map(
            _.groupBy(weekEndPriorPeriod, "month"),
            (o, idx) => {
              return { month: idx, values: _.sumBy(o, "value") };
            }
          );
          if (i == 0) {
            priorPeriodData["weekDayCount"] = weekDayPriorPeriod.length;
            priorPeriodData["weekEndCount"] = weekEndPriorPeriod.length;
            priorPeriodData["weekDay"] = groupByWeekDayMonth;
            priorPeriodData["weekEnd"] = groupByWeekEndMonth;
          } else {
            priorPeriodData["weekDay"].forEach((d, i) => {
              priorPeriodData["weekDay"][i]["value"] +=
                groupByWeekDayMonth[i]["value"];
            });
            priorPeriodData["weekEnd"].forEach((d, i) => {
              priorPeriodData["weekEnd"][i]["value"] +=
                groupByWeekEndMonth[i]["value"];
            });
          }
        }
      });

      // PRIOR YEAR DATA
      var weekDayPriorYear = [];
      var weekEndPriorYear = [];
      this.$store.state.home.priorYearData.forEach((site, i) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var isWeekday = weekdays.includes(moment(kpi.dateFrom).weekday());
            var month = moment(kpi.dateFrom).format("MMMM");
            var array = {};
            if (isWeekday) {
              array["month"] = month;
              array["value"] = kpi.value;
              weekDayPriorYear.push(array);
            } else {
              array["month"] = month;
              array["value"] = kpi.value;
              weekEndPriorYear.push(array);
            }
          });

          var groupByWeekDayMonth = _.map(
            _.groupBy(weekDayPriorYear, "month"),
            (o, idx) => {
              return { month: idx, values: _.sumBy(o, "value") };
            }
          );
          var groupByWeekEndMonth = _.map(
            _.groupBy(weekEndPriorYear, "month"),
            (o, idx) => {
              return { month: idx, values: _.sumBy(o, "value") };
            }
          );
          if (i == 0) {
            priorYearData["weekDayCount"] = weekDayPriorYear.length;
            priorYearData["weekEndCount"] = weekEndPriorYear.length;
            priorYearData["weekDay"] = groupByWeekDayMonth;
            priorYearData["weekEnd"] = groupByWeekEndMonth;
          } else {
            priorYearData["weekDay"].forEach((d, i) => {
              priorYearData["weekDay"][i]["value"] +=
                groupByWeekDayMonth[i]["value"];
            });
            priorYearData["weekEnd"].forEach((d, i) => {
              priorYearData["weekEnd"][i]["value"] +=
                groupByWeekEndMonth[i]["value"];
            });
          }
        }
      });

      // this.$store.state.home.comparePeriodData.forEach((site, i) => {
      //   console.log(site);
      //   console.log(i);
      // });

      data["selectedPeriod"] = selectedPeriodData;
      data["priorPeriod"] = priorPeriodData;
      data["priorYear"] = priorYearData;

      // FOR COLUMN CHART
      var months = [];
      months = [
        ..._.map(data["selectedPeriod"]["weekDay"], "month"),
        ..._.map(data["priorYear"]["weekDay"], "month"),
        ..._.map(data["selectedPeriod"]["weekEnd"], "month"),
        ..._.map(data["priorYear"]["weekEnd"], "month"),
      ];
      months = _.uniqBy(months);

      this.sortByMonth(months);

      var dataObj = [];
      for (const month of months) {
        var selectedWeekDayValue = _.find(data["selectedPeriod"]["weekDay"], {
          month: month,
        });
        var selectedWeekEndValue = _.find(data["selectedPeriod"]["weekEnd"], {
          month: month,
        });
        var priorYearWeekDayValue = _.find(data["priorYear"]["weekDay"], {
          month: month,
        });
        var priorYearWeekEndValue = _.find(data["priorYear"]["weekEnd"], {
          month: month,
        });
        if (typeof selectedWeekDayValue == "undefined") {
          selectedWeekDayValue = { month: month, values: 0 };
        }
        if (typeof selectedWeekEndValue == "undefined") {
          selectedWeekEndValue = { month: month, values: 0 };
        }
        if (typeof priorYearWeekDayValue == "undefined") {
          priorYearWeekDayValue = { month: month, values: 0 };
        }
        if (typeof priorYearWeekEndValue == "undefined") {
          priorYearWeekEndValue = { month: month, values: 0 };
        }

        dataObj.push({
          name: month,
          data: [
            priorYearWeekDayValue["values"],
            selectedWeekDayValue["values"],
            priorYearWeekEndValue["values"],
            selectedWeekEndValue["values"],
          ],
        });
      }
      // months.forEach((month) => {
      //   var selectedWeekDayValue = _.find(data["selectedPeriod"]["weekDay"], {
      //     month: month,
      //   });
      //   var selectedWeekEndValue = _.find(data["selectedPeriod"]["weekEnd"], {
      //     month: month,
      //   });
      //   var priorYearWeekDayValue = _.find(data["priorYear"]["weekDay"], {
      //     month: month,
      //   });
      //   var priorYearWeekEndValue = _.find(data["priorYear"]["weekEnd"], {
      //     month: month,
      //   });
      //   if (typeof selectedWeekDayValue == "undefined") {
      //     selectedWeekDayValue = { month: month, values: 0 };
      //   }
      //   if (typeof selectedWeekEndValue == "undefined") {
      //     selectedWeekEndValue = { month: month, values: 0 };
      //   }
      //   if (typeof priorYearWeekDayValue == "undefined") {
      //     priorYearWeekDayValue = { month: month, values: 0 };
      //   }
      //   if (typeof priorYearWeekEndValue == "undefined") {
      //     priorYearWeekEndValue = { month: month, values: 0 };
      //   }

      //   dataObj.push({
      //     name: month,
      //     data: [
      //       priorYearWeekDayValue["values"],
      //       selectedWeekDayValue["values"],
      //       priorYearWeekEndValue["values"],
      //       selectedWeekEndValue["values"],
      //     ],
      //   });
      // });
      data["chartCategories"] = [
        "Total Weekdays Prior Year",
        "Total Weekdays Current Period",
        "Total Weekends Prior Year",
        "Total Weekends Current Period",
      ];
      data["chartData"] = dataObj;
      return data;
    },
    // GET DATA FROM getChartData METHOD AND REGENERATE FOR TABLE AND CSV
    getTableData() {
      var chartData = this.getChartData;
      var data = [];
      chartData.chartData.forEach((month) => {
        var dataObj = {};

        var ppWD = month.data[0];
        var spWD = month.data[1];
        var ppWE = month.data[2];
        var spWE = month.data[3];

        var ppWeekDaysAverage =
          isNaN(ppWD / chartData.priorYear.weekDayCount) ||
          ppWD / chartData.priorYear.weekDayCount == null
            ? 0
            : Math.round(ppWD / chartData.priorYear.weekDayCount);
        var spWeekDaysAverage =
          isNaN(spWD / chartData.selectedPeriod.weekDayCount) ||
          spWD / chartData.selectedPeriod.weekDayCount == null
            ? 0
            : Math.round(spWD / chartData.selectedPeriod.weekDayCount);
        var ppWeekEndAverage =
          isNaN(ppWE / chartData.priorYear.weekEndCount) ||
          ppWE / chartData.priorYear.weekEndCount == null
            ? 0
            : Math.round(ppWE / chartData.priorYear.weekEndCount);
        var spWeekEndAverage =
          isNaN(spWE / chartData.selectedPeriod.weekEndCount) ||
          spWE / chartData.selectedPeriod.weekEndCount == null
            ? 0
            : Math.round(spWE / chartData.selectedPeriod.weekEndCount);
        var weekDayVariance =
          isNaN((spWD - ppWD) / ppWD) ||
          (spWD - ppWD) / ppWD == null ||
          !isFinite((spWD - ppWD) / ppWD)
            ? 0
            : (((spWD - ppWD) / ppWD) * 100).toFixed(2);
        var weekEndVariance =
          isNaN((spWE - ppWE) / ppWE) ||
          (spWE - ppWE) / ppWE == null ||
          !isFinite((spWE - ppWE) / ppWE)
            ? 0
            : (((spWE - ppWE) / ppWE) * 100).toFixed(2);

        dataObj["month"] = month.name;
        dataObj["priorPeriodYear"] = moment(
          this.$store.getters.priorYearDate[0],
          "YYYY-MM-DD hh:mm:ss"
        )          
          .format("YYYY");
        dataObj["currentPeriodYear"] = moment(
          this.$store.getters.selectedPeriodDate[0],
          "YYYY-MM-DD hh:mm:ss"
        )          
          .format("YYYY");
        dataObj["priorPeriodWeekdayTotal"] = ppWD;
        dataObj["priorPeriodWeekDaysAverage"] = ppWeekDaysAverage;
        dataObj["currentPeriodWeekdayTotal"] = spWD;
        dataObj["currentPeriodWeekDaysAverage"] = spWeekDaysAverage;
        dataObj["priorPeriodWeekendTotal"] = ppWE;
        dataObj["priorPeriodWeekEndAverage"] = ppWeekEndAverage;
        dataObj["currentPeriodWeekendTotal"] = spWE;
        dataObj["currentPeriodWeekEndAverage"] = spWeekEndAverage;
        dataObj["weekDayVariance"] = weekDayVariance;
        dataObj["weekEndVariance"] = weekEndVariance;

        data.push(dataObj);
      });
      return data;
    },

    getFileName() {
      return "WEEK COMPARISION";
    },
    isData() {
      return this.chartOptions.series.length > 0 ? true : false;
    },
  },
  created() {
    this.$root.$refs.current_page = this;
    // this.setChartData();
    // this.setChart();
    // this.timer1 = setInterval(this.setChartDataAsync, 10000);
    // this.timer2 = setInterval(this.setChart, 3000);
  },
  beforeDestroy() {
    clearInterval(this.timer1);
    // clearInterval(this.timer2);
  },
  // updated: function() {
  //   this.overlay = false;
  // },
  // WATCHING FOR CHANGE IN THE CONSOLIDATION
  //watch: {
  // getParameters: function () {
  //   this.setChartData();
  // },
  // getChartData: function () {
  //   this.setChart();
  // },
  // overlay(val) {
  //   val &&
  //     setTimeout(() => {
  //       this.overlay = false;
  //     }, 3000);
  // },
  //},
};
</script>
