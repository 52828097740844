<template>
  <v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <!-- COMPARISION CHART -->
    <v-card>
      <div ref="comparision">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-compare</v-icon>
              <span class="pa-4">SITE COMPARISION</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="pdfClickComparision()"
                    >
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickComparision()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts class="chart" :options="chartOptions"></highcharts>
            </v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>

    <!-- CONTRIBUTION CHART -->
    <v-card class="mt-4">
      <div ref="contribution">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-chart-pie</v-icon>
              <span class="pa-4">SITE CONTRIBUTION</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="pdfClickContribution()">
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickContribution()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts :options="pipChartOptions"></highcharts>
            </v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>

    <!-- SUMMARY REPORT -->
    <v-card class="mt-4">
      <div ref="entrance">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black"
                >mdi-file-document-box-outline</v-icon
              >
              <span class="pa-4">ENTRANCE SUMMARY</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickSummary()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-text-field
              v-model="summarySearch"
              append-icon="mdi-feature-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
          <!-- <v-spacer></v-spacer> -->
        </v-card-title>
        <v-data-table
          :headers="summaryHeader"
          :items="getTableSummary"
          :search="summarySearch"
        >
          <template v-slot:item.currentPeriodChange="{ item }">
            <div :class="getClass(item.currentPeriodChange)"></div>
            {{ item.currentPeriodChange }}
          </template>

          <template v-slot:item.comparePeriodChange="{ item }">
            <div :class="getClass(item.comparePeriodChange)"></div>
            {{ item.comparePeriodChange }}
          </template>

          <template v-slot:item.priorYearChange="{ item }">
            <div :class="getClass(item.priorYearChange)"></div>
            {{ item.priorYearChange }}
          </template>

          <template v-slot:item.variance="{ item }">
            <div :class="getClass(item.variance)"></div>
            {{ item.variance }}
          </template>

          <!-- <template v-slot:item.variance="{ item }">
            <div :class="getClass(item.variance)"></div>
            {{ item.priorYearChange }}
          </template>-->
        </v-data-table>
      </div>
    </v-card>

    <!-- SQUARE METER -->
    <v-card class="mt-4">
      <div ref="custable">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-diameter</v-icon>
              <span class="pa-4">PERSON PER SQUARE METER</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickSQM()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Site Name</th>
                  <th>
                    SQM(m
                    <sup>2</sup>)
                  </th>
                  <th>Current Period (Person/SQM)</th>
                  <th>Compare Period (Person/SQM)</th>
                  <!-- <th>Prior Year (Person/SQM)</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sqmComparision" :key="item.name">
                  <td>{{ item.label }}</td>
                  <td>{{ item.sqm }}</td>
                  <td>{{ item.sqmSelectedPeriod || 0 }}</td>
                  <td>{{ item.sqmComparePeriod || 0 }}</td>
                  <!-- <td>{{ item.sqmPriorYear || 0 }}</td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </div>
    </v-card>
    <!-- {{this.sqmComparision}} -->
    <v-snackbar :timeout="6000" :top="true" v-model="showAlert">
      {{ alertMessage }}
      <v-btn color="red" text @click="showAlert = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
// import Highcharts from "highcharts";
const expo = require("../plugins/export-2");
const sqm = require("../clients/sqm.json");
var priorPeriodLegend = false;
var priorYearLegend = false;
export default {
  name: "sites",
  data() {
    return {
      timer1: "",
      timer2: "",
      timer3: "",
      overlay: true,
      showAlert: false,
      computedCall: 3,
      passedCall: 1,
      alertMessage: "",
      // CHART DATA
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          width: 1200,
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        plotOptions: {
          column: {
            events: {
              legendItemClick: function () {
                if (this.userOptions.index == 2) {
                  priorPeriodLegend = !priorPeriodLegend;
                } else if (this.userOptions.index == 3) {
                  priorYearLegend = !priorYearLegend;
                }
              },
            },
          },
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "",
            data: [],
            color: "green",
          },
          {
            name: "",
            data: [],
            color: "red",
          },
          {
            name: "",
            data: [],
            color: "blue",
          },
          // {
          //   name: "",
          //   data: [],
          //   color: "purple",
          // },
        ],
      },
      pipChartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "pie",
        },
        title: {
          text: "",
        },
        plotOptions: {
          pie: {
            // innerSize: "50%",
            showInLegend: true,
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        labels: {
          items: [
            {
              html: "current period",
              style: {
                left: "220px",
                top: "300px",
              },
            },
            {
              html: "comparision period",
              style: {
                left: "840px",
                top: "300px",
              },
            },
            // {
            //   html: "prior year",
            //   style: {
            //     left: "1000x",
            //     top: "250px",
            //   },
            // },
          ],
        },
        series: [
          {
            name: "current period",
            size: 220,
            center: [250, 110],
            data: [],
          },
          {
            name: "comparision period",
            showInLegend: false,
            size: 220,
            center: [870, 110],
            data: [],
          },
          // {
          //   name: "prior year",
          //   showInLegend: false,
          //   size: 220,
          //   center: [1000, 80],
          //   data: [],
          // },
        ],
      },
      // KPI DATA PARAMETERS
      params: {
        kpiType: "numeric",
        fetchDataFor: "line",
        dataFilter: [],
        from: "",
        to: "",
        timeFrame: "",
        consolidateSiteData: 0,
        consolidateData: 1,
        localTimezone: "+04:00",
        showDataByOperationalHours: true,
      },
      sqmHeader: [
        {
          text: "SQM(m<sup>2</sup>)",
          value: "Site Name",
          align: "center",
        },
      ],
      summaryHeader: [
        {
          text: "Entrance Name",
          value: "name",
          align: "center",
        },
        {
          text: "Current Period",
          value: "currentPeriod",
          align: "center",
        },

        {
          text: "Compare Period",
          value: "comparePeriod",
          align: "center",
        },
        {
          text: "Variance %",
          value: "variance",
          align: "center",
        },
        // {
        //   text: "Change",
        //   value: "currentPeriodChange",
        //   align: "center",
        // },

        // {
        //   text: "Prior Year Traffic",
        //   value: "priorYear",
        //   align: "center"
        // },
        // {
        //   text: "Change",
        //   value: "priorYearChange",
        //   align: "center"
        // },
        // {
        //   text: "Prior Period Traffic",
        //   value: "priorPeriod",
        //   align: "center"
        // }
      ],
      summarySearch: "",
    };
  },
  methods: {
    // STORE CHART DATA INTO VUEX STORE
    setChartData() {
      // if (this.passedCall > this.computedCall) this.overlay = false;
      // this.passedCall += 1;
      var params = this.getParameters;
      // console.log(params);
      for (const param of params) {
        this.$store.dispatch("getKpiData", param);
      }
      // this.timer1 = setInterval(this.setChartDataAsync, 10000);
    },
    // async setChartDataAsync() {
    //   clearInterval(this.timer1);
    //   var params = this.getParameters;
    //   for (const param of params) {
    //     await this.$store.dispatch("getKpiData", param);
    //   }
    //   this.setChart();
    //   this.timer1 = setInterval(this.setChartDataAsync, 10000);
    // },
    // ASSIGNING DATA TO CHART
    setChart() {
      var data = this.getChartData;
      // this.chartOptions.yAxis.title.text = "";
      var label =
        data["selectedPeriodLabel"].length >= data["priorPeriodLabel"].length
          ? data["selectedPeriodLabel"]
          : data["priorPeriodLabel"];

      let totalDateRange = this.getTotal();
      this.chartOptions.series[0].name =
        "Current Period (" +
        moment(totalDateRange.selectedPeriod.datetime.from).format("DD/MM/YY") +
        " to " +
        moment(totalDateRange.selectedPeriod.datetime.to).format("DD/MM/YY") +
        ")";

      this.chartOptions.series[1].name =
        "Compare Period (" +
        moment(totalDateRange.comparisionPeriod.datetime.from).format(
          "DD/MM/YY"
        ) +
        " to " +
        moment(totalDateRange.comparisionPeriod.datetime.to).format(
          "DD/MM/YY"
        ) +
        ")";

      this.chartOptions.series[2].name =
        "Prior Week (" +
        moment(totalDateRange.priorPeriod.datetime.from).format("DD/MM/YY") +
        " to " +
        moment(totalDateRange.priorPeriod.datetime.to).format("DD/MM/YY") +
        ")";

      // this.chartOptions.series[3].name =
      //   "Prior Year (" +
      //   moment(totalDateRange.priorYear.datetime.from).format("DD/MM/YY") +
      //   " to " +
      //   moment(totalDateRange.priorYear.datetime.to).format("DD/MM/YY") +
      //   ")";

      this.chartOptions.series[2].visible = priorPeriodLegend;
      //this.chartOptions.series[3].visible = priorYearLegend;

      this.chartOptions.xAxis.categories = label;      
      this.chartOptions.series[0].data = data["selectedPeriod"];
      this.chartOptions.series[1].data = data["comparePeriod"];
      this.chartOptions.series[2].data = data["priorPeriod"];
      //this.chartOptions.series[3].data = data["priorYear"];
      //   this.chartOptions.title.text = this.getChartName + " Consolidated Data";

      // FOR PIE CHART
      var pieDataSelectedPeriod = [];
      var pieDataComparisionPeriod = [];
      // var pieDataPriorPeriodPeriod = [];
      var pieDataPriorYearPeriod = [];
      for (var i = 0; i < data.selectedPeriodLabel.length; i++) {
        var pieData1 = [];
        pieData1.push(data.selectedPeriodLabel[i]);
        pieData1.push(data.selectedPeriod[i]);
        pieDataSelectedPeriod.push(pieData1);
      }
      for (var l = 0; l < data.comparePeriodLabel.length; l++) {
        var pieData4 = [];
        pieData4.push(data.comparePeriodLabel[l]);
        pieData4.push(data.comparePeriod[l]);
        pieDataComparisionPeriod.push(pieData4);
      }
      // for (var j = 0; j < data.priorPeriodLabel.length; j++) {
      //   var pieData2 = [];
      //   pieData2.push(data.priorPeriodLabel[j]);
      //   pieData2.push(data.priorPeriod[j]);
      //   pieDataPriorPeriodPeriod.push(pieData2);
      // }
      for (var k = 0; k < data.selectedPeriodLabel.length; k++) {
        var pieData3 = [];
        pieData3.push(data.selectedPeriodLabel[k]);
        pieData3.push(data.priorYear[k]);
        pieDataPriorYearPeriod.push(pieData3);
      }
      this.pipChartOptions.series[0].data = pieDataSelectedPeriod;
      // this.pipChartOptions.series[1].data = pieDataPriorPeriodPeriod;
      this.pipChartOptions.series[1].data = pieDataComparisionPeriod;
      //this.pipChartOptions.series[2].data = pieDataPriorYearPeriod;
      // if (
      //   data["selectedPeriod"].length > 0 &&
      //   data["comparePeriod"].length > 0 &&
      //   data["priorPeriod"].length > 0
      // )
      this.overlay = false;
    },

    getTotal() {
      //var data = this.getChartData;
      //debugger;
      // var dateFrom = this.$store.state.filter.dates[0];
      // var dateTo = this.$store.state.filter.dates[1];
      var sumObj = {};

      //SELECTED PERIOD
      sumObj["selectedPeriod"] = {};
      sumObj["selectedPeriod"]["datetime"] = {};
      sumObj["selectedPeriod"]["datetime"][
        "from"
      ] = this.$store.getters.selectedPeriodDate[0];
      sumObj["selectedPeriod"]["datetime"][
        "to"
      ] = this.$store.getters.selectedPeriodDate[1];
      // sumObj["selectedPeriod"]["total"] =
      //   Math.round(selectedPeriodTotal * 100) / 100;

      this.$store.state.home.childInData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value =
              parseInt(isNaN(value) ? 0 : value) +
              parseInt(isNaN(kpi.value) ? 0 : kpi.value);
          });
        }
      });

      //COMPARISION PERIOD
      sumObj["comparisionPeriod"] = {};
      sumObj["comparisionPeriod"]["datetime"] = {};
      sumObj["comparisionPeriod"]["datetime"][
        "from"
      ] = this.$store.getters.comparePeriodDate[0];
      sumObj["comparisionPeriod"]["datetime"][
        "to"
      ] = this.$store.getters.comparePeriodDate[1];

      //PRIOR PERIOD
      sumObj["priorPeriod"] = {};
      sumObj["priorPeriod"]["datetime"] = {};
      sumObj["priorPeriod"]["datetime"]["from"] = moment(
        this.$store.getters.priorPeriodDate[0]
      ).format("YYYY-MM-DD");
      sumObj["priorPeriod"]["datetime"]["to"] = moment(
        this.$store.getters.priorPeriodDate[1]
      ).format("YYYY-MM-DD");

      //PRIOR YEAR
      // sumObj["priorYear"] = {};
      // sumObj["priorYear"]["datetime"] = {};
      // // sumObj["priorYear"]["datetime"]["from"] = moment(dateFrom).subtract(1, "years").format("YYYY-MM-DD");
      // // sumObj["priorYear"]["datetime"]["to"] = moment(dateTo).subtract(1, "years").format("YYYY-MM-DD");
      // sumObj["priorYear"]["datetime"]["from"] = moment(
      //   this.$store.getters.priorYearDate[0]
      // ).format("L");
      // sumObj["priorYear"]["datetime"]["to"] = moment(
      //   this.$store.getters.priorYearDate[1]
      // ).format("L");

      return sumObj;
    },

    // EXPORT CONTENT AS PDF USING THE REFERENCE ID
    pdfClickComparision() {
      var that = this;
      if (
        document.getElementsByClassName("v-navigation-drawer--mini-variant")
          .length != 1
      )
        document.querySelector(".v-navigation-drawer button").click();

      setTimeout(function () {
        if (that.isData) {
          that.showAlert = true;
          var filename = "SITE COMPARISION.pdf";
          var content = that.$refs.comparision;
          var content1 = that.$refs.contribution;
          var content2 = that.$refs.entrance;
          var content3 = that.$refs.custable;
          var text = that.pdfFilterData();
          var response = expo.createSitePDF(
            filename,
            text,
            content,
            content1,
            content2,
            content3
          );
          that.alertMessage = response;
        } else {
          that.alertMessage = "No data to export";
        }
      }, 500);
      //eslint-disable-next-line
      // console.log(Highcharts);
      // Highcharts.charts[0].exportChart({
      //   type: "application/pdf"
      // });
    },
    // EXPORTING VALUES TO CSV FORMAT
    csvClickComparision() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "SITE COMPARISION.csv";
        var data = this.csvData();
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    // EXPORT CONTENT AS PDF USING THE REFERENCE ID
    pdfClickContribution() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "SITE CONTRIBUTION.pdf";
        var content = this.$refs.contribution;
        var text = this.pdfFilterData();
        var response = expo.createPDF(filename, text, content);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    // EXPORTING VALUES TO CSV FORMAT
    csvClickContribution() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "SITE CONTRIBUTION.csv";
        var data = this.csvData2();
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },

    // EXPORTING VALUES TO CSV FORMAT
    csvClickSQM() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "SQM REPORT.csv";
        var data = this.sqmComparision;
        for (var i = 0; i < data.length; i++) {
          delete data[i].avgCountComparePeriod;
          delete data[i].avgCountSelectedPeriod;
        }
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    pdfFilterData() {
      var reportName = "SITE REPORT";
      var generationDate = new Date();
      var siteNames = _.map(this.$store.getters.selectedSites, "siteName");
      var zoneNames = _.map(this.$store.getters.selectedZones, "zoneName");
      var operationalHour = this.$store.state.filter.operationalHour;
      var consolidation = this.getChartName();

      var selectedPeriodDate =
        moment(this.$store.getters.selectedPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.selectedPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var selectedComparisonPeriodDate =
        moment(this.$store.getters.comparePeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.comparePeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var priorPeriodDate =
        moment(this.$store.getters.priorPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      // var priorYearDate =
      //   moment(this.$store.getters.priorYearDate[0], "YYYY-MM-DD hh:mm:ss")
      //     .add("hours", 4)
      //     .format("MMM-DD-YYYY") +
      //   " to " +
      //   moment(
      //     this.$store.getters.priorYearDate[1],
      //     "YYYY-MM-DD hh:mm:ss"
      //   ).format("YYYY-MM-DD");
      //var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Selected Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Perior Period Date</td> <td> ${priorPeriodDate}</td> </tr> <tr> <td>Prior Year Date</td> <td> ${priorYearDate}</td> </tr> </tbody> </table>`;
      var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Current Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Comparison Period Date</td> <td> ${selectedComparisonPeriodDate}</td> </tr> <tr> <td>Perior Week Date</td> <td> ${priorPeriodDate}</td> </tr> </tbody> </table>`;
      return table;
    },

    csvClickSummary() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "SUMMARY REPORT.csv";
        var data = this.getTableSummary;
        for (var i = 0; i < data.length; i++) {
          delete data[i].currentPeriodChange;
          delete data[i].comparePeriodChange;
          delete data[i].priorWeek;
        }
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    // RECREATING DATA FOR CSV EXPORT
    csvData() {
      var data = this.getChartData;
      var sites = this.$store.state.filter.sites;
      var siteIds = this.$store.state.filter.selectedSites;
      sites = sites.filter(function (site) {
        return siteIds.includes(site._id);
      });
      sites = _.reverse(sites);
      var csvData = [];
      var array = _.range(0, sites.length);
      _.each(array, function (value) {
        var csvObj = {};
        var selectedPeriodData = data.selectedPeriod[value];
        var priorPeriodData = data.priorPeriod[value];
        //var priorYearData = data.priorYear[value];
        csvObj["site"] = sites[value].siteName;
        csvObj["currentPeriod"] = selectedPeriodData;
        csvObj["priorWeek"] = priorPeriodData;
        //csvObj["priorYear"] = priorYearData;
        csvData.push(csvObj);
      });
      return csvData;
    },
    csvData2() {
      var data = this.getChartData;
      var sites = this.$store.state.filter.sites;
      var siteIds = this.$store.state.filter.selectedSites;
      sites = sites.filter(function (site) {
        return siteIds.includes(site._id);
      });
      sites = _.reverse(sites);
      var selectedPeriodTotal = _.sum(data.selectedPeriod);
      var priorPeriodTotal = _.sum(data.priorPeriod);
      //var priorYearTotal = _.sum(data.priorYear);

      var csvData = [];
      var array = _.range(0, sites.length);
      _.each(array, function (value) {
        var csvObj = {};
        var selectedPeriodData = data.selectedPeriod[value];
        var priorPeriodData = data.priorPeriod[value];
        //var priorYearData = data.priorYear[value];
        csvObj["site"] = sites[value].siteName;

        csvObj["currentPeriod"] =
          _.round((selectedPeriodData / selectedPeriodTotal) * 100, 2) ||
          0 + "%";
        csvObj["priorWeek"] =
          _.round((priorPeriodData / priorPeriodTotal) * 100, 2) || 0 + "%";
        // csvObj["priorYear"] =
        //   _.round((priorYearData / priorYearTotal) * 100, 2) || 0 + "%";
        csvData.push(csvObj);
      });
      return csvData;
    },
    // GET ARROW CLASS
    getClass(rows) {
      if (Math.sign(rows) == -1 || rows == 0) return "arrow-down";
      else return "arrow-up";
    },
    // NAME FOR THE CHART
    getChartName() {
      var consolidation = "";
      switch (this.getConsolidation) {
        case "hour":
          consolidation = "Hour";
          break;
        case "dayOfMonth":
          consolidation = "Day";
          break;
        case "week":
          consolidation = "Week";
          break;
        case "month":
          consolidation = "Month";
          break;
        case "year":
          consolidation = "Year";
          break;
        default:
          consolidation = "Day";
      }
      return consolidation;
    },
    PageReady(loader = true) {
      if (loader) this.overlay = true;
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);

      this.timer2 = setInterval(() => {
        if (this.$store.state.filter.selectedZones.length > 0) {
          clearInterval(this.timer2);
          this.setChartData();
          this.timer2 = setInterval(() => {
            var data = this.getChartData;
            if (
              data["comparePeriod"].length > 0 &&
              data["priorPeriod"].length > 0 &&
              data["selectedPeriod"].length > 0
            ) {
              clearInterval(this.timer2);
              this.setChart();
              this.timer3 = setInterval(() => {
                this.PageReady(false);
              }, 20000);
            }
          }, 100);
        }
      }, 100);
    },
  },
  computed: {
    getSiteObjects() {
      var dataFilter = [];
      this.$store.state.filter.selectedSites.forEach((siteId) => {
        var AdultsiteObject = {},
          ChildsiteObject = {};
        (AdultsiteObject["kpiGroupId"] = "5a41fd655bca800604b146cc"),
          (AdultsiteObject["kpiId"] = "5a41fd655bca80kpi14b147cc"),
          (AdultsiteObject["elementId"] = "Adult"),
          (AdultsiteObject["label"] = "HOURLY"),
          (AdultsiteObject["color"] = "#7F186E"),
          (AdultsiteObject["showOnAxis"] = "y1"),
          (AdultsiteObject["mapped"] = [
            {
              mappedFullSite: 1,
              siteId: siteId,
            },
          ]);
        (ChildsiteObject["kpiGroupId"] = "5a41fd655bca800604b146cc"),
          (ChildsiteObject["kpiId"] = "5a41fd655bca80kpi14b147cc"),
          (ChildsiteObject["elementId"] = "Child"),
          (ChildsiteObject["label"] = "HOURLY"),
          (ChildsiteObject["color"] = "#7F186E"),
          (ChildsiteObject["showOnAxis"] = "y1"),
          (ChildsiteObject["mapped"] = [
            {
              mappedFullSite: 1,
              siteId: siteId,
            },
          ]);
        dataFilter.push(ChildsiteObject);
        dataFilter.push(AdultsiteObject);
      });
      return _.reverse(dataFilter);
    },
    // GET PARAMETERS
    getParameters() {
      var payloads = [];
      var slelectedPeriodPayload = {};
      var comparisionPeriodPayload = {};
      var priorPeriodPayload = {};
      var priorYearPayload = {};

      var params = JSON.parse(JSON.stringify(this.params));
      params.dataFilter = this.getSiteObjects;
      params.timeFrame = this.$store.state.filter.consolidation;
      // params.dataFilter[0].mapped[0].zones = this.$store.state.filter.selectedZones;
      params.showDataByOperationalHours = this.$store.state.filter.operationalHour;

      //SELECTED PERIOD
      var params1 = JSON.parse(JSON.stringify(params));
      params1.from = this.$store.getters.selectedPeriodDate[0];
       params1.from = moment(params1.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      params1.to = this.$store.getters.selectedPeriodDate[1];
      slelectedPeriodPayload["params"] = params1;
      slelectedPeriodPayload["mutationId"] = "selectedPeriodData";
      payloads.push(slelectedPeriodPayload);

      //PRIOR PERIOD
      var params2 = JSON.parse(JSON.stringify(params));
      params2.from = this.$store.getters.priorPeriodDate[0];
      params2.to = this.$store.getters.priorPeriodDate[1];
      params2.from = moment(params2.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      priorPeriodPayload["params"] = params2;
      priorPeriodPayload["mutationId"] = "priorPeriodData";
      payloads.push(priorPeriodPayload);

      //PRIOR YEAR
      var params3 = JSON.parse(JSON.stringify(params));
      params3.from = this.$store.getters.priorYearDate[0];
      params3.to = this.$store.getters.priorYearDate[1];
      priorYearPayload["params"] = params3;
      priorYearPayload["mutationId"] = "priorYearData";
      payloads.push(priorYearPayload);

      //COMPARISION PERIOD
      var params4 = JSON.parse(JSON.stringify(params));
      params4.from = this.$store.getters.comparePeriodDate[0];
      params4.to = this.$store.getters.comparePeriodDate[1];
      params4.from = moment(params4.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      comparisionPeriodPayload["params"] = params4;
      comparisionPeriodPayload["mutationId"] = "comparePeriodData";
      payloads.push(comparisionPeriodPayload);

      return payloads;
    },
    // GET CONSOLIDATION STATE FROM STORE
    getConsolidation() {
      return this.$store.state.filter.consolidation;
    },
    // GET STORE DATA FROM VUEX STORE
    getChartData() {
      var data = {};
      var selectedPeriodData = [];
      var comparePeriodData = [];
      var priorPeriodData = [];
      var PriorYearData = [];
      var selectedPeriodLabel = [];
      var comparePeriodLabel = [];
      var priorPeriodLabel = [];
      var priorYearLabel = [];
      var comparePeriodDate = [];

      var selectedPeriodDate = [];
      var priorYearDate = [];
      // console.log(this.$store.state.home.selectedPeriodData);
      this.$store.state.home.selectedPeriodData.forEach((site) => {
        var value = 0;
        var date = 0;
        var labelName = site.label.split("::")[0];
        var exIndex = selectedPeriodLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          selectedPeriodData[exIndex] = selectedPeriodData[exIndex] + value;
        } else {
          selectedPeriodLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
              date = kpi.date;
            });
          } else {
            value = 0;
          }
          selectedPeriodDate.push(date);
          selectedPeriodData.push(value);
        }
      });
      this.$store.state.home.comparePeriodData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[0];
        var exIndex = comparePeriodLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          comparePeriodData[exIndex] = comparePeriodData[exIndex] + value;
        } else {
          comparePeriodLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
              let date = kpi.date;
              comparePeriodDate.push(date);
            });
          } else {
            value = 0;
          }
          comparePeriodData.push(value);
        }
      });
      this.$store.state.home.priorPeriodData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[0];
        var exIndex = priorPeriodLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          priorPeriodData[exIndex] = priorPeriodData[exIndex] + value;
        } else {
          priorPeriodLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          priorPeriodData.push(value);
        }
      });
      this.$store.state.home.priorYearData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[0];
        var exIndex = priorYearLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
              var date = kpi.date;
              priorYearDate.push(date);
            });
          } else {
            value = 0;
          }
          PriorYearData[exIndex] = PriorYearData[exIndex] + value;
        } else {
          priorYearLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
              var date = kpi.date;
              priorYearDate.push(date);
            });
          } else {
            value = 0;
          }
          PriorYearData.push(value);
        }
      });
      data["selectedPeriodLabel"] = selectedPeriodLabel;
      data["comparePeriodLabel"] = comparePeriodLabel;
      data["priorPeriodLabel"] = priorPeriodLabel;
      data["priorYearLabel"] = priorYearLabel;
      data["selectedPeriod"] = selectedPeriodData;
      data["comparePeriod"] = comparePeriodData;
      data["priorPeriod"] = priorPeriodData;
      data["priorYear"] = PriorYearData;
      data["selectedPeriodDate"] = selectedPeriodData;
      data["selectedPeriodDate"] = selectedPeriodDate;
      data["priorYearDate"] = priorYearDate;
      data["comparePeriodDate"] = comparePeriodDate;
      return data;
    },

    // CHECKING IF DATA IS NOT EMPTY
    isData() {
      var series = this.chartOptions.series;
      return series[0].data.length == 0 &&
        series[1].data.length == 0 &&
        series[2].data.length == 0
        ? false
        : true;
    },

    sqmComparision() {
      var sqmData = JSON.parse(JSON.stringify(sqm));
      var sites = _.find(sqmData, {
        clientId: localStorage.getItem("clientId"),
      }).sites;
      var data = [];

      // SELECTED PERIOD
      var selectedPeriodData = [];
      this.$store.state.home.selectedPeriodData.forEach((site) => {
        if (site.data.length != 0) {
          var sitesSqm = _.find(sites, { id: site.siteIds[0] });
          if (sitesSqm != undefined) {
            var sqmValue = sitesSqm.sqm;
            var selectedPeriodObj = {};
            selectedPeriodObj["label"] = site.label.split("::")[0];
            var value = 0;
            var valueCount = site.data.length;
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
            var avgCount = value / valueCount;

            selectedPeriodObj["avgCountSelectedPeriod"] = Math.round(avgCount);
            selectedPeriodObj["sqm"] = sqmValue;
            selectedPeriodObj["sqmSelectedPeriod"] = Number((value / sqmValue).toFixed(2));
            selectedPeriodData.push({ ...selectedPeriodObj });
          }
        }
      });

      // COMPARE PERIOD
      var comparePeriodData = [];
      this.$store.state.home.comparePeriodData.forEach((site) => {
        if (site.data.length != 0) {
          var sitesSqm = _.find(sites, { id: site.siteIds[0] });
          if (sitesSqm != undefined) {
            var sqmValue = sitesSqm.sqm;
            //var sqmValue = _.find(sites, { id: site.siteIds[0] }).sqm;
            var comparePeriodObj = {};
            comparePeriodObj["label"] = site.label.split("::")[0];
            var value = 0;
            var valueCount = site.data.length;
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
            var avgCount = value / valueCount;
            comparePeriodObj["avgCountComparePeriod"] = Math.round(avgCount);
            comparePeriodObj["sqm"] = sqmValue;
            comparePeriodObj["sqmComparePeriod"] = Number((value / sqmValue).toFixed(2));
            comparePeriodData.push({ ...comparePeriodObj });
          }
        }
      });

      // PRIOR YEAR
      // var priorYearData = [];
      // this.$store.state.home.priorYearData.forEach((site) => {
      //   if (site.data.length != 0) {
      //     var sitesSqm = _.find(sites, { id: site.siteIds[0] });
      //     if (sitesSqm != undefined) {
      //       var sqmValue = sitesSqm.sqm;
      //       //var sqmValue = _.find(sites, { id: site.siteIds[0] }).sqm;
      //       var priorYearObj = {};
      //       priorYearObj["label"] = site.label.split("::")[0];
      //       var value = 0;
      //       var valueCount = site.data.length;
      //       site.data.forEach((kpi) => {
      //         value += kpi.value;
      //       });
      //       var avgCount = value / valueCount;
      //       priorYearObj["avgCountPriorYear"] = Math.round(avgCount);
      //       priorYearObj["sqm"] = sqmValue;
      //       priorYearObj["sqmPriorYear"] = (value / sqmValue).toFixed(2);
      //       priorYearData.push({ ...priorYearObj });
      //     }
      //   }
      // });
      // let obj = {};
      // if (selectedPeriodData.length > 1) {
      //   selectedPeriodData.forEach((item) => {
      //     if (obj[item.name]) {
      //       obj[item.name].value = obj[item.name].value + item.value;
      //     } else {
      //       obj[item.name] = item;
      //     }
      //   });
      //   selectedPeriodData=obj;
      //   obj={};
      // }
      // if (comparePeriodData.length > 1) {
      //   comparePeriodData.forEach((item) => {
      //     if (obj[item.name]) {
      //       obj[item.name].value = obj[item.name].value + item.value;
      //     } else {
      //       obj[item.name] = item;
      //     }
      //   });
      //   comparePeriodData=obj;
      //    obj={};
      // }
      // console.log(selectedPeriodData);
      // console.log(comparePeriodData);
      data = _.filter(
        //_.merge(selectedPeriodData, comparePeriodData, priorYearData),
        _.merge(selectedPeriodData, comparePeriodData),
        function (o) {
          return o.sqm > 0;
        }
      );
      // var res = _(data).groupBy('label').map(_.spread(_.assign)).value();
      data= _(data)
        .groupBy("label")
        .map((objs, key) => ({
          'avgCountComparePeriod':_.sumBy(objs, "avgCountComparePeriod"),
          'avgCountSelectedPeriod':_.sumBy(objs, "avgCountSelectedPeriod"),
          'sqm':objs[0].sqm,
          'label': key,
          'sqmComparePeriod': _.sumBy(objs, "sqmComparePeriod"),
          'sqmSelectedPeriod': _.sumBy(objs, "sqmSelectedPeriod")
        }))
        .value();
      return data;
    },
    getTableSummary() {
      var data = this.getChartData;
      var tableData = [];
      var labels = data["selectedPeriodLabel"];
      for (var i = 0; i < labels.length; i++) {
        var tableDataObj = {};
        tableDataObj["name"] = labels[i];

        tableDataObj["currentPeriod"] = data["selectedPeriod"][i];
        tableDataObj["currentPeriodChange"] = isFinite(
          (data["selectedPeriod"][i] - data["priorPeriod"][i]) /
            data["priorPeriod"][i]
        )
          ? (
              (data["selectedPeriod"][i] - data["priorPeriod"][i]) /
              data["priorPeriod"][i]
            ).toFixed(2)
          : 0;

        tableDataObj["comparePeriod"] = data["comparePeriod"][i];
        tableDataObj["comparePeriodChange"] = isFinite(
          (data["selectedPeriod"][i] - data["comparePeriod"][i]) /
            data["comparePeriod"][i]
        )
          ? (
              (data["selectedPeriod"][i] - data["comparePeriod"][i]) /
              data["comparePeriod"][i]
            ).toFixed(2)
          : 0;

        // tableDataObj["priorYear"] = data["priorYear"][i];
        // tableDataObj["priorYearChange"] = isFinite(
        //   (data["selectedPeriod"][i] - data["priorYear"][i]) /
        //     data["priorYear"][i]
        // )
        //   ? (
        //       (data["selectedPeriod"][i] - data["priorYear"][i]) /
        //       data["priorYear"][i]
        //     ).toFixed(2)
        //   : 0;
        tableDataObj["priorWeek"] = data["priorPeriod"][i];

        // var periodCount =
        //   data["selectedPeriodDate"].length >= data["priorYearDate"].length
        //     ? data["selectedPeriodDate"].length
        //     : data["priorYearDate"].length;

        var selectedPeriodCount = data.selectedPeriod[i] || 0;
        var priorYearCount = data.priorYear[i];
        if (priorYearCount > 0) {
          var variance =
            ((selectedPeriodCount - priorYearCount) / priorYearCount) * 100 ||
            0;
          tableDataObj["variance"] = variance.toFixed(2);
        }

        tableData.push(tableDataObj);
      }
      return tableData;
    },
    getMonthName(monthInt) {
      var month = new Array();
      month[1] = "January";
      month[2] = "February";
      month[3] = "March";
      month[4] = "April";
      month[5] = "May";
      month[6] = "June";
      month[7] = "July";
      month[8] = "August";
      month[9] = "September";
      month[10] = "October";
      month[11] = "November";
      month[12] = "December";
      return month[monthInt];
    },
  },
  created() {
    this.$root.$refs.current_page = this;
    // this.setChartData();
    // this.setChart();
    // this.timer1 = setInterval(this.setChartDataAsync, 10000);
    // this.timer2 = setInterval(this.setChart, 5 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
  },
  // updated: function () {
  //   this.overlay = false;
  // },
  // WATCHING FOR CHANGE IN THE CONSOLIDATION
  // watch: {
  //   getParameters: function () {
  //     this.setChartData();
  //   },
  //   getChartData: function () {
  //     this.setChart();
  //   },
  //   // overlay(val) {
  //   //   val &&
  //   //     setTimeout(() => {
  //   //       this.overlay = false;
  //   //     }, 5000);
  //   // }
  // },
};
</script>

<style>
</style>