<template>
  <v-container>
    <!-- <div class="pa-1 title">ZONES</div> -->
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <v-card>
      <div ref="comparision">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-compare</v-icon>
              <span class="pa-4">Gate COMPARISION</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="pdfClickComparision()"
                    >
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickComparision()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts class="chart" :options="chartOptions"></highcharts>
            </v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>

    <v-card class="mt-4">
      <div ref="contribution">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-chart-pie</v-icon>
              <span class="pa-4">Gate CONTRIBUTION</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickContribution()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts class="chart" :options="pipChartOptions"></highcharts>
            </v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>

    <!-- SUMMARY REPORT -->
    <v-card class="mt-4">
      <div>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2" ref="entrance">
              <v-icon size="40" color="black"
                >mdi-file-document-box-outline</v-icon
              >
              <span class="pa-4">ENTRANCE SUMMARY</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickSummary()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-text-field
              v-model="summarySearch"
              append-icon="mdi-feature-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
          <!-- <v-spacer></v-spacer> -->
        </v-card-title>
        <v-data-table
          :headers="summaryHeader"
          :items="getTableSummary"
          :search="summarySearch"
          id="summaryTable"
        >
          <template v-slot:item.currentPeriodChange="{ item }">
            <div :class="getClass(item.currentPeriodChange)"></div>
            {{ item.currentPeriodChange }}
          </template>

          <template v-slot:item.comparePeriodChange="{ item }">
            <div :class="getClass(item.comparePeriodChange)"></div>
            {{ item.comparePeriodChange }}
          </template>

          <template v-slot:item.priorWeekChange="{ item }">
            <div :class="getClass(item.priorWeekChange)"></div>
            {{ item.priorWeekChange }}
          </template>

          <template v-slot:item.variance="{ item }">
            <div :class="getClass(item.variance)"></div>
            {{ item.variance }}
          </template>
        </v-data-table>
      </div>
    </v-card>

    <v-snackbar :timeout="6000" :top="true" v-model="showAlert">
      {{ alertMessage }}
      <v-btn color="red" text @click="showAlert = false">Close</v-btn>
    </v-snackbar>

    <!-- {{this.getChartData}} -->
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
const expo = require("../plugins/export-2");
var priorPeriodLegend = false;
var priorYearLegend = false;
export default {
  name: "gate",
  data() {
    return {
      timer1: "",
      timer2: "",
      timer3: "",
      overlay: true,
      showAlert: false,
      computedCall: 3,
      passedCall: 1,
      alertMessage: "",
      // CHART DATA
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          width: 1200,
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        plotOptions: {
          column: {
            events: {
              legendItemClick: function () {
                if (this.userOptions.index == 2) {
                  priorPeriodLegend = !priorPeriodLegend;
                } else if (this.userOptions.index == 3) {
                  priorYearLegend = !priorYearLegend;
                }
              },
            },
          },
          series: {
            dataLabels: {
              enabled: true,
              rotation : 270,
              style : {
                fontSize : 9,
              },
              allowOverlap : true
            },
          },
        },
        series: [
          {
            name: "Current period",
            data: [],
            color: "green",
          },
          {
            name: "Compare period",
            data: [],
            color: "red",
          },
          {
            name: "Prior week",
            data: [],
            color: "blue",
          },
          // {
          //   name: "Prior year",
          //   data: [],
          //   color: "purple",
          // },
        ],
      },
      pipChartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "pie",
          height: 440,
        },
        title: {
          text: "",
        },
        legend: {
          itemMarginTop: 2,
          // itemMarginBottom: 5
          // margin: 100
        },
        plotOptions: {
          pie: {
            // innerSize: "50%",
            showInLegend: true,
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        labels: {
          items: [
            {
              html: "current period",
              style: {
                left: "570px",
                top: "300px",
              },
            },
            // {
            //   html: "prior year",
            //   style: {
            //     left: "810px",
            //     top: "250px",
            //   },
            // },
          ],
        },
        series: [
          {
            name: "current period",
            size: 220,
            center: [600, 110],
            data: [],
          },
          // {
          //   showInLegend: false,
          //   name: "prior year",
          //   size: 220,
          //   center: [830, 80],
          //   data: [],
          // },
        ],
      },
      // KPI DATA PARAMETERS
      params: {
        kpiType: "numeric",
        fetchDataFor: "line",
        dataFilter: [],
        from: "",
        to: "",
        timeFrame: "year",
        consolidateSiteData: 0,
        consolidateData: 0,
        localTimezone: "+04:00",
        showDataByOperationalHours: true,
      },
      sqmHeader: [
        {
          text: "SQM(m<sup>2</sup>)",
          value: "Site Name",
          align: "center",
        },
      ],
      summaryHeader: [
        {
          text: "Entrance Name",
          value: "name",
          align: "center",
        },
        {
          text: "Current Period",
          value: "currentPeriod",
          align: "center",
        },
        {
          text: "Compare Period",
          value: "comparePeriod",
          align: "center",
        },

        {
          text: "Variance %",
          value: "variance",
          align: "center",
        },
        {
          text: "Change",
          value: "comparePeriodChange",
          align: "center",
        },
      ],
      summarySearch: "",
    };
  },
  methods: {
    // STORE CHART DATA INTO VUEX STORE
    setChartData() {
      // if (this.passedCall > this.computedCall) this.overlay = false;
      // this.passedCall += 1;
      var params = this.getParameters;
      params.forEach((param) => {
        this.$store.dispatch("getKpiData", param);
      });
      // clearInterval(this.timer1);
      // this.timer1 = setInterval(this.setChartDataAsync, 10000);
    },

    // ASSIGNING DATA TO CHART
    setChart() {
      var data = this.getChartData;
      this.chartOptions.yAxis.title.text = "";
      var label =
        data["selectedPeriodLabel"].length >= data["priorPeriodLabel"].length
          ? data["selectedPeriodLabel"]
          : data["priorPeriodLabel"];

      if (label.length > 0 && label[0] != undefined) {
        let labelSorted = new Array(label.length);
        let labelSortedZone = new Array(label.length);
        let labelSortedT = [];
        //let labelSortedZoneT = [];
        for (var s = 0; s < label.length; s++) {
          if (label[s].includes("Gate")) {
            labelSorted[s] = parseInt(label[s].replace("Gate", "").trim());
          } else if (label[s].includes("Zone") && label[s].indexOf("Zone") == 0)
            labelSortedZone[s] = label[s];

          labelSortedT[s] = label[s];
        }

        labelSorted.sort(function (a, b) {
          return a < b ? -1 : a > b ? 1 : 0;
        });

        labelSortedZone.sort(function (a, b) {
          return a < b ? -1 : a > b ? 1 : 0;
        });

        let lblSortedT = 0;
        let lblSortedZ = 0;
        for (var t = 0; t < label.length; t++) {
          if (label[t].includes("Gate")) {
            labelSortedT[t] = "Gate " + labelSorted[lblSortedT];
            lblSortedT++;
          } else if (
            label[t].includes("Zone") &&
            label[t].indexOf("Zone") == 0
          ) {
            labelSortedT[t] = labelSortedZone[lblSortedZ];
            lblSortedZ++;
          }
        }

        let selectedPeriodSorted = new Array(data["selectedPeriod"].length);
        let comparePeriodSorted = new Array(data["comparePeriod"].length);
        let priorPeriodSorted = new Array(data["priorPeriod"].length);
        let priorYearSorted = new Array(data["priorYear"].length);

        for (var u = 0; u < label.length; u++) {
          let indexOf = label.indexOf(labelSortedT[u]);
          selectedPeriodSorted[u] = data["selectedPeriod"][indexOf];
          comparePeriodSorted[u] = data["comparePeriod"][indexOf];
          priorPeriodSorted[u] = data["priorPeriod"][indexOf];
          priorYearSorted[u] = data["priorYear"][indexOf];
        }

        this.chartOptions.xAxis.categories = labelSortedT;
        // this.pipChartOptions.xAxis.categories = labelSortedT;
        this.chartOptions.series[0].data = selectedPeriodSorted;
        this.chartOptions.series[1].data = comparePeriodSorted;
        this.chartOptions.series[2].data = priorPeriodSorted;
        //this.chartOptions.series[3].data = priorYearSorted;

        var pieDataSelectedPeriod = [];
        for (var i = 0; i < selectedPeriodSorted.length; i++) {
          var pieData1 = [];
          pieData1.push(labelSortedT[i]);
          pieData1.push(selectedPeriodSorted[i]);
          pieDataSelectedPeriod.push(pieData1);
        }
        this.pipChartOptions.series[0].data = pieDataSelectedPeriod;
      } else {
        this.chartOptions.xAxis.categories = label;
        this.chartOptions.series[0].data = data["selectedPeriod"];
        this.chartOptions.series[1].data = data["comparePeriod"];
        this.chartOptions.series[2].data = data["priorPeriod"];
        //this.chartOptions.series[3].data = data["priorYear"];
        var pieDataSelectedPeriodx = [];
        for (t = 0; t < data.selectedPeriodLabel.length; t++) {
          var pieData4 = [];
          pieData4.push(data.selectedPeriodLabel[t]);
          pieData4.push(data.selectedPeriod[t]);
          pieDataSelectedPeriodx.push(pieData4);
        }
        this.pipChartOptions.series[0].data = pieDataSelectedPeriodx;
      }

      this.chartOptions.series[2].visible = priorPeriodLegend;
      //this.chartOptions.series[3].visible = priorYearLegend;

      this.chartOptions.series[0].name =
        "Current Period (" +
        moment(this.$store.getters.selectedPeriodDate[0]).format("DD/MM/YY") +
        " to " +
        moment(this.$store.getters.selectedPeriodDate[1]).format("DD/MM/YY") +
        ")";

      this.chartOptions.series[1].name =
        "Compare Period (" +
        moment(this.$store.getters.comparePeriodDate[0]).format("DD/MM/YY") +
        " to " +
        moment(this.$store.getters.comparePeriodDate[1]).format("DD/MM/YY") +
        ")";

      this.chartOptions.series[2].name =
        "Prior Week (" +
        moment(this.$store.getters.priorPeriodDate[0]).format("DD/MM/YY") +
        " to " +
        moment(this.$store.getters.priorPeriodDate[1]).format("DD/MM/YY") +
        ")";

      // this.chartOptions.series[3].name =
      //   "Prior Year (" +
      //   moment(this.$store.getters.priorYearDate[0]).format("DD/MM/YY") +
      //   " to " +
      //   moment(this.$store.getters.priorYearDate[1]).format("DD/MM/YY") +
      //   ")";

      // FOR PIE CHART
      // var pieDataSelectedPeriod = [];
      var pieDataPriorYearPeriod = [];
      // for (var i = 0; i < data.selectedPeriodLabel.length; i++) {
      //   var pieData1 = [];
      //   pieData1.push(data.selectedPeriodLabel[i]);
      //   pieData1.push(data.selectedPeriod[i]);
      //   pieDataSelectedPeriod.push(pieData1);
      // }

      for (var k = 0; k < data.selectedPeriodLabel.length; k++) {
        var pieData3 = [];
        pieData3.push(data.selectedPeriodLabel[k]);
        pieData3.push(data.priorYear[k]);
        pieDataPriorYearPeriod.push(pieData3);
      }
      // console.log(pieDataSelectedPeriod)
      // this.pipChartOptions.series[0].data = pieDataSelectedPeriod;
      //this.pipChartOptions.series[1].data = pieDataPriorYearPeriod;
      // if (
      //   data["selectedPeriod"].length > 0 &&
      //   data["comparePeriod"].length > 0 &&
      //   data["priorPeriod"].length > 0
      // )
      this.overlay = false;
    },
    // EXPORT CONTENT AS PDF USING THE REFERENCE ID
    pdfClickComparision() {
      var that = this;

      if (
        document.getElementsByClassName("v-navigation-drawer--mini-variant")
          .length != 1
      )
        document.querySelector(".v-navigation-drawer button").click();

      setTimeout(function () {
        if (that.isData) {
          that.showAlert = true;
          var filename = "Gate COMPARISION.pdf";
          var content = that.$refs.comparision;
          var content1 = that.$refs.contribution;
          var contentHeader = that.$refs.entrance;
          var text = that.pdfFilterData();
          var response = expo.createGatePDF(
            filename,
            text,
            content,
            content1,
            contentHeader
          );
          that.alertMessage = response;
        } else {
          that.alertMessage = "No data to export";
        }
      }, 500);
    },
    // EXPORTING VALUES TO CSV FORMAT
    csvClickComparision() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "ZONE COMPARISION.csv";
        var data = this.csvData();
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    // EXPORTING VALUES TO CSV FORMAT
    csvClickContribution() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "ZONE CONTRIBUTION.csv";
        var data = this.csvData2();
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    csvClickSummary() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "SUMMARY REPORT.csv";
        var data = this.getTableSummary;
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },

    pdfFilterData() {
      var reportName = "Gate Comparison";
      var generationDate = new Date();
      var siteNames = _.map(this.$store.getters.selectedSites, "siteName");
      var zoneNames = _.map(this.$store.getters.selectedZones, "zoneName");
      var operationalHour = this.$store.state.filter.operationalHour;
      var consolidation = this.getChartName();

      var selectedPeriodDate =
        moment(this.$store.getters.selectedPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.selectedPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var selectedComparisonPeriodDate =
        moment(this.$store.getters.comparePeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.comparePeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var priorPeriodDate =
        moment(this.$store.getters.priorPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");
      // var priorYearDate =
      //   moment(this.$store.getters.priorYearDate[0], "YYYY-MM-DD hh:mm:ss")
      //     .add("hours", 4)
      //     .format("MMM-DD-YYYY") +
      //   " to " +
      //   moment(
      //     this.$store.getters.priorYearDate[1],
      //     "YYYY-MM-DD hh:mm:ss"
      //   ).format("YYYY-MM-DD");
      //var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Selected Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Comparison Period Date</td> <td> ${selectedComparisonPeriodDate}</td> </tr> <tr> <td>Perior Period Date</td> <td> ${priorPeriodDate}</td> </tr> <tr> <td>Prior Year Date</td> <td> ${priorYearDate}</td> </tr> </tbody> </table>`;
      var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Current Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Comparison Period Date</td> <td> ${selectedComparisonPeriodDate}</td> </tr> <tr> <td>Prior Week Date</td> <td> ${priorPeriodDate}</td> </tr> </tbody> </table>`;
      return table;
    },
    // RECREATING DATA FOR CSV EXPORT
    csvData() {
      var data = this.getChartData;
      var zones = this.$store.state.filter.zones;
      var zoneIds = this.$store.state.filter.selectedZones;
      zones = zones.filter(function (zone) {
        return zoneIds.includes(zone._id);
      });
      var csvData = [];
      var array = _.range(0, zones.length);
      _.each(array, function (value) {
        var csvObj = {};
        var selectedPeriodData = data.selectedPeriod[value];
        var priorPeriodData = data.priorPeriod[value];
        //var priorYearData = data.priorYear[value];
        csvObj["zone"] = zones[value].zoneName;
        csvObj["currentPeriodData"] = selectedPeriodData;
        csvObj["priorWeekData"] = priorPeriodData;
        //csvObj["priorYearData"] = priorYearData;
        csvData.push(csvObj);
      });

      return csvData;
    },
    csvData2() {
      var data = this.getChartData;
      var zones = this.$store.state.filter.zones;
      var zoneIds = this.$store.state.filter.selectedZones;
      zones = zones.filter(function (zone) {
        return zoneIds.includes(zone._id);
      });
      var csvData = [];
      var selectedPeriodTotal = _.sum(data.selectedPeriod);
      var priorPeriodTotal = _.sum(data.priorPeriod);
      //var priorYearTotal = _.sum(data.priorYear);
      var array = _.range(0, zones.length);
      _.each(array, function (value) {
        var csvObj = {};
        var selectedPeriodData = data.selectedPeriod[value];
        var priorPeriodData = data.priorPeriod[value];
        //var priorYearData = data.priorYear[value];
        csvObj["zone"] = zones[value].zoneName;
        csvObj["currentPeriod"] =
          _.round((selectedPeriodData / selectedPeriodTotal) * 100, 2) ||
          0 + "%";
        csvObj["priorWeek"] =
          _.round((priorPeriodData / priorPeriodTotal) * 100, 2) || 0 + "%";
        // csvObj["priorYear"] =
        //   _.round((priorYearData / priorYearTotal) * 100, 2) || 0 + "%";
        csvData.push(csvObj);
      });

      return csvData;
    },
    getsiteIdByZone(zoneId) {
      var zones = this.$store.state.filter.zones;
      var siteId = _.filter(zones, { _id: zoneId })[0]["siteId"];
      return siteId;
    },
    // GET ARROW CLASS
    getClass(rows) {
      if (Math.sign(rows) == -1 || rows == 0) return "arrow-down";
      else return "arrow-up";
    },
    // NAME FOR THE CHART
    getChartName() {
      var consolidation = "";
      switch (this.getConsolidation()) {
        case "hour":
          consolidation = "Hour";
          break;
        case "dayOfMonth":
          consolidation = "Day";
          break;
        case "week":
          consolidation = "Week";
          break;
        case "month":
          consolidation = "Month";
          break;
        case "year":
          consolidation = "Year";
          break;
        default:
          consolidation = "Day";
      }
      return consolidation;
    },
    getConsolidation() {
      return this.$store.state.filter.consolidation;
    },
    PageReady(loader = true) {
      if (loader) this.overlay = true;
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);

      this.timer2 = setInterval(() => {
        if (this.$store.state.filter.selectedZones.length > 0) {
          clearInterval(this.timer2);
          this.setChartData();
          this.timer2 = setInterval(() => {
            var data = this.getChartData;
            if (
              data["comparePeriod"].length > 0 &&
              data["priorPeriod"].length > 0 &&
              data["selectedPeriod"].length > 0
            ) {
              clearInterval(this.timer2);
              this.setChart();
              this.timer3 = setInterval(() => {
                this.PageReady(false);
              }, 20000);
            }
          }, 100);
        }
      }, 100);
    },
  },
  computed: {
    getSiteObjects() {
      var dataFilter = [];
      this.$store.state.filter.selectedSites.forEach((siteId) => {
        var adultSiteObject = {},
          childSiteObject = {};
        (adultSiteObject["kpiGroupId"] = "5a41fd655bca800604b146cc"),
          (adultSiteObject["kpiId"] = "5a41fd655bca80kpi14b147cc"),
          (adultSiteObject["elementId"] = "Adult"),
          (adultSiteObject["label"] = "HOURLY"),
          (adultSiteObject["color"] = "#7F186E"),
          (adultSiteObject["showOnAxis"] = "y1"),
          (adultSiteObject["mapped"] = [
            {
              mappedFullSite: 1,
              siteId: siteId,
            },
          ]);
        (childSiteObject["kpiGroupId"] = "5a41fd655bca800604b146cc"),
          (childSiteObject["kpiId"] = "5a41fd655bca80kpi14b147cc"),
          (childSiteObject["elementId"] = "Child"),
          (childSiteObject["label"] = "HOURLY"),
          (childSiteObject["color"] = "#7F186E"),
          (childSiteObject["showOnAxis"] = "y1"),
          (childSiteObject["mapped"] = [
            {
              mappedFullSite: 1,
              siteId: siteId,
            },
          ]);
        dataFilter.push(childSiteObject);
        dataFilter.push(adultSiteObject);
      });
      return _.reverse(dataFilter);
    },
    // GET PARAMETERS
    getParameters() {
      var payloads = [];
      var slelectedPeriodPayload = {};
      var comparisionPeriodPayload = {};
      var priorPeriodPayload = {};
      var priorYearPayload = {};

      var params = JSON.parse(JSON.stringify(this.params));
      params.dataFilter = this.getSiteObjects;
      params.showDataByOperationalHours =
        this.$store.state.filter.operationalHour;

      var zoneIds = this.$store.state.filter.selectedZones;
      var dataFilter = [];
      zoneIds.forEach((zoneId) => {
        var adultMapping = {
          kpiGroupId: "5a41fd655bca800604b146cc",
          kpiId: "5a41fd655bca80kpi14b147cc",
          elementId: "Adult",
          label: "HOURLY",
          color: "#7F186E",
          showOnAxis: "y1",
          mapped: [
            {
              mappedFullSite: 0,
              siteId: this.getsiteIdByZone(zoneId),
              zones: [zoneId],
            },
          ],
        };
        var childMapping = {
          kpiGroupId: "5a41fd655bca800604b146cc",
          kpiId: "5a41fd655bca80kpi14b147cc",
          elementId: "Child",
          label: "HOURLY",
          color: "#7F186E",
          showOnAxis: "y1",
          mapped: [
            {
              mappedFullSite: 0,
              siteId: this.getsiteIdByZone(zoneId),
              zones: [zoneId],
            },
          ],
        };
        dataFilter.push(adultMapping);
        dataFilter.push(childMapping);
      });
      params.dataFilter = dataFilter;

      //SELECTED PERIOD
      var params1 = JSON.parse(JSON.stringify(params));
      params1.from = this.$store.getters.selectedPeriodDate[0];
      params1.to = this.$store.getters.selectedPeriodDate[1];
      params1.from = moment(params1.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      slelectedPeriodPayload["params"] = params1;
      slelectedPeriodPayload["mutationId"] = "selectedPeriodData";
      payloads.push(slelectedPeriodPayload);

      //PRIOR PERIOD
      var params2 = JSON.parse(JSON.stringify(params));
      params2.from = this.$store.getters.priorPeriodDate[0];
      params2.from = moment(params2.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      params2.to = this.$store.getters.priorPeriodDate[1];
      priorPeriodPayload["params"] = params2;
      priorPeriodPayload["mutationId"] = "priorPeriodData";
      payloads.push(priorPeriodPayload);

      //PRIOR YEAR
      var params3 = JSON.parse(JSON.stringify(params));
      params3.from = this.$store.getters.priorYearDate[0];
      params3.to = this.$store.getters.priorYearDate[1];
      priorYearPayload["params"] = params3;
      priorYearPayload["mutationId"] = "priorYearData";
      payloads.push(priorYearPayload);

      //COMPARISION PERIOD
      var params4 = JSON.parse(JSON.stringify(params));
      params4.from = this.$store.getters.comparePeriodDate[0];
      params4.from = moment(params4.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      params4.to = this.$store.getters.comparePeriodDate[1];
      comparisionPeriodPayload["params"] = params4;
      comparisionPeriodPayload["mutationId"] = "comparePeriodData";
      payloads.push(comparisionPeriodPayload);

      return payloads;
    },

    // GET STORE DATA FROM VUEX STORE
    getChartData() {
      var data = {};
      var selectedPeriodData = [];
      var comparePeriodData = [];
      var priorPeriodData = [];
      var PriorYearData = [];
      var selectedPeriodLabel = [];
      var comparePeriodLabel = [];
      var priorPeriodLabel = [];
      var priorYearLabel = [];
      var selectedPeriodDate = [];
      var comparePeriodDate = [];
      var priorYearDate = [];

      this.$store.state.home.selectedPeriodData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[1];
        var exIndex = selectedPeriodLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          selectedPeriodData[exIndex] = selectedPeriodData[exIndex] + value;
        } else {
          selectedPeriodLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
              var date =
                this.getConsolidation == "month"
                  ? this.getMonthName(kpi.date)
                  : kpi.date;
              selectedPeriodDate.push(date);
            });
          } else {
            value = 0;
          }
          selectedPeriodData.push(value);
        }
      });

      this.$store.state.home.comparePeriodData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[1];
        var exIndex = comparePeriodLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          comparePeriodData[exIndex] = comparePeriodData[exIndex] + value;
        } else {
          comparePeriodLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
              var date =
                this.getConsolidation == "month"
                  ? this.getMonthName(kpi.date)
                  : kpi.date;
              comparePeriodDate.push(date);
            });
          } else {
            value = 0;
          }
          comparePeriodData.push(value);
        }
      });

      this.$store.state.home.priorPeriodData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[1];
        var exIndex = priorPeriodLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          priorPeriodData[exIndex] = priorPeriodData[exIndex] + value;
        } else {
          priorPeriodLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          priorPeriodData.push(value);
        }
      });

      this.$store.state.home.priorYearData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[1];
        var exIndex = priorYearLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          PriorYearData[exIndex] = PriorYearData[exIndex] + value;
        } else {
          priorYearLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
              var date =
                this.getConsolidation == "month"
                  ? this.getMonthName(kpi.date)
                  : kpi.date;
              priorYearDate.push(date);
            });
          } else {
            value = 0;
          }
          PriorYearData.push(value);
        }
      });

      data["selectedPeriodLabel"] = selectedPeriodLabel;
      data["comparePeriodLabel"] = comparePeriodLabel;
      data["comparePeriodDate"] = comparePeriodDate;
      data["priorPeriodLabel"] = priorPeriodLabel;
      data["priorYearLabel"] = priorYearLabel;
      data["selectedPeriod"] = selectedPeriodData;
      data["comparePeriod"] = comparePeriodData;
      data["priorPeriod"] = priorPeriodData;
      data["priorYear"] = PriorYearData;
      data["selectedPeriodDate"] = selectedPeriodData;
      data["selectedPeriodDate"] = selectedPeriodDate;
      data["priorYearDate"] = priorYearDate;
      return data;
    },
    // CHECKING IF DATA IS NOT EMPTY
    isData() {
      var series = this.chartOptions.series;
      return series[0].data.length == 0 &&
        series[1].data.length == 0 &&
        series[2].data.length == 0
        ? false
        : true;
    },
    getTableSummary() {
      var data = this.getChartData;

      var tableData = [];
      var labels = data["selectedPeriodLabel"];

      for (var i = 0; i < labels.length; i++) {
        var tableDataObj = {};
        tableDataObj["name"] = labels[i];

        tableDataObj["currentPeriod"] = data["selectedPeriod"][i];
        tableDataObj["currentPeriodChange"] = isFinite(
          (data["selectedPeriod"][i] - data["priorPeriod"][i]) /
            data["priorPeriod"][i]
        )
          ? (
              (data["selectedPeriod"][i] - data["priorPeriod"][i]) /
              data["priorPeriod"][i]
            ).toFixed(2)
          : 0;

        tableDataObj["comparePeriod"] = data["comparePeriod"][i];
        tableDataObj["comparePeriodChange"] = isFinite(
          (data["selectedPeriod"][i] - data["comparePeriod"][i]) /
            data["comparePeriod"][i]
        )
          ? (
              (data["selectedPeriod"][i] - data["comparePeriod"][i]) /
              data["comparePeriod"][i]
            ).toFixed(2)
          : 0;

        //tableDataObj["priorYear"] = data["priorYear"][i];
        tableDataObj["priorYearChange"] = isFinite(
          (data["selectedPeriod"][i] - data["priorYear"][i]) /
            data["priorYear"][i]
        )
          ? (
              (data["selectedPeriod"][i] - data["priorYear"][i]) /
              data["priorYear"][i]
            ).toFixed(2)
          : 0;
        tableDataObj["priorWeek"] = data["priorPeriod"][i];

        // var periodCount =
        //   data["selectedPeriodDate"].length >= data["priorYearDate"].length
        //     ? data["selectedPeriodDate"].length
        //     : data["priorYearDate"].length;

        let selectedPeriodCount = data.selectedPeriod[i] || 0;
        let priorYearCount = data.priorYear[i];
        if (priorYearCount > 0) {
          let variance =
            ((selectedPeriodCount - priorYearCount) / priorYearCount) * 100 ||
            0;
          tableDataObj["variance"] = variance.toFixed(2);
        }

        // for (let j = 0; j < periodCount; j++) {
        //   let selectedPeriodCount = data.selectedPeriod[j] || 0;
        //   let priorYearCount = data.priorYear[j];
        //   if (priorYearCount > 0) {
        //     let variance =
        //       ((selectedPeriodCount - priorYearCount) / priorYearCount) * 100 ||
        //       0;
        //     tableDataObj["variance"] = variance.toFixed(2);

        //     debugger;
        //   }
        // }

        tableData.push(tableDataObj);
      }
      tableData.sort(function (a, b) {
        if (a.name == "Total Mall" || b.name == "Total Mall") return 5;
        return a.name.localeCompare(b.name, "en", { numeric: true });
      });

      return tableData;
    },
  },
  created() {
    this.$root.$refs.current_page = this;
    // this.setChartData();
    // this.setChart();
    // this.timer1 = setInterval(this.setChartDataAsync, 10000);
    // this.timer2 = setInterval(this.setChart, 3000);
  },
  beforeDestroy() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
  },
  getMonthName(monthInt) {
    var month = new Array();
    month[1] = "January";
    month[2] = "February";
    month[3] = "March";
    month[4] = "April";
    month[5] = "May";
    month[6] = "June";
    month[7] = "July";
    month[8] = "August";
    month[9] = "September";
    month[10] = "October";
    month[11] = "November";
    month[12] = "December";
    return month[monthInt];
  },
  // updated: function () {
  //   this.overlay = false;
  // },
  // WATCHING FOR CHANGE IN THE CONSOLIDATION
  // watch: {
  //   getParameters: function () {
  //     this.setChartData();
  //   },
  //   getChartData: function () {
  //     this.setChart();
  //   },
  //   // overlay(val) {
  //   //   val &&
  //   //     setTimeout(() => {
  //   //       this.overlay = false;
  //   //     }, 3000);
  //   // }
  // },
};
</script>

<style>
</style>