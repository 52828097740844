import axios from 'axios'
window.axios = axios


const state = {
    selectedPeriodData: [],
    comparePeriodData: [],
    priorPeriodData: [],
    priorYearData: [],
    footfallData: [],

    adultInData: [],
    adultOutData: [],
    childInData: [],
    childOutData: [],
    events: [],
    selectedEvent: "",
    cancelToken: ""
};
const mutations = {
    selectedPeriodData(state, value) {
        state.selectedPeriodData = value;
    },
    comparePeriodData(state, value) {
        state.comparePeriodData = value;
    },
    priorPeriodData(state, value) {
        state.priorPeriodData = value;
    },
    priorYearData(state, value) {
        state.priorYearData = value;
    },
    footfallData(state, value) {
        state.footfallData = value;
    },
    adultInData(state, value) {
        state.adultInData = value;
    },
    adultOutData(state, value) {
        state.adultOutData = value;
    },
    childInData(state, value) {
        state.childInData = value;
    },
    childOutData(state, value) {
        state.childOutData = value;
    },
    eventData(state, value) {
        state.events = value;
    },
    selectedEvent(state, value) {
        state.selectedEvent = value;
    },
    cancelToken(state, value) {
        state.cancelToken = value;
    }
};
let cancelToken = "";
const getters = {
    // selectedEvent: state => {
    //     return state.events.filter(function (event) {
    //         return state.selectedEvent.includes(event._id);
    //     });
    // },

};
const actions = {
    async getKpiData({ commit }, payload) {
        let token = localStorage.getItem('token');
        if (cancelToken === '')
            cancelToken = new axios.CancelToken.source();
        var config = {
            headers: { 'Authorization': "bearer " + token },
            cancelToken: cancelToken.token
        };
        if (!payload['params']["dataFilter"].length > 0)
            return;
        try {
            const resp = await axios.post('/kpi/data', payload['params'], config);
            let data = resp.data["dataFilter"]
            // debugger
            // data = data.map((df) => {
            //     if (df.data.length > 0) {
            //         df.data = df.data.sort((a, b) => {
            //             if (typeof a.date === 'number' && typeof b.date === 'number') {
            //                 return a.date - b.date;
            //             }
            //             return 0;
            //         });
            //     }
            //     return df;
            // });
            commit(payload['mutationId'], data);
            return data;
        }
        catch (err) {
            //eslint-disable-next-line
            console.log(err)
        }
        // new Promise((resolve) => {
        //     axios.post('/kpi/data', payload['params'], config)
        //         .then(r => r.data["dataFilter"])
        //         .then(data => {                                     
        //             commit(payload['mutationId'], data);
        //             resolve(data);
        //         })
        // }).catch(err => {
        //     //eslint-disable-next-line
        //     console.log(err)
        // })
    },
    cancelRequest() {
        if (cancelToken != "") {
            cancelToken.cancel();
            cancelToken = "";
        }
    },
    getEvents({ commit }) {

        var config = {
            headers: { 'Authorization': "bearer " + localStorage.getItem('token') }
        };
        new Promise((resolve) => {
            axios.get('/event', config
            )
                .then(r => r.data)
                .then(data => {
                    commit("eventData", data);
                    commit("selectedEvent", data[0]._id)
                    resolve(data);
                })
        })
            .catch(err => {
                //eslint-disable-next-line
                console.log(err)
            })
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};