import Vuex from 'vuex';
import Vue from 'vue';
import filter from './modules/filter';
import login from './modules/login';
import home from './modules/home'
// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        filter,
        login,
        home
    }
});
