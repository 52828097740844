<template>
  <v-container>
    <!-- <div class="pa-1 title">OVERVIEW</div> -->
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>

    <!-- BOXES -->
    <div ref="boxes">
      <v-row class="mb-6" no-gutters>
        <v-col v-for="box in getBoxData" :key="box.label">
          <v-card
            class="pa-2 mr-2 text-center"
            tile
            outlined
            :color="box.color"
            v-if="box.enabled"
          >
            <v-card-text class="black--text font-weight-bold">
              <h1 class="mb-4">{{ box.value }}</h1>
              <span>{{ box.label }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- OVERVIEW -->
    <v-card>
      <div ref="content">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8">
              <v-icon size="40" color="black">{{ this.getKPI.icon }}</v-icon>
              <span class="pa-4">{{ getKPI.text.toUpperCase() }}</span>
              <v-select
                @change="submitTypedrp"
                hide-details
                style="display: inline-table; width: 17%"
                v-model="kpiDefault"
                :items="kpis"
              ></v-select>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="submitType"
                    data-html2canvas-ignore="true"
                    class="primary ma-1"
                    v-on="on"
                  >
                    {{ chartType.name }}
                    <v-icon right>{{ chartType.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>Select Chart Type</span>
              </v-tooltip>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="pdfClick()">
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="csvClick()">
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts class="chart" :options="chartOptions"></highcharts>
            </v-flex>
            <v-flex>
              <ul
                class="list-group list-group-flush text-center"
                style="list-style: none"
              >
                <li class="list-group-item pb-8">
                  <div class="pb-12">
                    <span class="dot green"></span>
                    <!-- <label class="float-left">{{chartOptions.series[0].name.toUpperCase()}}</label> -->
                    <label class="float-left">CURRENT PERIOD</label>
                    <label class="float-right sub-title"
                      >{{ getTotal["selectedPeriod"]["datetime"]["from"] }} to
                      {{ getTotal["selectedPeriod"]["datetime"]["to"] }}</label
                    >
                  </div>

                  <div>
                    <h1>
                      {{ formatNumber(getTotal["selectedPeriod"]["total"]) }}
                    </h1>
                    <label>OVERALL {{ getKPI.text.toUpperCase() }}</label>
                  </div>
                </li>

                <li class="list-group-item pb-8 grey lighten-4">
                  <div class="pb-12">
                    <span class="dot blue"></span>
                    <!-- <label class="float-left">{{chartOptions.series[1].name.toUpperCase()}}</label> -->
                    <label class="float-left">COMPARE PERIOD</label>
                    <label class="float-right sub-title"
                      >{{ getTotal["comparisionPeriod"]["datetime"]["from"] }}
                      to
                      {{
                        getTotal["comparisionPeriod"]["datetime"]["to"]
                      }}</label
                    >
                  </div>
                  <div
                    :class="getClass(getTotal['comparisionPeriod']['change'])"
                  ></div>
                  <span style="float: left"
                    >{{ getTotal["comparisionPeriod"]["change"] }}%</span
                  >
                  <div>
                    <h1>
                      {{ formatNumber(getTotal["comparisionPeriod"]["total"]) }}
                    </h1>
                  </div>
                </li>
                <v-divider></v-divider>

                <li class="list-group-item pb-8 grey lighten-4">
                  <div class="pb-12">
                    <span class="dot blue"></span>
                    <!-- <label class="float-left">{{chartOptions.series[2].name.toUpperCase()}}</label> -->
                    <label class="float-left">PRIOR WEEK</label>
                    <label class="float-right sub-title"
                      >{{ getTotal["priorPeriod"]["datetime"]["from"] }} to
                      {{ getTotal["priorPeriod"]["datetime"]["to"] }}</label
                    >
                  </div>
                  <div
                    :class="getClass(getTotal['priorPeriod']['change'])"
                  ></div>
                  <span style="float: left"
                    >{{ getTotal["priorPeriod"]["change"] }}%</span
                  >
                  <div>
                    <h1>
                      {{ formatNumber(getTotal["priorPeriod"]["total"]) }}
                    </h1>
                  </div>
                </li>
                <v-divider></v-divider>

                <!-- <li class="list-group-item pb-8 grey lighten-4">
                  <div class="pb-12">
                    <span class="dot purple"></span>
                    
                    <label class="float-left">PRIOR YEAR</label>
                    <label class="float-right sub-title"
                      >{{ getTotal["priorYear"]["datetime"]["from"] }} to
                      {{ getTotal["priorYear"]["datetime"]["to"] }}</label
                    >
                  </div>

                  <div :class="getClass(getTotal['priorYear']['change'])"></div>
                  <span style="float: left"
                    >{{ getTotal["priorYear"]["change"] }}%</span
                  >
                  <div>
                    <h1>{{ formatNumber(getTotal["priorYear"]["total"]) }}</h1>
                  </div>
                </li> -->
              </ul>
            </v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>

    <!-- TABLE -->
    <div ref="custable" class="pt-2">
      <v-flex>
        <table id="table" class="special" style="width: 100%">
          <thead>
            <tr>
              <th>{{ this.getChartName }}</th>
              <th>Year</th>
              <th>Count</th>
              <!-- <th>Average</th> -->
              <th>Variance %</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="data in getTableData">
              <tr :key="data.selectedPeriodYear">
                <td>{{ data.timeFrame }}</td>
                <td>{{ data.selectedYear }}</td>
                <td>{{ data.selectedPeriodCount }}</td>
                 <td rowspan="2">{{ data.variance }}</td>
                <!-- <td>{{ data.selectedPeriodAvg }}</td> -->
              </tr>
              <tr :key="data.priorPeriodYear">
                <td>{{ data.compareTimeFrame }}</td>
                <td>{{ data.compareYear }}</td>
                <td>{{ data.comparePeriodCount }}</td>
                <!-- <td>{{ data.comparePeriodAvg }}</td> -->
               
              </tr>              
            </template>
          </tbody>
        </table>
      </v-flex>
    </div>

    <!-- POWER HOUR -->

    <v-card class="mt-4">
      <div ref="custable2">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-diameter</v-icon>
              <span class="pa-4">POWER HOURS</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickPowerHour()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
      </div>
      <v-card-text>
        <table id="table2" style="width: 100%">
          <thead>
            <tr>
              <th></th>
              <th>SUN</th>
              <th>MON</th>
              <th>TUE</th>
              <th>WED</th>
              <th>THU</th>
              <th>FRI</th>
              <th>SAT</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="data in getPowerHourData">
              <tr :key="data.hour">
                <td>{{ data.hourF }}</td>
                <td :style="getCellColor(data.Sunday)">{{ data.Sunday }}</td>
                <td :style="getCellColor(data.Monday)">{{ data.Monday }}</td>
                <td :style="getCellColor(data.Tuesday)">{{ data.Tuesday }}</td>
                <td :style="getCellColor(data.Wednesday)">
                  {{ data.Wednesday }}
                </td>
                <td :style="getCellColor(data.Thursday)">
                  {{ data.Thursday }}
                </td>
                <td :style="getCellColor(data.Friday)">{{ data.Friday }}</td>
                <td :style="getCellColor(data.Saturday)">
                  {{ data.Saturday }}
                </td>
                <td :style="getCellColor(data.total)">{{ data.total }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </v-card-text>
    </v-card>

    <v-snackbar :timeout="6000" :top="true" v-model="showAlert">
      {{ alertMessage }}
      <v-btn color="red" text @click="showAlert = false">Close</v-btn>
    </v-snackbar>
    <!-- {{this.getTableData}}
    {{this.getChartData}}-->

    <!-- {{ this.$store.state.home.selectedPeriodData}} -->
    <!-- {{ this.$store.state.home.footfallData}} -->
    <!-- {{this.getPowerHourData}} -->
  </v-container>
</template>



<script>
import moment from "moment";
import _ from "lodash";
const expo = require("../plugins/export-2");
var priorPeriodLegend = false;
var priorYearLegend = false;

export default {
  name: "home",
  data() {
    return {
      timer1: "",
      timer2: "",
      timer3: "",
      overlay: true,
      computedCall: 3,
      passedCall: 1,
      showAlert: false,
      alertMessage: "",
      trafficData: 0,
      chartType: {
        name: "bar",
        icon: "mdi-chart-bar",
      },
      // CHART KPI'S
      kpiDefault: {
        text: "Traffic In",
        value: 1,
        kpiGroupId: "5a41fd655bca800604b146cc",
        kpiId: "5a41fd655bca80kpi14b147cc",
        element: "Adult",
        icon: "mdi-transit-transfer",
      },
      kpis: [
        {
          text: "Traffic In",
          value: 1,
          kpiGroupId: "5a41fd655bca800604b146cc",
          kpiId: "5a41fd655bca80kpi14b147cc",
          element: "Adult",
          icon: "mdi-transit-transfer",
        },
        {
          text: "Traffic Out",
          value: 2,
          kpiGroupId: "5a41fd655bca800604b140cc",
          kpiId: "5a41fd655bca80kpi24b147cc",
          element: "Adult",
          icon: "mdi-transit-transfer",
        },
        // {
        //   text: "Child In",
        //   value: 3,
        //   kpiGroupId: "5a41fd655bca800604b146cc",
        //   kpiId: "5a41fd655bca80kpi14b147cc",
        //   element: "Child",
        //   icon: "mdi-transit-transfer",
        // },
        // {
        //   text: "Child Out",
        //   value: 4,
        //   kpiGroupId: "5a41fd655bca800604b140cc",
        //   kpiId: "5a41fd655bca80kpi24b147cc",
        //   element: "Child",
        //   icon: "mdi-transit-transfer",
        // },
      ],

      // CHART DATA
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          zoomType: "x",
          width: 830,
          type: "line",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        plotOptions: {
          line: {
            events: {
              legendItemClick: function () {
                if (this.userOptions.index == 2) {
                  priorPeriodLegend = !priorPeriodLegend;
                } else if (this.userOptions.index == 3) {
                  priorYearLegend = !priorYearLegend;
                }
              },
            },
          },
          series: {
            dataLabels: {
              enabled: false,
              rotation : 270,
              style : {
                fontSize : 8,
              },
              allowOverlap : true
            },
          },
        },
        series: [
          {
            name: "",
            data: [],
            color: "green",
          },
          {
            name: "",
            data: [],
            color: "red",
          },
          {
            name: "",
            data: [],
            color: "blue",
          },
          // {
          //   name: "",
          //   data: [],
          //   color: "purple",
          // },
        ],
      },
      // KPI DATA PARAMETERS
      params: {
        kpiType: "numeric",
        fetchDataFor: "line",
        dataFilter: [
          {
            kpiGroupId: "",
            kpiId: "",
            elementId: "",
            label: "HOURLY",
            color: "#7F186E",
            showOnAxis: "y1",
            mapped: [
              {
                mappedFullSite: 0,
                siteId: "full",
              },
            ],
          },
        ],
        from: "",
        to: "",
        timeFrame: "",
        consolidateSiteData: 1,
        consolidateData: 1,
        localTimezone: "+00:00",
        showDataByOperationalHours: true,
      },
    };
  },
  // METHODS
  methods: {
    //TOGGLE CHART (LINE OR COLUMN)
    submitType() {
      if (this.chartType.name == "bar") {
        this.chartType.name = "line";
        this.chartType.icon = "mdi-chart-bell-curve-cumulative";
        this.chartOptions.chart.type = "column";
        this.chartOptions.plotOptions.series.dataLabels.enabled = true;
      } else {
        this.chartType.name = "bar";
        this.chartType.icon = "mdi-chart-bar";
        this.chartOptions.chart.type = "line";
        this.chartOptions.plotOptions.series.dataLabels.enabled = false;
      }
    },
    submitTypedrp() {
      this.$store.dispatch("cancelRequest");

      this.$store.state.home.selectedPeriodData = [];
      this.$store.state.home.comparePeriodData = [];
      this.$store.state.home.priorPeriodData = [];
      this.$store.state.home.priorYearData = [];
      this.$store.state.home.footfallData = [];
      this.$store.state.home.adultInData = [];
      this.$store.state.home.adultOutData = [];
      this.$store.state.home.childOutData = [];
      this.PageReady();
    },

    // EXPORT CONTENT AS PDF USING THE REFERENCE ID
    pdfClick() {
      var that = this;
      if (
        document.getElementsByClassName("v-navigation-drawer--mini-variant")
          .length != 1
      )
        document.querySelector(".v-navigation-drawer button").click();

      setTimeout(function () {
        that.showAlert = true;
        //localStorage.setItem("mini", true);

        if (that.isData) {
          var filename = that.getFileName + ".pdf";
          var boxes = that.$refs.boxes;
          var content = that.$refs.content;
          //var content1 = this.$refs.custable;
          var content2 = that.$refs.custable2;
          var text = that.pdfFilterData();

          var response = expo.createHomePDF(
            filename,
            text,
            boxes,
            content,
            content2
          );

          that.alertMessage = response;
        } else {
          that.alertMessage = "No data to export";
        }
      }, 500);
    },

    pdfFilterData() {
      var reportName = this.getFileName;
      var generationDate = new Date();
      var siteNames = _.map(this.$store.getters.selectedSites, "siteName");
      var zoneNames = _.map(this.$store.getters.selectedZones, "zoneName");
      var operationalHour = this.$store.state.filter.operationalHour;
      var consolidation = this.getChartName;

      var selectedPeriodDate =
        moment(this.$store.getters.selectedPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.selectedPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var selectedComparisonPeriodDate =
        moment(this.$store.getters.comparePeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.comparePeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var priorPeriodDate =
        moment(this.$store.getters.priorPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var priorYearDate =
        moment(this.$store.getters.priorYearDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorYearDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("YYYY-MM-DD");

      var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Current Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Comparison Period Date</td> <td> ${selectedComparisonPeriodDate}</td> </tr> <tr> <td>Perior Period Date</td> <td> ${priorPeriodDate}</td> </tr> <tr> <td>Prior Year Date</td> <td> ${priorYearDate}</td> </tr> </tbody> </table>`;

      return table;
    },

    // EXPORTING VALUES TO CSV FORMAT
    csvClick() {
      this.showAlert = true;
      if (this.isData) {
        var filename = this.getFileName + ".csv";
        var data = this.csvData();
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },

    // RECREATING DATA FOR CSV EXPORT
    csvData() {
      var data = this.getChartData;
      var that = this;
      var csvData = [];
      var csvObj = {};
      var dates =
        data["selectedPeriodDate"].length >= data["priorPeriodDate"].length
          ? data["selectedPeriodDate"]
          : data["priorPeriodDate"];

      var array = _.range(0, dates.length);
      _.each(array, function (value) {
        if (that.getChartName.toLowerCase() == "hour")
          csvObj["selectedPeriodTime"] = that.setHourFormat(
            data.selectedPeriodDate[value].toLocaleString()
          );
        else {
          csvObj["currentPeriodTime"] = data.selectedPeriodDate[value] || 0;
        }
        csvObj["currentPeriodData"] = data.selectedPeriod[value] || 0;
        csvObj["priorWeekTime"] = data.priorPeriodDate[value] || 0;
        csvObj["priorWeekData"] = data.priorPeriod[value] || 0;
        // csvObj["priorYearTime"] = data.priorYearDate[value] || 0;
        // csvObj["priorYearData"] = data.priorYear[value] || 0;
        csvData.push({ ...csvObj });
      });
      return csvData;
    },

    csvClickPowerHour() {
      this.showAlert = true;
      if (this.isData) {
        var filename = this.getFileName + ".csv";
        var data = this.getPowerHourData;
        var filterdData = [];
        data.forEach((singleData) => {
          delete singleData.hour;
          filterdData.push(
            JSON.parse(
              JSON.stringify(singleData).split('"hourF":').join('"hour":')
            )
          );
        });
        var response = expo.createCSV(filename, filterdData);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },

    // STORE CHART DATA INTO VUEX STORE
    setChartData() {
      var params = this.getParameters;
      //let params3 = this.getParameters3;
      var params2 = this.getParameters2;
      //console.log(this.getParameters);

      // if (this.passedCall >= this.computedCall) this.overlay = false;
      // this.passedCall += 1;

      params.forEach((param) => {
        this.$store.dispatch("getKpiData", param);
      });
      params2.forEach((param) => {
        this.$store.dispatch("getKpiData", param);
      });
      // this.setChart();
      // params3.forEach(param => {
      //   this.$store.dispatch("getKpiData", param);
      // });
    },

    // ASSIGNING DATA TO CHART
    setChart() {
      var data = this.getChartData;
      // data.selectedPeriodDate = data.selectedPeriodDate.sort((a,b)=>{
      //     return a-b
      // })
      this.chartOptions.yAxis.title.text = this.getKPI.text + " Value";
      let totalDateRange = this.getTotal;

      this.chartOptions.xAxis.categories = data["period"];

      //let date = moment(totalDateRange.selectedPeriod.datetime.from).format('L');
      this.chartOptions.series[0].name =
        "Current Period (" +
        totalDateRange.selectedPeriod.datetime.from +
        " to " +
        totalDateRange.selectedPeriod.datetime.to +
        ")";

      this.chartOptions.series[1].name =
        "Compare Period (" +
        totalDateRange.comparisionPeriod.datetime.from +
        " to " +
        totalDateRange.comparisionPeriod.datetime.to +
        ")";

      this.chartOptions.series[2].name =
        "Prior Week (" +
        totalDateRange.priorPeriod.datetime.from +
        " to " +
        totalDateRange.priorPeriod.datetime.to +
        ")";

      // this.chartOptions.series[3].name =
      //   "Prior Year (" +
      //   totalDateRange.priorYear.datetime.from +
      //   " to " +
      //   totalDateRange.priorYear.datetime.to +
      //   ")";

      this.chartOptions.series[2].visible = priorPeriodLegend;
      //this.chartOptions.series[3].visible = priorYearLegend;

      this.chartOptions.series[0].data = data["selectedPeriod"];
      this.chartOptions.series[1].data = data["comparePeriod"];
      this.chartOptions.series[2].data = data["priorPeriod"];
      //this.chartOptions.series[3].data = data["priorYear"];
      this.chartOptions.title.text = this.getChartName + " Consolidated Data";
      this.overlay = false;
      this.chartOptions["tooltip"] = {
        formatter: function () {
          var xVal = this.x;
          if (this.series.name.indexOf("Compare Period") > -1) {
            xVal = data["comparePeriodDate"][this.point.x];
          } else if (this.series.name.indexOf("Prior Week") > -1) {
            xVal = data["priorPeriodDate"][this.point.x];
          }
          return (
            xVal +
            "<br/><span style='color:" +
            this.color +
            "'>\u25CF</span> " +
            this.series.name +
            ": <b>" +
            this.y +
            "</b><br/>"
          );
        },
      };
    },

    setHourFormat(hour) {
      if (hour.length < 2) return "0" + hour + ":00";
      else if (hour.length > 1) return hour + ":00";
    },

    // GET ARROW CLASS
    getClass(rows) {
      if (Math.sign(rows) == -1) return "arrow-down";
      else return "arrow-up";
    },

    // GET ARROW CLASS
    getCellColor(value) {
      if (value) {
        if (value >= 200 && value <= 599) return "background-color:#add8e6";
        else if (value >= 600) return "background-color:#436975";
        else return "background-color:white";
      } else return "background-color:white";
    },

    // FORMATING THE NUMBER WITH COMMA
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    // GET FULL MONTH NAME
    getMonthName(monthInt) {
      var month = new Array();
      month[1] = "January";
      month[2] = "February";
      month[3] = "March";
      month[4] = "April";
      month[5] = "May";
      month[6] = "June";
      month[7] = "July";
      month[8] = "August";
      month[9] = "September";
      month[10] = "October";
      month[11] = "November";
      month[12] = "December";
      return month[monthInt];
    },
    PageReady(loader = true) {
      if (loader) this.overlay = true;
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);
      
      this.timer2 = setInterval(() => {
        if (this.$store.state.filter.selectedZones.length > 0) {
          clearInterval(this.timer2);
          this.setChartData();
          this.timer2 = setInterval(() => {
            var data = this.getChartData;
            if (
              data["selectedPeriodDate"].length > 0 &&
              data["comparePeriodDate"].length > 0 &&
              data["priorPeriodDate"].length > 0
            ) {
              clearInterval(this.timer2);
              this.setChart();
              this.timer3 = setInterval(() => {
                this.PageReady(false);
              }, 20000);
            }
          }, 100);
        }
      }, 100);
    },
    getSelectedZonesBySiteId(siteId) {
      var allZones = this.$store.state.filter.zones;
      var selectedZones = this.$store.state.filter.selectedZones;
      var siteZones = _.map(_.filter(allZones, { siteId: siteId }), "_id");
      return siteZones.filter((x) => selectedZones.includes(x));
    },
  },
  computed: {
    getKPI() {
      var value =
        typeof this.kpiDefault.value == "undefined"
          ? this.kpiDefault
          : this.kpiDefault.value;

      return _.find(this.kpis, { value: value });
    },
    getSiteObjects() {
      var dataFilter = [];
      this.$store.state.filter.selectedSites.forEach((siteId) => {
        var AdultsiteObject = {},
          ChildsiteObject = {};
        (AdultsiteObject["kpiGroupId"] = this.getKPI.kpiGroupId),
          (AdultsiteObject["kpiId"] = this.getKPI.kpiId),
          (AdultsiteObject["elementId"] = "Adult"),
          (AdultsiteObject["label"] = "HOURLY"),
          (AdultsiteObject["color"] = "#7F186E"),
          (AdultsiteObject["showOnAxis"] = "y1"),
          (AdultsiteObject["mapped"] = [
            {
              mappedFullSite: 0,
              siteId: siteId,
              zones: this.getSelectedZonesBySiteId(siteId),
            },
          ]);
        (ChildsiteObject["kpiGroupId"] = this.getKPI.kpiGroupId),
          (ChildsiteObject["kpiId"] = this.getKPI.kpiId),
          (ChildsiteObject["elementId"] = "Child"),
          (ChildsiteObject["label"] = "HOURLY"),
          (ChildsiteObject["color"] = "#7F186E"),
          (ChildsiteObject["showOnAxis"] = "y1"),
          (ChildsiteObject["mapped"] = [
            {
              mappedFullSite: 0,
              siteId: siteId,
              zones: this.getSelectedZonesBySiteId(siteId),
            },
          ]);
        dataFilter.push(AdultsiteObject);
        dataFilter.push(ChildsiteObject);
      });
      return _.reverse(dataFilter);
    },
    // GET PARAMETERS
    getParameters() {
      var payloads = [];
      var selectedPeriodPayload = {};
      var comparisionPeriodPayload = {};
      var priorPeriodPayload = {};
      var priorYearPayload = {};
      var powerHourPayload = {};
      var params = JSON.parse(JSON.stringify(this.params));

      // params.dataFilter[0].kpiGroupId = this.getKPI.kpiGroupId;
      // params.dataFilter[0].kpiId = this.getKPI.kpiId;
      // params.dataFilter[0].elementId = this.getKPI.element;
      params.dataFilter = this.getSiteObjects;
      // params.dataFilter[0].mapped[0].zones = this.$store.state.filter.selectedZones;
      params.timeFrame = this.$store.state.filter.consolidation;
      params.showDataByOperationalHours =
        this.$store.state.filter.operationalHour;

      //SELECTED PERIOD
      var params1 = JSON.parse(JSON.stringify(params));
      params1.from = this.$store.getters.selectedPeriodDate[0];
      params1.to = this.$store.getters.selectedPeriodDate[1];
      //console.log("before",params1)
      params1.from = moment(params1.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      //params1.to =moment(params1.to).add(2,'hours').format("YYYY-MM-DD HH:mm:ss");
      //console.log(params1)
      selectedPeriodPayload["params"] = params1;
      selectedPeriodPayload["mutationId"] = "selectedPeriodData";
      payloads.push(selectedPeriodPayload);

      //COMPARISION PERIOD
      var params5 = JSON.parse(JSON.stringify(params));
      params5.from = this.$store.getters.comparePeriodDate[0];
      params5.from = moment(params5.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      params5.to = this.$store.getters.comparePeriodDate[1];
      comparisionPeriodPayload["params"] = params5;
      comparisionPeriodPayload["mutationId"] = "comparePeriodData";
      payloads.push(comparisionPeriodPayload);

      //PRIOR PERIOD
      var params2 = JSON.parse(JSON.stringify(params));
      params2.from = this.$store.getters.priorPeriodDate[0];
      params2.from = moment(params2.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      params2.to = this.$store.getters.priorPeriodDate[1];
      priorPeriodPayload["params"] = params2;
      priorPeriodPayload["mutationId"] = "priorPeriodData";
      payloads.push(priorPeriodPayload);

      //PRIOR YEAR
      var params3 = JSON.parse(JSON.stringify(params));
      params3.from = this.$store.getters.priorYearDate[0];
      params3.to = this.$store.getters.priorYearDate[1];
      priorYearPayload["params"] = params3;
      priorYearPayload["mutationId"] = "priorYearData";
      payloads.push(priorYearPayload);

      //POWER HOUR
      var params4 = JSON.parse(JSON.stringify(params));
      params4.from = this.$store.getters.selectedPeriodDate[0];
      params4.to = this.$store.getters.selectedPeriodDate[1];
      params4.timeFrame = "hour";
      params4.consolidateData = 0;
      powerHourPayload["params"] = params4;
      powerHourPayload["mutationId"] = "footfallData";
      payloads.push(powerHourPayload);

      return payloads;
    },
    getParameters3() {
      var payloads = [];

      var selectedPeriodPayload = {};
      var params = JSON.parse(JSON.stringify(this.params));

      params.dataFilter[0].kpiGroupId = this.getKPI.kpiGroupId;
      params.dataFilter[0].kpiId = this.getKPI.kpiId;
      params.dataFilter[0].elementId = this.getKPI.element;
      params.dataFilter[0].mapped[0].zones =
        this.$store.state.filter.selectedZones;
      params.timeFrame = this.$store.state.filter.consolidation;
      params.showDataByOperationalHours =
        this.$store.state.filter.operationalHour;

      //SELECTED PERIOD
      var params1 = JSON.parse(JSON.stringify(params));
      params1.from = this.$store.getters.comparePeriodDate[0];
      params1.from = moment(params1.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      params1.to = this.$store.getters.comparePeriodDate[1];
      selectedPeriodPayload["params"] = params1;
      selectedPeriodPayload["mutationId"] = "selectedPeriodData";
      payloads.push(selectedPeriodPayload);

      return payloads;
    },

    getParameters2() {
      var payloads = [];
      var adultInPayload = {};
      var adultOutPayload = {};
      var childInPayload = {};
      var childOutPayload = {};
      var params = JSON.parse(JSON.stringify(this.params));

      params.dataFilter[0].mapped[0].zones =
        this.$store.state.filter.selectedZones;
      params.from = this.$store.getters.selectedPeriodDate[0];
      params.from = moment(params.from)        
        .format("YYYY-MM-DD HH:mm:ss");
      params.to = this.$store.getters.selectedPeriodDate[1];
      params.timeFrame = this.$store.state.filter.consolidation;
      params.showDataByOperationalHours =
        this.$store.state.filter.operationalHour;

      //ADULT IN
      var params1 = JSON.parse(JSON.stringify(params));
      params1.dataFilter[0].kpiGroupId = "5a41fd655bca800604b146cc";
      params1.dataFilter[0].kpiId = "5a41fd655bca80kpi14b147cc";
      params1.dataFilter[0].elementId = "Adult";
      adultInPayload["params"] = params1;
      adultInPayload["mutationId"] = "adultInData";
      payloads.push(adultInPayload);

      //ADULT OUT
      var params2 = JSON.parse(JSON.stringify(params));
      params2.dataFilter[0].kpiGroupId = "5a41fd655bca800604b140cc";
      params2.dataFilter[0].kpiId = "5a41fd655bca80kpi24b147cc";
      params2.dataFilter[0].elementId = "Adult";
      adultOutPayload["params"] = params2;
      adultOutPayload["mutationId"] = "adultOutData";
      payloads.push(adultOutPayload);

      //CHILD IN
      var params3 = JSON.parse(JSON.stringify(params));
      params3.dataFilter[0].kpiGroupId = "5a41fd655bca800604b146cc";
      params3.dataFilter[0].kpiId = "5a41fd655bca80kpi14b147cc";
      params3.dataFilter[0].elementId = "Child";
      childInPayload["params"] = params3;
      childInPayload["mutationId"] = "childInData";
      payloads.push(childInPayload);

      //CHILD OUT
      var params4 = JSON.parse(JSON.stringify(params));
      params4.dataFilter[0].kpiGroupId = "5a41fd655bca800604b140cc";
      params4.dataFilter[0].kpiId = "5a41fd655bca80kpi24b147cc";
      params4.dataFilter[0].elementId = "Child";
      childOutPayload["params"] = params4;
      childOutPayload["mutationId"] = "childOutData";
      payloads.push(childOutPayload);
      return payloads;
    },

    getBoxData() {
      var data = [];
      var adultInData = {};
      var adultOutData = {};
      var childInData = {};
      var childOutData = {};
  
      this.$store.state.home.adultInData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value += kpi.value;
          });
        }
        adultInData["label"] = "ADULT IN";
        adultInData["color"] = "green";
        adultInData["enabled"] = true;
        adultInData["value"] = value;
      });

      this.$store.state.home.adultOutData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value += kpi.value;
          });
        }

        adultOutData["label"] = "ADULT OUT";
        adultOutData["color"] = "red";
        adultOutData["enabled"] = true;
        adultOutData["value"] = value;
      });
      this.$store.state.home.childInData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value += kpi.value;
          });
        }

        childInData["label"] = "CHILD IN";
        childInData["color"] = "green";
        childInData["enabled"] = true;
        childInData["value"] = value;
      });
      this.$store.state.home.childOutData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value += kpi.value;
          });
        }
        childOutData["label"] = "CHILD OUT";
        childOutData["color"] = "red";
        childOutData["enabled"] = true;
        childOutData["value"] = value;
      });
      data.push(adultInData);
      data.push(adultOutData);
      data.push(childInData);
      data.push(childOutData);

      return data;
    },
    getPowerHourData() {
      var data = [];
      var data3 = [];
      this.$store.state.home.footfallData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var dataObj = {};
            var date = moment(kpi.date, "DD-MM-YYYY hh:mm");
            dataObj.hour = date.format("H");
            if (dataObj.hour > 0) {
              dataObj.hourF = date.format("HH:mm");
            } else {
              dataObj.hourF = "00:00";
            }
            dataObj.day = date.format("dddd");
            dataObj.value = kpi.value;
            data.push(dataObj);
          });
        }
      });

      data = _.groupBy(data, "hour");
      var weekDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      for (var i = 0; i < 24; i++) {
        var total = 0;
        var daysGrouped = _.groupBy(data[i], "day");
        var testObj = {};
        for (var j = 0; j < weekDays.length; j++) {
          var days = daysGrouped[weekDays[j]];
          if (typeof days != "undefined") {
            testObj.hour = i;
            testObj.hourF = days[0].hourF;

            var value = 0;
            days.forEach((day) => {
              value += day.value;
            });
            total += value;
            testObj[weekDays[j]] = value;
          }
          testObj.total = total;
        }
        data3.push(testObj);
      }

      return data3;
    },
    // GET STORE DATA FROM VUEX STORE
    getChartData() {
      var data = {};
      var selectedPeriodData = [];
      var comparePeriodData = [];
      var priorPeriodData = [];
      var priorYearData = [];
      var selectedPeriodDate = [];
      var comparePeriodDate = [];
      var priorPeriodDate = [];
      var priorYearDate = [];
      //var that = this;
      
      this.$store.state.home.selectedPeriodData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var date =
              this.getConsolidation == "month"
                ? this.getMonthName(kpi.date)
                : kpi.date;

            if (selectedPeriodDate.includes(date)) {
              var indexIfExist = selectedPeriodDate.indexOf(date);
              selectedPeriodData[indexIfExist] += kpi.value;
            }
            else if(typeof date == 'number'){
              selectedPeriodDate[date] = date;
              selectedPeriodData[date] = kpi.value;
            } 
            else {
              selectedPeriodDate.push(date);
              selectedPeriodData.push(kpi.value);
            }
          });
        }
      });
      
      this.$store.state.home.comparePeriodData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            // console.log(kpi)
            var date =
              this.getConsolidation == "month"
                ? this.getMonthName(kpi.date)
                : kpi.date;

            if (comparePeriodDate.includes(date)) {
              var indexIfExist = comparePeriodDate.indexOf(date);
              comparePeriodData[indexIfExist] += kpi.value;
            }
            else if(typeof date == 'number'){
              comparePeriodDate[date] = date;
              comparePeriodData[date] = kpi.value;
            } 
            else {
              comparePeriodDate.push(date);
              comparePeriodData.push(kpi.value);
            }
          });
        }
      });

      this.$store.state.home.priorPeriodData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var date =
              this.getConsolidation == "month"
                ? this.getMonthName(kpi.date)
                : kpi.date;
            if (priorPeriodDate.includes(date)) {
              var indexIfExist = priorPeriodDate.indexOf(date);
              priorPeriodData[indexIfExist] += kpi.value;
            }
            else if(typeof date == 'number'){
              priorPeriodDate[date] = date;
              priorPeriodData[date] = kpi.value;
            } 
            else {
              priorPeriodDate.push(date);
              priorPeriodData.push(kpi.value);
            }
          });
        }
      });

      this.$store.state.home.priorYearData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var date =
              this.getConsolidation == "month"
                ? this.getMonthName(kpi.date)
                : kpi.date;

            if (priorYearDate.includes(date)) {
              var indexIfExist = priorYearDate.indexOf(date);
              priorYearDate[indexIfExist] += kpi.value;
            } 
            else if(typeof date == 'number'){
              priorYearDate[date] = date;
              priorYearData[date] = kpi.value;
            }
            else {
              priorYearDate.push(date);
              priorYearData.push(kpi.value);
            }
          });
        }
      });

      var period =
        selectedPeriodDate.length >=
        (priorPeriodDate.length &&
          priorYearDate.length &&
          comparePeriodDate.length)
          ? selectedPeriodDate
          : comparePeriodDate.length >= priorYearDate.length &&
            priorPeriodDate.length
          ? comparePeriodDate
          : priorPeriodDate.length >= priorYearDate.length
          ? priorPeriodDate
          : priorYearDate;

      //debugger;
      // if (comparePeriodDate.length > 1) {
      //   period.push(comparePeriodDate[0]);
      //   period.push(comparePeriodDate[1]);
      //   // period.push(20-02-2020);
      //   // period.push(comparePeriodDate[1]);
      // }
      //period = comparePeriodDate;
      //       var c=period.concat(comparePeriodDate);
      //       // c.concat(comparePeriodDate);
      // // period=period.push(comparePeriodDate);
      // // period.concat(comparePeriodDate);
      // console.log(c)
      // var arrAll = this.concatArraysUniqueWithSort(
      //   selectedPeriodDate,
      //   comparePeriodDate
      // );
      //console.log(arrAll);
      // period = selectedPeriodDate.concat(comparePeriodDate);

      // //  var dates=new moment(period);
      // period.sort(function(left, right) {
      //   return moment(left).format("X") - moment(right).format("X");
      //   // return moment(left).diff(moment(right))
      // });
      // const comparisonValues = period.map(v => v.valueOf());
      // period = period.filter(
      //   (v, i) => comparisonValues.indexOf(v.valueOf()) == i
      // );

      // console.log(period);
      // console.log(period.length);

      // var selectedPeriodArray = new Array(period.length);
      // var comparePeriodArray = new Array(period.length);
      //console.log(selectedPeriodDate.indexOf("12346"));

      // for (let x = 0; x < period.length; x++) {
      //   // debugger;
      //   let index = selectedPeriodDate.indexOf(period[x]);
      //   if (index > -1) {
      //     selectedPeriodArray[x] = selectedPeriodData[index];
      //   } else selectedPeriodArray[x] = "-";

      //   let index2 = comparePeriodDate.indexOf(period[x]);
      //   if (index2 > -1) {
      //     comparePeriodArray[x] = comparePeriodData[index2];
      //   } else comparePeriodArray[x] = "-";
      //   // if (selectedPeriodDate.includes(period[x])) {
      //   //   console.log(selectedPeriodDate.indexOf(period[x]));
      //   // }
      //   //console.log(selectedPeriodDate.includes(period[x]));
      // }
      // console.log(period);

      data["period"] = period;
      data["selectedPeriodDate"] = selectedPeriodDate;
      data["comparePeriodDate"] = comparePeriodDate;
      data["priorPeriodDate"] = priorPeriodDate;
      data["priorYearDate"] = priorYearDate;
      data["selectedPeriod"] = selectedPeriodData;
      data["comparePeriod"] = comparePeriodData;
      data["priorPeriod"] = priorPeriodData;
      data["priorYear"] = priorYearData;

      //   data["sp"] = {};
      //   data["sp"]["date"] = selectedPeriodDate;
      //   data["sp"]["value"] = selectedPeriodData;

      //   data["pp"] = {};
      //   data["pp"]["date"] = priorPeriodDate;
      //   data["pp"]["value"] = priorPeriodData;

      //   data["py"] = {};
      //   data["py"]["date"] = priorYearDate;
      //   data["py"]["value"] = priorYearData;
      //eslint-disable-next-line
      // console.log('a');
      // this.overlay=false;
      return data;
    },
    concatArraysUniqueWithSort(thisArray, otherArray) {
      var newArray = thisArray.concat(otherArray).sort(function (a, b) {
        return new Date(a) > new Date(b)
          ? 1
          : new Date(a) < new Date(b)
          ? -1
          : 0;
      });

      return newArray.filter(function (item, index) {
        return newArray.indexOf(item) === index;
      });
    },
    // GET TABLE DATA FROM getChartData FUNCTION -- TESTING
    getTableData() {
      var tableData = [];
      var chartData = this.getChartData;
      //var selectedPeriodSum = _.sum(chartData.selectedPeriod);

      var selectedPeriodSum = chartData.selectedPeriod.reduce(
        (a, b) => parseInt(isNaN(a) ? 0 : a) + parseInt(isNaN(b) ? 0 : b),
        0
      );

      // var priorYearSum = _.sum(chartData.priorYear);
      // var comparePeriodSum = _.sum(chartData.comparePeriod);
      var comparePeriodSum = chartData.comparePeriod.reduce(
        (a, b) => parseInt(isNaN(a) ? 0 : a) + parseInt(isNaN(b) ? 0 : b),
        0
      );

      var periodCount =
        chartData.selectedPeriodDate.length >= chartData.priorYearDate.length
          ? chartData.selectedPeriodDate.length
          : chartData.priorYearDate.length;
      //   var periodCount = chartData.period.length;
      //debugger;
      for (var i = 0; i < periodCount; i++) {
        var dataObj = {};

        var selectedPeriodCount = chartData.selectedPeriod[i] || 0;
        var selectedPeriodAvg = Math.round(
          (selectedPeriodCount / selectedPeriodSum) * 100
        );
        //eslint-disable-next-line
        // console.log(selectedPeriodCount + "----" + selectedPeriodSum);

        // var priorYearCount = chartData.priorYear[i];
        // var priorYearAvg =
        //   Math.round((priorYearCount / priorYearSum) * 100) || 0;

        var comparePeriodCount = chartData.comparePeriod[i];
        var comparePeriodAvg =
          Math.round((comparePeriodCount / comparePeriodSum) * 100) || 0;

        var timeFrame = chartData.selectedPeriodDate[i] || 0;
        var compareTimeFrame = chartData.comparePeriodDate[i] || 0;

        if (this.getConsolidation == "dayOfMonth") {
          timeFrame = moment(chartData.period[i], "DD-MM-YYYY").format(
            "MMM-DD"
          );
          compareTimeFrame = moment(
            chartData.comparePeriodDate[i],
            "DD-MM-YYYY"
          ).format("MMM-DD");
        }

        var variance =
          ((selectedPeriodCount - comparePeriodCount) / comparePeriodCount) *
            100 || 0;

        // if (variance != 0) debugger;

        dataObj["timeFrame"] = timeFrame;
        dataObj["compareTimeFrame"] = compareTimeFrame;
        dataObj["selectedYear"] = moment(
          this.$store.getters.selectedPeriodDate[0],
          "YYYY-MM-DD hh:mm:ss"
        )          
          .format("YYYY");
        //debugger;
        dataObj["compareYear"] = moment(
          this.$store.getters.comparePeriodDate[0],
          "YYYY-MM-DD hh:mm:ss"
        )          
          .format("YYYY");
        dataObj["selectedPeriodCount"] = selectedPeriodCount || 0;
        dataObj["selectedPeriodAvg"] = selectedPeriodAvg;
        // dataObj["priorYearCount"] = priorYearCount || 0;
        // dataObj["priorYearAvg"] = priorYearAvg;
        dataObj["comparePeriodCount"] = comparePeriodCount || 0;
        dataObj["comparePeriodAvg"] = comparePeriodAvg;
        dataObj["variance"] = variance.toFixed(2);

        if (this.getChartName.toLowerCase() == "hour") {
          dataObj.timeFrame = this.setHourFormat(
            dataObj.timeFrame.toLocaleString()
          );
           dataObj.compareTimeFrame = this.setHourFormat(
            dataObj.compareTimeFrame.toLocaleString()
          );
        }
        // if (dataObj.timeFrame.toLocaleString().length <script 2)
        //   dataObj.timeFrame = "0" + dataObj.timeFrame + ":00";
        // else if (dataObj.timeFrame.toLocaleString().length > 1)
        //   dataObj.timeFrame = dataObj.timeFrame + ":00";        
        tableData.push(dataObj);
      }

      return tableData;
    },
    // GET BOX DATA
    getTotal() {
      var data = this.getChartData;
      //debugger;
      // var dateFrom = this.$store.state.filter.dates[0];
      // var dateTo = this.$store.state.filter.dates[1];
      var sumObj = {};

      var selectedPeriodTotal = data["selectedPeriod"].reduce(
        (a, b) => parseInt(isNaN(a) ? 0 : a) + parseInt(isNaN(b) ? 0 : b),
        0
      );

      var comparePeriodTotal = data["comparePeriod"].reduce(
        (a, b) => parseInt(isNaN(a) ? 0 : a) + parseInt(isNaN(b) ? 0 : b),
        0
      );
      var priorPeriodTotal = data["priorPeriod"].reduce(
        (a, b) => parseInt(isNaN(a) ? 0 : a) + parseInt(isNaN(b) ? 0 : b),
        0
      );
      var priorYearTotal = data["priorYear"].reduce(
        (a, b) => parseInt(isNaN(a) ? 0 : a) + parseInt(isNaN(b) ? 0 : b),
        0
      );

      //SELECTED PERIOD
      sumObj["selectedPeriod"] = {};
      sumObj["selectedPeriod"]["datetime"] = {};
      // sumObj["selectedPeriod"]["datetime"]["from"] = dateFrom;
      // sumObj["selectedPeriod"]["datetime"]["to"] = dateTo;
      sumObj["selectedPeriod"]["datetime"]["from"] = moment(
        this.$store.state.filter.dates[0]
      ).format("DD/MM/YY");

      sumObj["selectedPeriod"]["datetime"]["to"] = moment(
        this.$store.state.filter.dates[1]
      ).format("DD/MM/YY");
      //this.$store.getters.selectedPeriodDate[0];
      // sumObj["selectedPeriod"]["total"] =
      //   Math.round(selectedPeriodTotal * 100) / 100;

      this.$store.state.home.childInData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value =
              parseInt(isNaN(value) ? 0 : value) +
              parseInt(isNaN(kpi.value) ? 0 : kpi.value);
          });
        }

        //childInData = value;
        selectedPeriodTotal =
          parseInt(isNaN(selectedPeriodTotal) ? 0 : selectedPeriodTotal) +
          parseInt(isNaN(value) ? 0 : value);
      });

      sumObj["selectedPeriod"]["total"] =
        Math.round(selectedPeriodTotal * 100) / 100;

      //COMPARISION PERIOD
      sumObj["comparisionPeriod"] = {};
      sumObj["comparisionPeriod"]["datetime"] = {};
      // sumObj["comparisionPeriod"]["datetime"]["from"] = this.$store.state.filter.dates2[0];
      // sumObj["comparisionPeriod"]["datetime"]["to"] = this.$store.state.filter.dates2[1];

      sumObj["comparisionPeriod"]["datetime"]["from"] = moment(
        this.$store.state.filter.dates2[0]
      ).format("DD/MM/YY");
      sumObj["comparisionPeriod"]["datetime"]["to"] = moment(
        this.$store.state.filter.dates2[1]
      ).format("DD/MM/YY");
      sumObj["comparisionPeriod"]["total"] =
        Math.round(comparePeriodTotal * 100) / 100;

      //PRIOR PERIOD
      sumObj["priorPeriod"] = {};
      sumObj["priorPeriod"]["datetime"] = {};
      // sumObj["priorPeriod"]["datetime"]["from"] = moment(dateFrom)
      //   .subtract(7, "days")
      //   .format("YYYY-MM-DD");
      // sumObj["priorPeriod"]["datetime"]["to"] = moment(dateTo)
      //   .subtract(7, "days")
      //   .format("YYYY-MM-DD");
      sumObj["priorPeriod"]["datetime"]["from"] = moment(
        this.$store.getters.priorPeriodDate[0]
      ).format("DD/MM/YY");
      sumObj["priorPeriod"]["datetime"]["to"] = moment(
        this.$store.getters.priorPeriodDate[1]
      ).format("DD/MM/YY");

      sumObj["priorPeriod"]["total"] = priorPeriodTotal;

      // sumObj["priorPeriod"]["change"] = isFinite(
      //   (selectedPeriodTotal - priorPeriodTotal) / priorPeriodTotal
      // )
      //   ? Math.round(
      //       ((selectedPeriodTotal - priorPeriodTotal) / priorPeriodTotal) * 100
      //     )
      //   : 0;

      //PRIOR YEAR
      sumObj["priorYear"] = {};
      sumObj["priorYear"]["datetime"] = {};
      // sumObj["priorYear"]["datetime"]["from"] = moment(dateFrom)
      //   .subtract(1, "years")
      //   .format("YYYY-MM-DD");
      // sumObj["priorYear"]["datetime"]["to"] = moment(dateTo)
      //   .subtract(1, "years")
      //   .format("YYYY-MM-DD");
      sumObj["priorYear"]["datetime"]["from"] = moment(
        this.$store.getters.priorYearDate[0]
      ).format("DD/MM/YY");
      sumObj["priorYear"]["datetime"]["to"] = moment(
        this.$store.getters.priorYearDate[1]
      ).format("DD/MM/YY");
      sumObj["priorYear"]["total"] = priorYearTotal;

      // sumObj["priorYear"]["change"] = isFinite(
      //   (selectedPeriodTotal - priorYearTotal) / priorYearTotal
      // )
      //   ? Math.round(
      //       ((selectedPeriodTotal - priorYearTotal) / priorYearTotal) * 100
      //     )
      //   : 0;

      if (
        this.getKPI.value == 1 &&
        Object.keys(this.getBoxData[0]).length > 0 &&
        Object.keys(this.getBoxData[2]).length > 0
      ) {
        sumObj["selectedPeriod"]["total"] =
          this.getBoxData[0].value + this.getBoxData[2].value;
      } else if (
        this.getKPI.value == 2 &&
        Object.keys(this.getBoxData[1]).length > 0 &&
        Object.keys(this.getBoxData[3]).length > 0
      ) {
        sumObj["selectedPeriod"]["total"] =
          this.getBoxData[1].value + this.getBoxData[3].value;
      } else if (
        this.getKPI.value == 3 &&
        Object.keys(this.getBoxData[2]).length > 0
      ) {
        sumObj["selectedPeriod"]["total"] = this.getBoxData[2].value;
      } else if (
        this.getKPI.value == 4 &&
        Object.keys(this.getBoxData[3]).length > 0
      ) {
        sumObj["selectedPeriod"]["total"] = this.getBoxData[3].value;
      }

      var selectedPeriod = sumObj["selectedPeriod"]["total"];
      var comparePeriod = sumObj["comparisionPeriod"]["total"];
      var priorPeriod = sumObj["priorPeriod"]["total"];

      sumObj["priorPeriod"]["change"] = isFinite(
        (selectedPeriodTotal - priorPeriod) / priorPeriod
      )
        ? (((selectedPeriod - priorPeriod) / priorPeriod) * 100).toFixed(2)
        : 0;

      sumObj["comparisionPeriod"]["change"] = isFinite(
        (selectedPeriod - comparePeriod) / comparePeriod
      )
        ? (((selectedPeriod - comparePeriod) / comparePeriod) * 100).toFixed(2)
        : 0;

      // if(this.getKPI.value ==1)
      // trafficData = adultInData + childInData;
      // else
      return sumObj;
    },

    // CHECKING IF DATA IS NOT EMPTY
    isData() {
      var series = this.chartOptions.series;
      return series[0].data.length == 0 &&
        series[1].data.length == 0 &&
        series[2].data.length == 0
        ? false
        : true;
    },

    // GET CONSOLIDATION STATE FROM STORE
    getConsolidation() {
      return this.$store.state.filter.consolidation;
    },

    // NAME FOR THE CHART
    getChartName() {
      var consolidation = "";
      switch (this.getConsolidation) {
        case "hour":
          consolidation = "Hour";
          break;
        case "dayOfMonth":
          consolidation = "Day";
          break;
        case "week":
          consolidation = "Week";
          break;
        case "month":
          consolidation = "Month";
          break;
        case "year":
          consolidation = "Year";
          break;
        default:
          consolidation = "Day";
      }
      return consolidation;
    },

    // NAME FOR CSV AND PDF FILE
    getFileName() {
      return (
        this.getChartName +
        " CONSOLIDATED " +
        this.getKPI.text +
        " DATA"
      ).toUpperCase();
    },
  },
  // updated: function() {
  //   this.overlay = false;
  // },
  created() {
    // this.setChartData();
    this.$root.$refs.current_page = this;
    // this.$parent.$children[1].PageReady();
    // this.setChartData();
    // this.setChart();
    // this.timer1 = setInterval(this.setChartDataAsync, 10000);
    // this.timer2 = setInterval(this.setChart, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
  },
  // WATCHING FOR CHANGE IN THE CONSOLIDATION
  // watch: {
  //   // getParameters: function () {
  //   //   this.overlay = true;
  //   //   this.setChartData();
  //   // },
  //   // getChartData: function () {
  //   //   this.setChart();
  //   //   // if (this.computedCalls > this.passedCalls) {
  //   //   //   this.passedCalls += 1;
  //   //   // } else {
  //   //   //   this.overlay = false;
  //   //   // }
  //   // },
  //   // overlay(val) {
  //   //   this.overlay = val;
  //   // },
  // },
};
</script>

<style scope>
