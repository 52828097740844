<template>
  <v-container>
    <!-- <div class="pa-1 title">EVENTS</div> -->
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <v-card>
      <div ref="content">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8">
              <v-icon size="40" color="black">mdi-calendar</v-icon>
              <span class="pa-4">EVENTS</span>
              <v-select @change="submitTypedrp"
                hide-details
                style="display: inline-table; width: 17%"
                v-model="selectedEvent"
                :items="setEvents"
              ></v-select>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="submitType"
                    data-html2canvas-ignore="true"
                    class="primary ma-1"
                    v-on="on"
                  >
                    {{ chartType.name }}
                    <v-icon right>{{ chartType.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>Select Chart Type</span>
              </v-tooltip>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="pdfClick()">
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="csvClick()">
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts :options="chartOptions"></highcharts>
            </v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>

    <v-snackbar :timeout="6000" :top="true" v-model="showAlert">
      {{ alertMessage }}
      <v-btn color="red" text @click="showAlert = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
const expo = require("../plugins/export-2");
export default {
  name: "home",
  data() {
    return {
      overlay: true,
      showAlert: false,
      alertMessage: "",
      computedCall: 3,
      passedCall: 1,
      chartType: {
        name: "bar",
        icon: "mdi-chart-bar",
      },

      // CHART DATA
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          width: 1200,
          type: "line",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "",
            data: [],
            color: "green",
          },
          // {
          //   name: "",
          //   data: [],
          //   color: "purple",
          // },
        ],
      },
      // KPI DATA PARAMETERS
      params: {
        kpiType: "numeric",
        fetchDataFor: "line",
        dataFilter: [
          {
            kpiGroupId: "5a41fd655bca800604b146cc",
            kpiId: "5a41fd655bca80kpi14b147cc",
            elementId: "Adult",
            label: "HOURLY",
            color: "#7F186E",
            showOnAxis: "y1",
            mapped: [
              {
                mappedFullSite: 0,
                siteId: "full",
                zones: [],
              },
            ],
          },
          {
            kpiGroupId: "5a41fd655bca800604b146cc",
            kpiId: "5a41fd655bca80kpi14b147cc",
            elementId: "Child",
            label: "HOURLY",
            color: "#7F186E",
            showOnAxis: "y1",
            mapped: [
              {
                mappedFullSite: 0,
                siteId: "full",
                zones: [],
              },
            ],
          },
        ],
        from: "",
        to: "",
        timeFrame: "",
        consolidateSiteData: 1,
        consolidateData: 1,
        localTimezone: "+04:00",
        showDataByOperationalHours: true,
      },
    };
  },
  // METHODS
  methods: {
    //TOGGLE CHART (LINE OR COLUMN)
    submitType() {
      if (this.chartType.name == "bar") {
        this.chartType.name = "line";
        this.chartType.icon = "mdi-chart-bell-curve-cumulative";
        this.chartOptions.chart.type = "column";
      } else {
        this.chartType.name = "bar";
        this.chartType.icon = "mdi-chart-bar";
        this.chartOptions.chart.type = "line";
      }
    },
    submitTypedrp() {
      this.$store.dispatch("cancelRequest");
      this.$store.state.home.selectedPeriodData = [];
      this.$store.state.home.comparePeriodData = [];
      this.$store.state.home.priorPeriodData = [];
      this.$store.state.home.priorYearData = [];
      this.$store.state.home.footfallData = [];
      this.$store.state.home.adultInData = [];
      this.$store.state.home.adultOutData = [];
      this.$store.state.home.childOutData = [];
      this.PageReady();
    },

    // EXPORT CONTENT AS PDF USING THE REFERENCE ID
    pdfClick() {
      this.showAlert = true;
      var that = this;
      if (
        document.getElementsByClassName("v-navigation-drawer--mini-variant")
          .length != 1
      )
        document.querySelector(".v-navigation-drawer button").click();

      setTimeout(function () {
        if (that.isData) {
          var filename = that.getFileName + ".pdf";
          var content = that.$refs.content;
          var text = that.pdfFilterData();
          var response = expo.createPDF(filename, text, content);
          that.alertMessage = response;
        } else {
          that.alertMessage = "No data to export";
        }
      }, 500);
    },
    // EXPORTING VALUES TO CSV FORMAT
    csvClick() {
      this.showAlert = true;
      if (this.isData) {
        var filename = this.getFileName + ".csv";
        var data = this.csvData();
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    pdfFilterData() {
      var events = this.getSelectedEvent;
      var reportName = this.getFileName;
      //var eventName = events.eventName;
      var generationDate = new Date();
      var siteNames = _.map(events.mapped, "siteName");
      var zoneNames = _.map(events.mapped, "zones");
      var operationalHour = this.$store.state.filter.operationalHour;
      var consolidation = this.getChartName;
      var selectedPeriodDate =
        moment(this.$store.getters.selectedPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.selectedPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var selectedComparisonPeriodDate =
        moment(this.$store.getters.comparePeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.comparePeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var priorPeriodDate =
        moment(this.$store.getters.priorPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");
      var priorYearDate =
        moment(this.$store.getters.priorYearDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorYearDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("YYYY-MM-DD");
      var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Current Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Comparison Period Date</td> <td> ${selectedComparisonPeriodDate}</td> </tr> <tr> <td>Perior Period Date</td> <td> ${priorPeriodDate}</td> </tr> <tr> <td>Prior Year Date</td> <td> ${priorYearDate}</td> </tr> </tbody> </table>`;
      return table;
    },
    // RECREATING DATA FOR CSV EXPORT
    csvData() {
      var data = this.getChartData;
      var csvData = [];
      var csvObj = {};
      var dates =
        data["selectedPeriodDate"].length >= data["priorYearDate"].length
          ? data["selectedPeriodDate"]
          : data["priorYearDate"];
      var array = _.range(0, dates.length);
      _.each(array, function (value) {
        csvObj["currentPeriodTime"] = data.selectedPeriodDate[value] || 0;
        csvObj["currentPeriodData"] = data.selectedPeriod[value] || 0;
        // csvObj["priorPeriodTime"] = data.priorPeriodDate[value] || 0;
        // csvObj["priorPeriodData"] = data.priorPeriod[value] || 0;
        // csvObj["priorYearTime"] = data.priorYearDate[value] || 0;
        // csvObj["priorYearData"] = data.priorYear[value] || 0;
        csvData.push({ ...csvObj });
      });
      return csvData;
    },

    setEventData() {
      this.$store.dispatch("getEvents");
    },
    // STORE CHART DATA INTO VUEX STORE
    setChartData() {
      var params = this.getParameters;
      // params.forEach((param) => {
      //   await this.$store.dispatch("getKpiData", param);
      // });
      for (const param of params) {
        this.$store.dispatch("getKpiData", param);
      }
      //this.overlay = false;
    },

    // ASSIGNING DATA TO CHART
    setChart() {
      var data = this.getChartData;
      var dates =
        data["selectedPeriodDate"].length >= data["priorYearDate"].length
          ? data["selectedPeriodDate"]
          : data["priorYearDate"];

      this.chartOptions.series[0].name =
        "Current Period (" +
        moment(this.$store.getters.selectedPeriodDate[0]).format("DD/MM/YY") +
        " to " +
        moment(this.$store.getters.selectedPeriodDate[1]).format("DD/MM/YY") +
        ")";

      // this.chartOptions.series[1].name =
      //   "Prior Year (" +
      //   moment(this.$store.getters.priorYearDate[0]).format("DD/MM/YY") +
      //   " to " +
      //   moment(this.$store.getters.priorYearDate[1]).format("DD/MM/YY") +
      //   ")";

      // var dates = data["selectedPeriodDate"];
      //this.chartOptions.series[1].visible = false;
      
      this.chartOptions.xAxis.categories = dates;
      this.chartOptions.series[0].data = data["selectedPeriod"];
      // this.chartOptions.series[1].data = data["priorPeriod"];
      //this.chartOptions.series[1].data = data["priorYear"];
      this.chartOptions.title.text = this.getFileName;
      // if (data["selectedPeriod"].length > 0)
      this.overlay = false;
    },

    // GET FULL MONTH NAME
    getMonthName(monthInt) {
      var month = new Array();
      month[1] = "January";
      month[2] = "February";
      month[3] = "March";
      month[4] = "April";
      month[5] = "May";
      month[6] = "June";
      month[7] = "July";
      month[8] = "August";
      month[9] = "September";
      month[10] = "October";
      month[11] = "November";
      month[12] = "December";
      return month[monthInt];
    },
    PageReady(loader = true) {
      if (loader) this.overlay = true;
      clearInterval(this.timer1);
      clearInterval(this.timer2);

      this.timer2 = setInterval(() => {
        if (this.$store.state.filter.selectedZones.length > 0) {
          clearInterval(this.timer2);
          this.setChartData();
          this.timer2 = setInterval(() => {
            var data = this.getChartData;
            
            if (
              data["selectedPeriod"].length > 0 &&
              data["selectedPeriodDate"].length > 0 &&
              this.$store.state.home.events.length > 0
            ) {
              clearInterval(this.timer2);
              this.setChart();
            }
          }, 100);
        }
      }, 100);
    },
  },
  computed: {
     getSiteObjects() {
      var dataFilter = [];
      this.$store.state.filter.selectedSites.forEach((siteId) => {
        var AdultsiteObject = {},
          ChildsiteObject = {};
        (AdultsiteObject["kpiGroupId"] = "5a41fd655bca800604b146cc"),
          (AdultsiteObject["kpiId"] = "5a41fd655bca80kpi14b147cc"),
          (AdultsiteObject["elementId"] = "Adult"),
          (AdultsiteObject["label"] = "HOURLY"),
          (AdultsiteObject["color"] = "#7F186E"),
          (AdultsiteObject["showOnAxis"] = "y1"),
          (AdultsiteObject["mapped"] = [
            {
              mappedFullSite: 1,
              siteId: siteId,
            },
          ]);
        (ChildsiteObject["kpiGroupId"] = "5a41fd655bca800604b146cc"),
          (ChildsiteObject["kpiId"] = "5a41fd655bca80kpi14b147cc"),
          (ChildsiteObject["elementId"] = "Child"),
          (ChildsiteObject["label"] = "HOURLY"),
          (ChildsiteObject["color"] = "#7F186E"),
          (ChildsiteObject["showOnAxis"] = "y1"),
          (ChildsiteObject["mapped"] = [
            {
              mappedFullSite: 1,
              siteId: siteId,
            },
          ]);
        dataFilter.push(ChildsiteObject);
        dataFilter.push(AdultsiteObject);
      });
      return _.reverse(dataFilter);
    },
    selectedEvent: {
      get() {
        return this.$store.state.home.selectedEvent;
      },
      set(value) {
        this.$store.commit("selectedEvent", value);
      },
    },
    // MAPPING EVENT VALUES TO SELECT LIST
    setEvents() {
      return this.$store.state.home.events.map((event) => {
        return { text: event.eventName, value: event._id };
      });
    },

    // setEvents() {
    //   return this.$store.state.home.events.map(function(event) {
    //     return { text: event.eventName, value: event._id };
    //   });
    // },

    getSelectedEvent() {
      var event = _.find(this.$store.state.home.events, {
        _id: this.selectedEvent,
      });
      return typeof event == "undefined" ? 0 : event;
    },

    // GET PARAMETERS
    getParameters() {
      var payloads = [];
      var slelectedPeriodPayload = {};
      // var priorPeriodPayload = {};
      var priorYearPayload = {};
      var params = JSON.parse(JSON.stringify(this.params));

      var event = this.getSelectedEvent;
      // params.dataFilter[0].mapped = event.mapped;
      params.dataFilter = this.getSiteObjects;
      console.log("event.mapped",event.mapped);
      
      params.timeFrame = this.$store.state.filter.consolidation;
      params.showDataByOperationalHours = this.$store.state.filter.operationalHour;

      var dateFrom =
        moment(event.from, "YYYY-MM-DDThh:mm:ss.SSSZ")
          .subtract(1, "days")
          .format("YYYY-MM-DD") + " 00:00:00";
      var dateTo =
        moment(event.to, "YYYY-MM-DDThh:mm:ss.SSSZ")
          .subtract(1, "days")
          .format("YYYY-MM-DD") + " 23:59:59";

      //SELECTED PERIOD
      var params1 = JSON.parse(JSON.stringify(params));
      params1.from = dateFrom;
      params1.to = dateTo;
      slelectedPeriodPayload["params"] = params1;
      slelectedPeriodPayload["mutationId"] = "selectedPeriodData";
      payloads.push(slelectedPeriodPayload);

      //PRIOR PERIOD
      // var params2 = JSON.parse(JSON.stringify(params));
      // params2.from =
      //   moment(dateFrom, "YYYY-MM-DD hh:mm:ss")
      //     .subtract(7, "days")
      //     .format("YYYY-MM-DD") + " 21:00:00";
      // params2.to =
      //   moment(dateTo, "YYYY-MM-DD hh:mm:ss")
      //     .subtract(7, "days")
      //     .format("YYYY-MM-DD") + " 21:00:00";
      // priorPeriodPayload["params"] = params2;
      // priorPeriodPayload["mutationId"] = "priorPeriodData";
      // payloads.push(priorPeriodPayload);

      //PRIOR YEAR
      var params3 = JSON.parse(JSON.stringify(params));
      params3.from =
        moment(dateFrom, "YYYY-MM-DD hh:mm:ss")
          .subtract(1, "years")
          .format("YYYY-MM-DD") + " 00:00:00";
      params3.to =
        moment(dateTo, "YYYY-MM-DD hh:mm:ss")
          .subtract(1, "years")
          .format("YYYY-MM-DD") + " 23:59:59";
      priorYearPayload["params"] = params3;
      priorYearPayload["mutationId"] = "priorYearData";
      payloads.push(priorYearPayload);
      return payloads;
    },

    // GET STORE DATA FROM VUEX STORE
    getChartData() {
      var data = {};
      var selectedPeriodData = [];
      // var priorPeriodData = [];
      var PriorYearData = [];
      var selectedPeriodDate = [];
      // var priorPeriodDate = [];
      var priorYearDate = [];

      this.$store.state.home.selectedPeriodData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var date =
              this.getConsolidation == "month"
                ? this.getMonthName(kpi.date)
                : kpi.date;
            // selectedPeriodDate.push(date);
            // selectedPeriodData.push(kpi.value);
              if (selectedPeriodDate.includes(date)) {
              var indexIfExist = selectedPeriodDate.indexOf(date);
              selectedPeriodData[indexIfExist] += kpi.value;
            } 
            //Added condition for hourly sort
            else if(typeof date == 'number'){
              selectedPeriodDate[date] = date;
              selectedPeriodData[date] = kpi.value;
            } 
            else {
              selectedPeriodDate.push(date);
              selectedPeriodData.push(kpi.value);
            }
          });
        }
      });
      // this.$store.state.home.priorPeriodData.forEach(site => {
      //   if (site.data.length != 0) {
      //     site.data.forEach(kpi => {
      //       var date =
      //         this.getConsolidation == "month"
      //           ? this.getMonthName(kpi.date)
      //           : kpi.date;
      //       priorPeriodDate.push(date);
      //       priorPeriodData.push(kpi.value);
      //     });
      //   }
      // });
      this.$store.state.home.priorYearData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            // priorYearDate.push(kpi.date);
            // PriorYearData.push(kpi.value);
            var date =
              this.getConsolidation == "month"
                ? this.getMonthName(kpi.date)
                : kpi.date;

            if (priorYearDate.includes(date)) {
              var indexIfExist = priorYearDate.indexOf(date);
              PriorYearData[indexIfExist] += kpi.value;
            } 
            //Added condition for hourly sort
            else if(typeof date == 'number'){
              priorYearDate[date] = date;
              PriorYearData[date] = kpi.value;
            } else {
              priorYearDate.push(date);
              PriorYearData.push(kpi.value);
            }
          });
        }
      });

      data["selectedPeriodDate"] = selectedPeriodDate;
      // data["priorPeriodDate"] = priorPeriodDate;
      data["priorYearDate"] = priorYearDate;
      data["selectedPeriod"] = selectedPeriodData;
      // data["priorPeriod"] = priorPeriodData;
      data["priorYear"] = PriorYearData;

      // eslint-disable-next-line
      // console.log(this.$store.state.home.selectedPeriodData);
      return data;
    },

    // CHECKING IF DATA IS NOT EMPTY
    isData() {
      var series = this.chartOptions.series;
      return series[0].data.length == 0 ? false : true;
      // return series[0].data.length == 0 &&
      //   series[1].data.length == 0 &&
      //   series[2].data.length == 0
      //   ? false
      //   : true;
    },

    // GET CONSOLIDATION STATE FROM STORE
    getConsolidation() {
      return this.$store.state.filter.consolidation;
    },

    // NAME FOR THE CHART
    getChartName() {
      var consolidation = "";
      switch (this.$store.state.filter.consolidation) {
        case "hour":
          consolidation = "Hourly";
          break;
        case "dayOfMonth":
          consolidation = "Daily";
          break;
        case "week":
          consolidation = "Weekly";
          break;
        case "month":
          consolidation = "Monthly";
          break;
        case "year":
          consolidation = "Yearly";
          break;
        default:
          consolidation = "daily";
      }
      return consolidation;
    },

    // NAME FOR CSV AND PDF FILE
    getFileName() {
      return (
        this.getSelectedEvent.eventName +
        " " +
        this.getChartName +
        " Consolidated Data"
      );
    },
  },
  // updated: function () {
  //   this.overlay = false;
  // },
  created() {
    //debugger;
    this.setEventData();
    this.$root.$refs.current_page = this;
    // this.setChartData();
    // this.setChart();
  },
  // WATCHING FOR CHANGE IN THE CONSOLIDATION
  // watch: {
  //   getParameters: function () {
  //     this.setChartData();
  //   },
  //   getChartData: function () {
  //     this.setChart();
  //     // if (this.computedCalls > this.$store.state.home.passedCalls) {
  //     //   this.$store.state.home.passedCalls += 1;
  //     // } else {
  //     //   this.overlay = false;
  //     // }
  //   },
  //   // overlay(val) {
  //   //   this.overlay = val;
  //   //   val &&
  //   //     setTimeout(() => {
  //   //       this.overlay = false;
  //   //     }, 3000);
  //   // }
  // },
};
</script>

<style>
</style>