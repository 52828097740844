<template>
  <v-container>
    <!-- <div class="pa-1 title">OVERVIEW</div> -->
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>

    <!-- BOXES -->
    <div ref="boxes">
      <v-row class="mb-6" no-gutters>
        <v-col v-for="box in getBoxData" :key="box.label">
          <v-card
            class="pa-2 mr-2 text-center"
            tile
            outlined
            :color="box.color"
            v-if="box.enabled"
          >
            <v-card-text class="black--text font-weight-bold">
              <h1 class="mb-4">{{ box.value }}</h1>
              <span>{{ box.label }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- OVERVIEW -->
    <v-card>
      <div ref="content">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8">
              <v-icon size="40" color="black">{{ this.getKPI.icon }}</v-icon>
              <span class="pa-4">{{ getKPI.text.toUpperCase() }}</span>
              <v-select
                hide-details
                style="display: inline-table; width: 17%"
                v-model="kpiDefault"
                :items="kpis"
              ></v-select>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="submitType"
                    data-html2canvas-ignore="true"
                    class="primary ma-1"
                    v-on="on"
                  >
                    {{ chartType.name }}
                    <v-icon right>{{ chartType.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>Select Chart Type</span>
              </v-tooltip>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="pdfClick()">
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="csvClick()">
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts class="chart" :options="chartOptions"></highcharts>
            </v-flex>
            <v-flex>
              <ul
                class="list-group list-group-flush text-center"
                style="list-style: none"
              >
                <li class="list-group-item pb-12">
                  <div class="pb-12">
                    <span class="dot green"></span>
                    <label class="float-left">{{
                      chartOptions.series[0].name.toUpperCase()
                    }}</label>
                    <label class="float-right sub-title"
                      >{{ getTotal["selectedPeriod"]["datetime"]["from"] }} to
                      {{ getTotal["selectedPeriod"]["datetime"]["to"] }}</label
                    >
                  </div>

                  <div>
                    <h1>
                      {{ formatNumber(getTotal["selectedPeriod"]["total"]) }}
                    </h1>
                    <label>OVERALL {{ getKPI.text.toUpperCase() }}</label>
                  </div>
                </li>

                <li class="list-group-item pb-12 grey lighten-4">
                  <div class="pb-12">
                    <span class="dot blue"></span>
                    <label class="float-left">{{
                      chartOptions.series[1].name.toUpperCase()
                    }}</label>
                    <label class="float-right sub-title"
                      >{{ getTotal["priorPeriod"]["datetime"]["from"] }} to
                      {{ getTotal["priorPeriod"]["datetime"]["to"] }}</label
                    >
                  </div>
                  <div
                    :class="getClass(getTotal['priorPeriod']['change'])"
                  ></div>
                  <span style="float: left"
                    >{{ getTotal["priorPeriod"]["change"] }}%</span
                  >
                  <div>
                    <h1>
                      {{ formatNumber(getTotal["priorPeriod"]["total"]) }}
                    </h1>
                  </div>
                </li>
                <v-divider></v-divider>
                <!-- <li class="list-group-item pb-12 grey lighten-4">
                  <div class="pb-12">
                    <span class="dot purple"></span>
                    <label class="float-left">{{chartOptions.series[2].name.toUpperCase()}}</label>
                    <label
                      class="float-right sub-title"
                    >{{getTotal["priorYear"]["datetime"]["from"]}} to {{getTotal["priorYear"]["datetime"]["to"]}}</label>
                  </div>

                  <div :class="getClass(getTotal['priorYear']['change'])"></div>
                  <span style="float:left;">{{getTotal['priorYear']['change']}}%</span>
                  <div>
                    <h1>{{formatNumber(getTotal["priorYear"]["total"])}}</h1>
                  </div>
                </li> -->
              </ul>
            </v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>

    <!-- TABLE -->
    <div ref="custable" class="pt-2">
      <v-flex>
        <table id="table" style="width: 100%">
          <thead>
            <tr>
              <th>{{ this.getChartName }}</th>
              <th>Year</th>
              <th>Count</th>
              <!-- <th>Average</th> -->
              <th>Variance %</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="data in getTableData">
              <tr :key="data.priorPeriodYear">
                <td rowspan="2">{{ data.timeFrame }}</td>
                <td>{{ data.priorYear }}</td>
                <td>{{ data.priorYearCount }}</td>
                <!-- <td>{{ data.priorYearAvg }}</td> -->
                <td rowspan="2">{{ data.variance }}</td>
              </tr>
              <tr :key="data.selectedPeriodYear">
                <td>{{ data.selectedYear }}</td>
                <td>{{ data.selectedPeriodCount }}</td>
                <!-- <td>{{ data.selectedPeriodAvg }}</td> -->
              </tr>
            </template>
          </tbody>
        </table>
      </v-flex>
    </div>

    <!-- POWER HOUR -->

    <v-card class="mt-4">
      <div ref="custable2">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-diameter</v-icon>
              <span class="pa-4">POWER HOURS</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="powerHourPdfClick()"
                    >
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickPowerHour()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <table id="table" style="width: 100%">
            <thead>
              <tr>
                <th></th>
                <th>SUN</th>
                <th>MON</th>
                <th>TUE</th>
                <th>WED</th>
                <th>THU</th>
                <th>FRI</th>
                <th>SAT</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
              </tr>
              <template v-for="data in getPowerHourData">
                <tr :key="data.hour">
                  <td>{{ data.hour }}</td>
                  <td :style="getCellColor(data.Sunday)">{{ data.Sunday }}</td>
                  <td :style="getCellColor(data.Monday)">{{ data.Monday }}</td>
                  <td :style="getCellColor(data.Tuesday)">
                    {{ data.Tuesday }}
                  </td>
                  <td :style="getCellColor(data.Wednesday)">
                    {{ data.Wednesday }}
                  </td>
                  <td :style="getCellColor(data.Thursday)">
                    {{ data.Thursday }}
                  </td>
                  <td :style="getCellColor(data.Friday)">{{ data.Friday }}</td>
                  <td :style="getCellColor(data.Saturday)">
                    {{ data.Saturday }}
                  </td>
                  <td :style="getCellColor(data.total)">{{ data.total }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </v-card-text>
      </div>
    </v-card>

    <v-snackbar :timeout="6000" :top="true" v-model="showAlert">
      {{ alertMessage }}
      <v-btn color="red" text @click="showAlert = false">Close</v-btn>
    </v-snackbar>
    <!-- {{this.getTableData}}
    {{this.getChartData}}-->

    <!-- {{ this.$store.state.home.selectedPeriodData}} -->
    <!-- {{ this.$store.state.home.footfallData}} -->
    <!-- {{this.getPowerHourData}} -->
  </v-container>
</template>



<script>
import moment from "moment";
import _ from "lodash";
const expo = require("../plugins/export-2");
export default {
  name: "home",
  data() {
    return {
      timer1: "",
      timer2: "",
      timer3:"",
      overlay: true,
      computedCalls: 0,
      passedCalls: 1,
      showAlert: false,
      alertMessage: "",
      chartType: {
        name: "bar",
        icon: "mdi-chart-bar",
      },
      // CHART KPI'S
      kpiDefault: {
        text: "Traffic In",
        value: 1,
        kpiGroupId: "5a41fd655bca800604b146cc",
        kpiId: "5a41fd655bca80kpi14b147cc",
        element: "Adult",
        icon: "mdi-walk",
      },
      kpis: [
        {
          text: "Traffic In",
          value: 1,
          kpiGroupId: "5a41fd655bca800604b146cc",
          kpiId: "5a41fd655bca80kpi14b147cc",
          element: "Adult",
          icon: "mdi-walk",
        },
        {
          text: "Traffic Out",
          value: 2,
          kpiGroupId: "5a41fd655bca800604b140cc",
          kpiId: "5a41fd655bca80kpi24b147cc",
          element: "Adult",
          icon: "mdi-walk",
        },
        {
          text: "Child In",
          value: 3,
          kpiGroupId: "5a41fd655bca800604b146cc",
          kpiId: "5a41fd655bca80kpi14b147cc",
          element: "Child",
          icon: "mdi-human-child",
        },
        {
          text: "Child Out",
          value: 4,
          kpiGroupId: "5a41fd655bca800604b140cc",
          kpiId: "5a41fd655bca80kpi24b147cc",
          element: "Child",
          icon: "mdi-human-child",
        },
      ],

      // CHART DATA
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          zoomType: "x",
          width: 830,
          type: "line",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: "Selected period",
            data: [],
            color: "green",
          },
          {
            name: "Prior period",
            data: [],
            color: "blue",
          },
          // {
          //   name: "Prior year",
          //   data: [],
          //   color: "purple"
          // }
        ],
      },
      // KPI DATA PARAMETERS
      params: {
        kpiType: "numeric",
        fetchDataFor: "line",
        dataFilter: [
          {
            kpiGroupId: "",
            kpiId: "",
            elementId: "",
            label: "HOURLY",
            color: "#7F186E",
            showOnAxis: "y1",
            mapped: [
              {
                mappedFullSite: 0,
                siteId: "full",
                zones: [],
              },
            ],
          },
        ],
        from: "",
        to: "",
        timeFrame: "",
        consolidateSiteData: 1,
        consolidateData: 1,
        localTimezone: "+00:00",
        showDataByOperationalHours: true,
      },
    };
  },
  // METHODS
  methods: {
    //TOGGLE CHART (LINE OR COLUMN)
    submitType() {
      if (this.chartType.name == "bar") {
        this.chartType.name = "line";
        this.chartType.icon = "mdi-chart-bell-curve-cumulative";
        this.chartOptions.chart.type = "column";
        this.chartOptions.plotOptions.series.dataLabels.enabled = true;
      } else {
        this.chartType.name = "bar";
        this.chartType.icon = "mdi-chart-bar";
        this.chartOptions.chart.type = "line";
        this.chartOptions.plotOptions.series.dataLabels.enabled = false;
      }
    },

    // EXPORT CONTENT AS PDF USING THE REFERENCE ID
    pdfClick() {
      this.showAlert = true;

      if (this.isData) {
        var filename = this.getFileName + ".pdf";
        // var boxes = this.$refs.boxes;
        var content = this.$refs.content;
        var content1 = this.$refs.custable;
        var text = this.pdfFilterData();
        var response = expo.createPDF(
          filename,
          text,
          content,
          null,
          content1,
          true
        );
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },

    pdfFilterData() {
      var reportName = this.getFileName;
      var generationDate = new Date();
      var siteNames = _.map(this.$store.getters.selectedSites, "siteName");
      var zoneNames = _.map(this.$store.getters.selectedZones, "zoneName");
      var operationalHour = this.$store.state.filter.operationalHour;
      var consolidation = this.getChartName;
      var selectedPeriodDate =
        moment(this.$store.getters.selectedPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.selectedPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");
      var priorPeriodDate =
        moment(this.$store.getters.priorPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");
      var priorYearDate =
        moment(this.$store.getters.priorYearDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorYearDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("YYYY-MM-DD");
      var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Selected Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Perior Period Date</td> <td> ${priorPeriodDate}</td> </tr> <tr> <td>Prior Year Date</td> <td> ${priorYearDate}</td> </tr> </tbody> </table>`;
      return table;
    },

    // EXPORTING VALUES TO CSV FORMAT
    csvClick() {
      this.showAlert = true;
      if (this.isData) {
        var filename = this.getFileName + ".csv";
        var data = this.csvData();
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },

    // RECREATING DATA FOR CSV EXPORT
    csvData() {
      var data = this.getChartData;
      var csvData = [];
      var csvObj = {};
      var dates =
        data["selectedPeriodDate"].length >= data["priorPeriodDate"].length
          ? data["selectedPeriodDate"]
          : data["priorPeriodDate"];

      var array = _.range(0, dates.length);
      _.each(array, function (value) {
        csvObj["selectedPeriodTime"] = data.selectedPeriodDate[value] || 0;
        csvObj["selectedPeriodData"] = data.selectedPeriod[value] || 0;
        csvObj["priorPeriodTime"] = data.priorPeriodDate[value] || 0;
        csvObj["priorPeriodData"] = data.priorPeriod[value] || 0;
        csvObj["priorYearTime"] = data.priorYearDate[value] || 0;
        csvObj["priorYearData"] = data.priorYear[value] || 0;
        csvData.push({ ...csvObj });
      });
      return csvData;
    },
    powerHourPdfClick() {
      this.showAlert = true;

      if (this.isData) {
        var filename = "POWER HOUR DATA" + ".pdf";
        var content1 = this.$refs.custable2;
        var text = this.pdfFilterData();
        var response = expo.createPDF(
          filename,
          text,
          content1,
          content1,
          null,
          false
        );
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    csvClickPowerHour() {
      this.showAlert = true;
      if (this.isData) {
        var filename = this.getFileName + ".csv";
        var data = this.getPowerHourData;
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },

    // STORE CHART DATA INTO VUEX STORE
    setChartData() {
      var params = this.getParameters;
      var params2 = this.getParameters2;
      // console.log(params)
      params.forEach((param) => {
        this.$store.dispatch("getKpiData", param);
      });
      params2.forEach((param) => {
        this.$store.dispatch("getKpiData", param);
      });
    },

    // ASSIGNING DATA TO CHART
    setChart() {
      var data = this.getChartData;
      this.chartOptions.yAxis.title.text = this.getKPI.text + " Value";
      // var dates =
      //   data["selectedPeriodDate"].length >= data["priorPeriodDate"].length
      //     ? data["selectedPeriodDate"]
      //     : data["priorPeriodDate"];
      this.chartOptions.xAxis.categories = data["period"];
      this.chartOptions.series[0].data = data["selectedPeriod"];
      this.chartOptions.series[1].data = data["priorPeriod"];
      this.chartOptions.series[2].data = data["priorYear"];
      this.chartOptions.title.text = this.getChartName + " Consolidated Data";
      this.overlay = false;
    },

    // GET ARROW CLASS
    getClass(rows) {
      if (Math.sign(rows) == -1) return "arrow-down";
      else return "arrow-up";
    },

    // GET ARROW CLASS
    getCellColor(value) {
      if (value <= 200) return "background-color:#add8e6";
      else return "background-color:white";
    },

    // FORMATING THE NUMBER WITH COMMA
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    // GET FULL MONTH NAME
    getMonthName(monthInt) {
      var month = new Array();
      month[1] = "January";
      month[2] = "February";
      month[3] = "March";
      month[4] = "April";
      month[5] = "May";
      month[6] = "June";
      month[7] = "July";
      month[8] = "August";
      month[9] = "September";
      month[10] = "October";
      month[11] = "November";
      month[12] = "December";
      return month[monthInt];
    },
    PageReady(loader = true) {
      if (loader) this.overlay = true;
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);

      this.timer2 = setInterval(() => {
        if (this.$store.state.filter.selectedZones.length > 0) {
          clearInterval(this.timer2);
          this.setChartData();
          this.timer2 = setInterval(() => {
            var data = this.getChartData;
            if (
              data["selectedPeriodDate"].length > 0 &&
              data["comparePeriodDate"].length > 0 &&
              data["priorPeriodDate"].length > 0
            ) {
              clearInterval(this.timer2);
              this.setChart();
              this.timer3 = setInterval(() => {
                this.PageReady(false);
              }, 20000);
            }
          }, 100);
        }
      }, 100);
    },
  },
  computed: {
    getKPI() {
      var value =
        typeof this.kpiDefault.value == "undefined"
          ? this.kpiDefault
          : this.kpiDefault.value;
      return _.find(this.kpis, { value: value });
    },
      getSiteObjects() {
      var dataFilter = [];
      this.$store.state.filter.selectedSites.forEach((siteId) => {
        var AdultsiteObject = {},
          ChildsiteObject = {};
        (AdultsiteObject["kpiGroupId"] = "5a41fd655bca800604b146cc"),
          (AdultsiteObject["kpiId"] = "5a41fd655bca80kpi14b147cc"),
          (AdultsiteObject["elementId"] = "Adult"),
          (AdultsiteObject["label"] = "HOURLY"),
          (AdultsiteObject["color"] = "#7F186E"),
          (AdultsiteObject["showOnAxis"] = "y1"),
          (AdultsiteObject["mapped"] = [
            {
              mappedFullSite: 1,
              siteId: siteId,
            },
          ]);
        (ChildsiteObject["kpiGroupId"] = "5a41fd655bca800604b146cc"),
          (ChildsiteObject["kpiId"] = "5a41fd655bca80kpi14b147cc"),
          (ChildsiteObject["elementId"] = "Child"),
          (ChildsiteObject["label"] = "HOURLY"),
          (ChildsiteObject["color"] = "#7F186E"),
          (ChildsiteObject["showOnAxis"] = "y1"),
          (ChildsiteObject["mapped"] = [
            {
              mappedFullSite: 1,
              siteId: siteId,
            },
          ]);
        dataFilter.push(ChildsiteObject);
        dataFilter.push(AdultsiteObject);
      });
      return _.reverse(dataFilter);
    },
    // GET PARAMETERS
    getParameters() {
      var payloads = [];
      var selectedPeriodPayload = {};
      var priorPeriodPayload = {};
      var priorYearPayload = {};
      var powerHourPayload = {};
      var params = JSON.parse(JSON.stringify(this.params));

      // params.dataFilter[0].kpiGroupId = this.getKPI.kpiGroupId;
      // params.dataFilter[0].kpiId = this.getKPI.kpiId;
      // params.dataFilter[0].elementId = this.getKPI.element;
      // params.dataFilter[0].mapped[0].zones = this.$store.state.filter.selectedZones;
      params.dataFilter = this.getSiteObjects;
      params.timeFrame = this.$store.state.filter.consolidation;
      params.showDataByOperationalHours = this.$store.state.filter.operationalHour;

      //SELECTED PERIOD
      var params1 = JSON.parse(JSON.stringify(params));
      params1.from = this.$store.getters.selectedPeriodDate[0];
      params1.to = this.$store.getters.selectedPeriodDate[1];
      params1.from =moment(params1.from).format("YYYY-MM-DD HH:mm:ss");
      selectedPeriodPayload["params"] = params1;
      selectedPeriodPayload["mutationId"] = "selectedPeriodData";
      payloads.push(selectedPeriodPayload);

      //PRIOR PERIOD
      var params2 = JSON.parse(JSON.stringify(params));
      params2.from = this.$store.getters.priorPeriodDate[0];
      params2.to = this.$store.getters.priorPeriodDate[1];
      priorPeriodPayload["params"] = params2;
      priorPeriodPayload["mutationId"] = "priorPeriodData";
      payloads.push(priorPeriodPayload);

      //PRIOR YEAR
      var params3 = JSON.parse(JSON.stringify(params));
      params3.from = this.$store.getters.priorYearDate[0];
      params3.to = this.$store.getters.priorYearDate[1];
      priorYearPayload["params"] = params3;
      priorYearPayload["mutationId"] = "priorYearData";
      payloads.push(priorYearPayload);

      //POWER HOUR
      var params4 = JSON.parse(JSON.stringify(params));
      params4.from = this.$store.getters.selectedPeriodDate[0];
      params4.to = this.$store.getters.selectedPeriodDate[1];
      params4.timeFrame = "hour";
      params4.consolidateData = 0;
      powerHourPayload["params"] = params4;
      powerHourPayload["mutationId"] = "footfallData";
      payloads.push(powerHourPayload);
      return payloads;
    },

    getParameters2() {
      var payloads = [];
      var adultInPayload = {};
      var adultOutPayload = {};
      var childInPayload = {};
      var childOutPayload = {};
      var params = JSON.parse(JSON.stringify(this.params));

      params.dataFilter[0].mapped[0].zones = this.$store.state.filter.selectedZones;
      params.from = this.$store.getters.selectedPeriodDate[0];
      params.from =moment(params.from).format("YYYY-MM-DD HH:mm:ss");
      params.to = this.$store.getters.selectedPeriodDate[1];
      params.timeFrame = this.$store.state.filter.consolidation;
      params.showDataByOperationalHours = this.$store.state.filter.operationalHour;

      //ADULT IN
      var params1 = JSON.parse(JSON.stringify(params));
      params1.dataFilter[0].kpiGroupId = "5a41fd655bca800604b146cc";
      params1.dataFilter[0].kpiId = "5a41fd655bca80kpi14b147cc";
      params1.dataFilter[0].elementId = "Adult";
      adultInPayload["params"] = params1;
      adultInPayload["mutationId"] = "adultInData";
      payloads.push(adultInPayload);

      //ADULT OUT
      var params2 = JSON.parse(JSON.stringify(params));
      params2.dataFilter[0].kpiGroupId = "5a41fd655bca800604b140cc";
      params2.dataFilter[0].kpiId = "5a41fd655bca80kpi24b147cc";
      params2.dataFilter[0].elementId = "Adult";
      adultOutPayload["params"] = params2;
      adultOutPayload["mutationId"] = "adultOutData";
      payloads.push(adultOutPayload);

      //CHILD IN
      var params3 = JSON.parse(JSON.stringify(params));
      params3.dataFilter[0].kpiGroupId = "5a41fd655bca800604b146cc";
      params3.dataFilter[0].kpiId = "5a41fd655bca80kpi14b147cc";
      params3.dataFilter[0].elementId = "Child";
      childInPayload["params"] = params3;
      childInPayload["mutationId"] = "childInData";
      payloads.push(childInPayload);

      //CHILD OUT
      var params4 = JSON.parse(JSON.stringify(params));
      params4.dataFilter[0].kpiGroupId = "5a41fd655bca800604b140cc";
      params4.dataFilter[0].kpiId = "5a41fd655bca80kpi24b147cc";
      params4.dataFilter[0].elementId = "Child";
      childOutPayload["params"] = params4;
      childOutPayload["mutationId"] = "childOutData";
      payloads.push(childOutPayload);
      return payloads;
    },

    getBoxData() {
      var data = [];
      var adultInData = {};
      var adultOutData = {};
      var childInData = {};
      var childOutData = {};

      this.$store.state.home.adultInData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value += kpi.value;
          });
        }
        adultInData["label"] = "ADULT IN";
        adultInData["color"] = "green";
        adultInData["enabled"] = true;
        adultInData["value"] = value;
      });

      this.$store.state.home.adultOutData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value += kpi.value;
          });
        }

        adultOutData["label"] = "ADULT OUT";
        adultOutData["color"] = "red";
        adultOutData["enabled"] = true;
        adultOutData["value"] = value;
      });
      this.$store.state.home.childInData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value += kpi.value;
          });
        }

        childInData["label"] = "CHILD IN";
        childInData["color"] = "green";
        childInData["enabled"] = true;
        childInData["value"] = value;
      });
      this.$store.state.home.childOutData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value += kpi.value;
          });
        }
        childOutData["label"] = "CHILD OUT";
        childOutData["color"] = "red";
        childOutData["enabled"] = true;
        childOutData["value"] = value;
      });
      data.push(adultInData);
      data.push(adultOutData);
      data.push(childInData);
      data.push(childOutData);

      return data;
    },

    getPowerHourData() {
      var data = [];
      var data3 = [];
      this.$store.state.home.footfallData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var dataObj = {};
            var date = moment(kpi.date, "DD-MM-YYYY hh:mm");
            dataObj.hour = date.format("H");
            dataObj.day = date.format("dddd");
            dataObj.value = kpi.value;
            data.push(dataObj);
          });
        }
      });

      data = _.groupBy(data, "hour");
      var weekDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      for (var i = 0; i < 24; i++) {
        var total = 0;
        var daysGrouped = _.groupBy(data[i], "day");
        var testObj = {};
        for (var j = 0; j < weekDays.length; j++) {
          var days = daysGrouped[weekDays[j]];
          if (typeof days != "undefined") {
            testObj.hour = i;
            var value = 0;
            days.forEach((day) => {
              value += day.value;
            });
            total += value;
            testObj[weekDays[j]] = value;
          }
          testObj.total = total;
        }
        data3.push(testObj);
      }

      return data3;
    },
    // GET STORE DATA FROM VUEX STORE
    getChartData() {
      var data = {};
      var selectedPeriodData = [];
      var priorPeriodData = [];
      var priorYearData = [];
      var selectedPeriodDate = [];
      var priorPeriodDate = [];
      var priorYearDate = [];
      // console.log(this.$store.state.home.selectedPeriodData);
      this.$store.state.home.selectedPeriodData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var date =
              this.getConsolidation == "month"
                ? this.getMonthName(kpi.date)
                : kpi.date;
             if (selectedPeriodDate.includes(date)) {
              var indexIfExist = selectedPeriodDate.indexOf(date);
              selectedPeriodData[indexIfExist] += kpi.value;
            } else {
              selectedPeriodDate.push(date);
              selectedPeriodData.push(kpi.value);
            }
          });
        }
      });
      this.$store.state.home.priorPeriodData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var date =
              this.getConsolidation == "month"
                ? this.getMonthName(kpi.date)
                : kpi.date;

              if (priorPeriodDate.includes(date)) {
              var indexIfExist = priorPeriodDate.indexOf(date);
              priorPeriodData[indexIfExist] += kpi.value;
            } else {
              priorPeriodDate.push(date);
              priorPeriodData.push(kpi.value);
            }
          });
        }
      });
      this.$store.state.home.priorYearData.forEach((site) => {
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            var date =
              this.getConsolidation == "month"
                ? this.getMonthName(kpi.date)
                : kpi.date;
             if (priorYearDate.includes(date)) {
              var indexIfExist = priorYearDate.indexOf(date);
              priorYearDate[indexIfExist] += kpi.value;
            } else {
              priorYearDate.push(date);
              priorYearData.push(kpi.value);
            }
          });
        }
      });

      var period =
        selectedPeriodDate.length >= priorPeriodDate.length &&
        priorYearDate.length
          ? selectedPeriodDate
          : priorPeriodDate.length >= priorYearDate.length
          ? priorPeriodDate
          : priorYearDate;
      data["period"] = period;
      data["selectedPeriodDate"] = selectedPeriodDate;
      data["priorPeriodDate"] = priorPeriodDate;
      data["priorYearDate"] = priorYearDate;
      data["selectedPeriod"] = selectedPeriodData;
      data["priorPeriod"] = priorPeriodData;
      data["priorYear"] = priorYearData;

      //   data["sp"] = {};
      //   data["sp"]["date"] = selectedPeriodDate;
      //   data["sp"]["value"] = selectedPeriodData;

      //   data["pp"] = {};
      //   data["pp"]["date"] = priorPeriodDate;
      //   data["pp"]["value"] = priorPeriodData;

      //   data["py"] = {};
      //   data["py"]["date"] = priorYearDate;
      //   data["py"]["value"] = priorYearData;
      return data;
    },
    // GET TABLE DATA FROM getChartData FUNCTION -- TESTING
    getTableData() {
      var tableData = [];
      var chartData = this.getChartData;
      var selectedPeriodSum = _.sum(chartData.selectedPeriod);
      var priorYearSum = _.sum(chartData.priorYear);

      var periodCount =
        chartData.selectedPeriodDate.length >= chartData.priorYearDate.length
          ? chartData.selectedPeriodDate.length
          : chartData.priorYearDate.length;
      //   var periodCount = chartData.period.length;
      for (var i = 0; i < periodCount; i++) {
        var dataObj = {};

        var selectedPeriodCount = chartData.selectedPeriod[i] || 0;
        var selectedPeriodAvg = Math.round(
          (selectedPeriodCount / selectedPeriodSum) * 100
        );
        //eslint-disable-next-line
        // console.log(selectedPeriodCount + "----" + selectedPeriodSum);

        var priorYearCount = chartData.priorYear[i];
        var priorYearAvg =
          Math.round((priorYearCount / priorYearSum) * 100) || 0;

        var timeFrame = chartData.selectedPeriodDate[i] || 0;
        if (this.getConsolidation == "dayOfMonth") {
          timeFrame = moment(chartData.period[i], "DD-MM-YYYY").format(
            "MMM-DD"
          );
        }

        var variance =
          ((selectedPeriodCount - priorYearCount) / priorYearCount) * 100 || 0;
        dataObj["timeFrame"] = timeFrame;
        dataObj["selectedYear"] = moment(
          this.$store.getters.selectedPeriodDate[0],
          "YYYY-MM-DD hh:mm:ss"
        )          
          .format("YYYY");
        dataObj["priorYear"] = moment(
          this.$store.getters.priorYearDate[0],
          "YYYY-MM-DD hh:mm:ss"
        )          
          .format("YYYY");
        dataObj["selectedPeriodCount"] = selectedPeriodCount || 0;
        dataObj["priorYearCount"] = priorYearCount || 0;
        dataObj["selectedPeriodAvg"] = selectedPeriodAvg;
        dataObj["priorYearAvg"] = priorYearAvg;
        dataObj["variance"] = variance.toFixed(2);
        tableData.push(dataObj);
      }

      return tableData;
    },
    // GET BOX DATA
    getTotal() {
      var data = this.getChartData;
      var dateFrom = this.$store.state.filter.dates[0];
      var dateTo = this.$store.state.filter.dates[1];
      var sumObj = {};
      var selectedPeriodTotal = data["selectedPeriod"].reduce(
        (a, b) => a + b,
        0
      );
      var priorPeriodTotal = data["priorPeriod"].reduce((a, b) => a + b, 0);
      var priorYearTotal = data["priorYear"].reduce((a, b) => a + b, 0);

      //SELECTED PERIOD
      sumObj["selectedPeriod"] = {};
      sumObj["selectedPeriod"]["datetime"] = {};
      sumObj["selectedPeriod"]["datetime"]["from"] = dateFrom;
      sumObj["selectedPeriod"]["datetime"]["to"] = dateTo;
      sumObj["selectedPeriod"]["total"] =
        Math.round(selectedPeriodTotal * 100) / 100;

          this.$store.state.home.childInData.forEach((site) => {
        var value = 0;
        if (site.data.length != 0) {
          site.data.forEach((kpi) => {
            value =
              parseInt(isNaN(value) ? 0 : value) +
              parseInt(isNaN(kpi.value) ? 0 : kpi.value);
          });
        }
      });

      //PRIOR PERIOD
      sumObj["priorPeriod"] = {};
      sumObj["priorPeriod"]["datetime"] = {};
      sumObj["priorPeriod"]["datetime"]["from"] = moment(dateFrom)
        .subtract(7, "days")
        .format("DD/MM/YYYY");
      sumObj["priorPeriod"]["datetime"]["to"] = moment(dateTo)
        .subtract(7, "days")
        .format("DD/MM/YYYY");
      sumObj["priorPeriod"]["total"] = priorPeriodTotal;

      sumObj["priorPeriod"]["change"] = isFinite(
        (selectedPeriodTotal - priorPeriodTotal) / priorPeriodTotal
      )
        ? Math.round(
            ((selectedPeriodTotal - priorPeriodTotal) / priorPeriodTotal) * 100
          )
        : 0;

      //PRIOR YEAR
      sumObj["priorYear"] = {};
      sumObj["priorYear"]["datetime"] = {};
      // sumObj["priorYear"]["datetime"]["from"] = moment(dateFrom)
      //   .subtract(1, "years")
      //   .format("YYYY-MM-DD");
      // sumObj["priorYear"]["datetime"]["to"] = moment(dateTo)
      //   .subtract(1, "years")
      //   .format("YYYY-MM-DD");
      sumObj["priorYear"]["datetime"]["from"] = moment(
        this.$store.getters.priorYearDate[0]
      ).format("L");
      sumObj["priorYear"]["datetime"]["to"] = moment(
        this.$store.getters.priorYearDate[1]
      ).format("L");
      sumObj["priorYear"]["total"] = priorYearTotal;

      sumObj["priorYear"]["change"] = isFinite(
        (selectedPeriodTotal - priorYearTotal) / priorYearTotal
      )
        ? Math.round(
            ((selectedPeriodTotal - priorYearTotal) / priorYearTotal) * 100
          )
        : 0;

      return sumObj;
    },

    // CHECKING IF DATA IS NOT EMPTY
    isData() {
      var series = this.chartOptions.series;
      return series[0].data.length == 0 &&
        series[1].data.length == 0 &&
        series[2].data.length == 0
        ? false
        : true;
    },

    // GET CONSOLIDATION STATE FROM STORE
    getConsolidation() {
      return this.$store.state.filter.consolidation;
    },

    // NAME FOR THE CHART
    getChartName() {
      var consolidation = "";
      switch (this.getConsolidation) {
        case "hour":
          consolidation = "Hour";
          break;
        case "dayOfMonth":
          consolidation = "Day";
          break;
        case "week":
          consolidation = "Week";
          break;
        case "month":
          consolidation = "Month";
          break;
        case "year":
          consolidation = "Year";
          break;
        default:
          consolidation = "Day";
      }
      return consolidation;
    },

    // NAME FOR CSV AND PDF FILE
    getFileName() {
      return (
        this.getChartName +
        " CONSOLIDATED " +
        this.getKPI.text +
        " DATA"
      ).toUpperCase();
    },
  },
  created() {
    this.$root.$refs.current_page = this;
    // this.setChartData();
    // this.setChart();
    // this.computedCalls = Object.keys(this._computedWatchers).length;
    // this.timer1 = setInterval(this.setChartData, 50000);
    // this.timer2 = setInterval(this.setChart, 50000);
  },
  beforeDestroy() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
  },
  // WATCHING FOR CHANGE IN THE CONSOLIDATION
  // watch: {
  //   getParameters: function() {
  //     this.overlay = true;
  //     this.setChartData();
  //   },
  //   getChartData: function() {
  //     this.setChart();
  //     if (this.computedCalls > this.passedCalls) {
  //       this.passedCalls += 1;
  //     } else {
  //       this.overlay = false;
  //     }
  //     //this.overlay = false;
  //   },
  //   overlay(val) {
  //     this.overlay = val;
  //     // val &&
  //     //   setTimeout(() => {
  //     //     this.overlay = false;
  //     //   }, 3000);
  //   }
  // }
};
</script>

<style scope>