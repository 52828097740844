<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="E-mail"
                    v-model="login.credential.email"
                    v-bind:rules="emailRules"
                    prepend-icon="mdi-email"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    v-model="login.credential.password"
                    v-bind:rules="passwordRules"
                    prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    v-on:keyup.enter="logItIn"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-on:click="logItIn">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
        <v-snackbar :timeout="6000" :top="true" v-model="showAlert">{{message}}</v-snackbar>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      showAlert: false,
      message: "",
      login: {
        credential: {
          email: "",
          password: ""
        }
      },
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid email"
      ],
      passwordRules: [v => !!v || "Password is required"]
    };
  },
  methods: {
    logItIn: function() {
      this.$store
        .dispatch("login", this.login)
        .then(() => this.$router.push("/"))
        .catch(err => {
          this.showAlert = true;
          this.message = err;
        });
    }
  }
};
</script>

<style>
</style>
