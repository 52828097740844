<template>
  <nav>
    <v-toolbar flat class="primary">
      <v-toolbar-title class="text-uppercase white--text">
        <span class="font-weight-light">Xpand</span>
        <span>Retail</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text color="white" @click="logItOut">
        <span>Sign Out</span>
        <v-icon right>mdi-logout</v-icon>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      :mini-variant.sync="mini"
      permanent
      app
      color="rgb(250, 250, 250)"
    >
      <v-list-item class="pb-2">
        <v-list-item-avatar>
          <v-img :src="getProfileImage"></v-img>
        </v-list-item-avatar>
        <v-list-item-subtitle>{{username.toUpperCase()}}</v-list-item-subtitle>
        <v-btn icon @click.stop="mini = !mini">
          <!-- <v-btn icon @click.stop="changeMini()"> -->
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-list dense>
        <template v-for="item in items">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
            :to="item.route"
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.route">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  props: {
    source: String
  },
  data: () => ({
    username: JSON.parse(localStorage.getItem("user")).name,
    profileImage: JSON.parse(localStorage.getItem("user")).profileImage,
    mini: true,
    dialog: false,
    drawer: null,
    items: [
      { text: "Overview", icon: "mdi-home-analytics", route: "/" },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Reports",
        model: false,
        children: [
          {
            text: "Events",
            icon: "mdi-calendar",
            route: "/events"
          },
          { text: "Site Report", icon: "mdi-sitemap", route: "/sites" },
          {
            text: "Zones Comparison",
            icon: "mdi-map-marker-radius",
            route: "/zones"
          },
           {
            text: "Anchor Report",
            icon: "mdi-map-marker-radius",
            route: "/anchor"
          },
          {
            text: "Gate Comparison",
            icon: "mdi-compare",
            route: "/gate-comparision"            
          },
          {
            text: "Week Comparision",
            icon: "mdi-calendar-week",
            route: "/week-comparision"
          }
        ]
      }
    ]
  }),
  methods: {
    logItOut: function() {
      this.$store
        .dispatch("logout")
        .then(() => this.$router.push("login"))
        .catch(err => err);
    }
  },
  computed: {
    getProfileImage() {
      return "https://xpandretail-assets.s3.amazonaws.com/" + this.profileImage;
    }
  }
};
</script>