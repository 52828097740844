var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"text-center"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1),_c('v-card',[_c('div',{ref:"comparision"},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-icon',{attrs:{"size":"40","color":"black"}},[_vm._v("mdi-compare")]),_c('span',{staticClass:"pa-4"},[_vm._v("SITE COMPARISION")])],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',{staticClass:"pa-2 float-right",attrs:{"data-html2canvas-ignore":"true"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary ma-1",on:{"click":function($event){return _vm.pdfClickComparision()}}},on),[_c('span',[_vm._v("PDF")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-pdf-box-outline")])],1)]}}])},[_c('span',[_vm._v("Export to PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary ma-1",on:{"click":function($event){return _vm.csvClickComparision()}}},on),[_c('span',[_vm._v("CSV")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Export to CSV")])])],1)])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',[_c('highcharts',{staticClass:"chart",attrs:{"options":_vm.chartOptions}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"mt-4"},[_c('div',{ref:"contribution"},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-icon',{attrs:{"size":"40","color":"black"}},[_vm._v("mdi-chart-pie")]),_c('span',{staticClass:"pa-4"},[_vm._v("SITE CONTRIBUTION")])],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',{staticClass:"pa-2 float-right",attrs:{"data-html2canvas-ignore":"true"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary ma-1",on:{"click":function($event){return _vm.csvClickContribution()}}},on),[_c('span',[_vm._v("CSV")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Export to CSV")])])],1)])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',[_c('highcharts',{attrs:{"options":_vm.pipChartOptions}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"mt-4"},[_c('div',{ref:"entrance"},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-icon',{attrs:{"size":"40","color":"black"}},[_vm._v("mdi-file-document-box-outline")]),_c('span',{staticClass:"pa-4"},[_vm._v("ENTRANCE SUMMARY")])],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',{staticClass:"pa-2 float-right",attrs:{"data-html2canvas-ignore":"true"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary ma-1",on:{"click":function($event){return _vm.csvClickSummary()}}},on),[_c('span',[_vm._v("CSV")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Export to CSV")])])],1)]),_c('v-text-field',{attrs:{"append-icon":"mdi-feature-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.summarySearch),callback:function ($$v) {_vm.summarySearch=$$v},expression:"summarySearch"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.summaryHeader,"items":_vm.getTableSummary,"search":_vm.summarySearch},scopedSlots:_vm._u([{key:"item.currentPeriodChange",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getClass(item.currentPeriodChange)}),_vm._v(" "+_vm._s(item.currentPeriodChange)+" ")]}},{key:"item.comparePeriodChange",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getClass(item.comparePeriodChange)}),_vm._v(" "+_vm._s(item.comparePeriodChange)+" ")]}},{key:"item.priorYearChange",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getClass(item.priorYearChange)}),_vm._v(" "+_vm._s(item.priorYearChange)+" ")]}},{key:"item.variance",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getClass(item.variance)}),_vm._v(" "+_vm._s(item.variance)+" ")]}}])})],1)]),_c('v-card',{staticClass:"mt-4"},[_c('div',{ref:"custable"},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-icon',{attrs:{"size":"40","color":"black"}},[_vm._v("mdi-diameter")]),_c('span',{staticClass:"pa-4"},[_vm._v("PERSON PER SQUARE METER")])],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',{staticClass:"pa-2 float-right",attrs:{"data-html2canvas-ignore":"true"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary ma-1",on:{"click":function($event){return _vm.csvClickSQM()}}},on),[_c('span',[_vm._v("CSV")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Export to CSV")])])],1)])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Site Name")]),_c('th',[_vm._v(" SQM(m "),_c('sup',[_vm._v("2")]),_vm._v(") ")]),_c('th',[_vm._v("Current Period (Person/SQM)")]),_c('th',[_vm._v("Compare Period (Person/SQM)")])])]),_c('tbody',_vm._l((_vm.sqmComparision),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.label))]),_c('td',[_vm._v(_vm._s(item.sqm))]),_c('td',[_vm._v(_vm._s(item.sqmSelectedPeriod || 0))]),_c('td',[_vm._v(_vm._s(item.sqmComparePeriod || 0))])])}),0)]},proxy:true}])})],1)],1)]),_c('v-snackbar',{attrs:{"timeout":6000,"top":true},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" "),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.showAlert = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }