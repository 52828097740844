import axios from 'axios'
window.axios = axios


const state = {
    status: '',
    token: localStorage.getItem('token') || '',
    user: [],
};
const mutations = {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, user) {
        state.status = 'success'
        state.token = user.token
        state.user = user
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.token = ''
    },
};
const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    token: state => {
        return state.token;
    },

};
const actions = {
    login({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios({
                url: 'customer/login',
                data: payload,
                method: 'POST'
            })
                .then(resp => {
                    const user = resp.data
                    const token = user.token
                    const clientId = user._id
                    localStorage.setItem('clientId', clientId)
                    localStorage.setItem('token', token)
                    localStorage.setItem('user', JSON.stringify(user))
                    commit('auth_success', user)
                    resolve(resp)
                })
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('token')
                    reject(err)
                })
        })
    },
    logout({
        commit
    }) {
        localStorage.removeItem('token')
        localStorage.removeItem('clientId')
        localStorage.removeItem('user')
        localStorage.removeItem('dates')
        localStorage.removeItem('dates2')
        delete axios.defaults.headers.common['Authorization']
        commit('logout')
    },

}

export default {
    state,
    getters,
    actions,
    mutations
};