<template>
  <v-app class="grey lighten-4">
    <v-content>
      <div v-if="authentication">
        <navigation></navigation>
        <dropdown-filter class="pb-2"></dropdown-filter>
      </div>
      <router-view ref="pagegg"></router-view>
    </v-content>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import DropdownFilter from "./components/Filter";
export default {
  name: "App",
  components: {
    Navigation,
    DropdownFilter
  }, 
  data() {
    return {
      // authentication: this.$store.getters.isLoggedIn
    };
  },
  computed: {
    authentication() {
      return this.$store.getters.isLoggedIn;
    }
  }
};

</script>
<style >
.container {
  max-width: 1270px;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #f16361;
  float: left;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #93f552;
  float: left;
}

#table,
#table2 {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

#table td,
#table th,
#table2 td,
#table2 th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table tr:nth-child(even),
#table2 tr:nth-child(even) {
  background-color: #f2f2f2;
}

#table tr:hover,
#table2 tr:hover {
  background-color: #ddd;
}

#table th,
#table2 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  /* background-color: #4caf50; */
  background-color: #083d59;
  color: white;
}

.v-application .theme--light.green {
  background-color: #93f552 !important;
  border-color: #93f552 !important;
}
.v-application .theme--light.red {
  background-color: #f16361 !important;
  border-color: #f16361 !important;
}
#table.special tr {
  background: #f2f2f2;
}

#table.special tr:nth-child(4n + 1) {
  background: #fff;
}

#table.special tr:nth-child(4n + 2) {
  background: #fff;
}
</style>
