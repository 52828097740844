<template>
  <v-container>
    <!-- <div class="pa-1 title">ZONES</div> -->
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <v-card>
      <div ref="comparision">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-compare</v-icon>
              <span class="pa-4">ZONE COMPARISION</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="pdfClickComparision()"
                    >
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickComparision()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts class="chart" :options="chartOptions"></highcharts>
            </v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>

    <v-card class="mt-4">
      <div ref="contribution">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="8" class="pa-2">
              <v-icon size="40" color="black">mdi-chart-pie</v-icon>
              <span class="pa-4">ZONE CONTRIBUTION</span>
            </v-col>

            <v-col cols="6" md="4">
              <div class="pa-2 float-right" data-html2canvas-ignore="true">
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="primary ma-1" v-on="on" @click="pdfClickContribution()">
                      <span>PDF</span>
                      <v-icon right>mdi-file-pdf-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to PDF</span>
                </v-tooltip>-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary ma-1"
                      v-on="on"
                      @click="csvClickContribution()"
                    >
                      <span>CSV</span>
                      <v-icon right>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to CSV</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-layout wrap>
            <v-flex>
              <highcharts class="chart" :options="pipChartOptions"></highcharts>
            </v-flex>
          </v-layout>
        </v-card-text>
      </div>
    </v-card>
    <v-snackbar :timeout="6000" :top="true" v-model="showAlert">
      {{ alertMessage }}
      <v-btn color="red" text @click="showAlert = false">Close</v-btn>
    </v-snackbar>

    <!-- {{this.getChartData}} -->
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
const expo = require("../plugins/export-2");
var priorPeriodLegend = false;
var priorYearLegend = false;
export default {
  name: "zones",
  data() {
    return {
      timer1: "",
      timer2: "",
      timer3: "",
      overlay: true,
      computedCall: 3,
      passedCall: 0,
      showAlert: false,
      alertMessage: "",
      // CHART DATA
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          width: 1200,
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        plotOptions: {
          column: {
            events: {
              legendItemClick: function () {
                if (this.userOptions.index == 2) {
                  priorPeriodLegend = !priorPeriodLegend;
                } else if (this.userOptions.index == 3) {
                  priorYearLegend = !priorYearLegend;
                }
              },
            },
          },
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "",
            data: [],
            color: "green",
          },
          {
            name: "",
            data: [],
            color: "red",
          },
          {
            name: "",
            data: [],
            color: "blue",
            visible: false,
          },
          // {
          //   name: "",
          //   data: [],
          //   color: "purple",
          // },
        ],
      },
      pipChartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "pie",
          height: 440,
        },
        title: {
          text: "",
        },
        legend: {
          itemMarginTop: 2,
          // itemMarginBottom: 5
          // margin: 100
        },
        plotOptions: {
          pie: {
            // innerSize: "50%",
            showInLegend: true,
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        labels: {
          items: [
            {
              html: "current period",
              style: {
                left: "220px",
                top: "300px",
              },
            },
            {
              html: "comparision period",
              style: {
                left: "840px",
                top: "300px",
              },
            },
            // {
            //   html: "prior year",
            //   style: {
            //     left: "1000x",
            //     top: "250px",
            //   },
            // },
          ],
        },
        series: [
          {
            name: "current period",
            size: 220,
            center: [250, 110],
            data: [],
          },

          {
            name: "comparision period",
            showInLegend: false,
            size: 220,
            center: [870, 110],
            data: [],
          },
          // {
          //   name: "prior year",
          //   showInLegend: false,
          //   size: 220,
          //   center: [1000, 80],
          //   data: [],
          // },
        ],
      },
      // KPI DATA PARAMETERS
      params: {
        kpiType: "numeric",
        fetchDataFor: "line",
        dataFilter: [],
        from: "",
        to: "",
        timeFrame: "year",
        consolidateSiteData: 0,
        consolidateData: 0,
        localTimezone: "+04:00",
        showDataByOperationalHours: true,
      },
    };
  },
  methods: {
    // STORE CHART DATA INTO VUEX STORE
    setChartData() {
      // if (this.passedCall > this.computedCall) this.overlay = false;
      // this.passedCall += 1;
      var params = this.getParameters;
      // params.forEach((param) => {
      //   this.$store.dispatch("getKpiData", param);
      // });
      for (const param of params) {
        this.$store.dispatch("getKpiData", param);
      }
      // this.timer1 = setInterval(this.setChartDataAsync, 10000);
    },
    // ASSIGNING DATA TO CHART
    setChart() {
      var data = this.getChartData;

      this.chartOptions.yAxis.title.text = "";
      var label =
        data["selectedPeriodLabel"].length >= data["priorPeriodLabel"].length
          ? data["selectedPeriodLabel"]
          : data["priorPeriodLabel"];

      if (label.length > 0 && label[0] != undefined) {
        let labelSorted = new Array(label.length);
        let labelSortedZone = new Array(label.length);
        let labelSortedT = [];
        for (var s = 0; s < label.length; s++) {
          if (label[s].includes("Gate")) {
            labelSorted[s] = parseInt(label[s].replace("Gate", "").trim());
          } else if (label[s].includes("Zone") && label[s].indexOf("Zone") == 0)
            labelSortedZone[s] = label[s];
          labelSortedT[s] = label[s];
        }

        labelSorted.sort(function (a, b) {
          return a < b ? -1 : a > b ? 1 : 0;
        });

        labelSortedZone.sort(function (a, b) {
          return a < b ? -1 : a > b ? 1 : 0;
        });

        let lblSortedT = 0;
        let lblSortedZ = 0;
        for (var t = 0; t < label.length; t++) {
          if (label[t].includes("Gate")) {
            labelSortedT[t] = "Gate " + labelSorted[lblSortedT];
            lblSortedT++;
          } else if (
            label[t].includes("Zone") &&
            label[t].indexOf("Zone") == 0
          ) {
            labelSortedT[t] = labelSortedZone[lblSortedZ];
            lblSortedZ++;
          }
        }

        let selectedPeriodSorted = new Array(data["selectedPeriod"].length);
        let comparePeriodSorted = new Array(data["comparePeriod"].length);
        let priorPeriodSorted = new Array(data["priorPeriod"].length);
        //let priorYearSorted = new Array(data["priorYear"].length);

        for (var u = 0; u < label.length; u++) {
          let indexOf = label.indexOf(labelSortedT[u]);
          selectedPeriodSorted[u] = data["selectedPeriod"][indexOf];
          comparePeriodSorted[u] = data["comparePeriod"][indexOf];
          priorPeriodSorted[u] = data["priorPeriod"][indexOf];
          //priorYearSorted[u] = data["priorYear"][indexOf];
        }

        this.chartOptions.xAxis.categories = labelSortedT;
        this.chartOptions.series[0].data = selectedPeriodSorted;
        this.chartOptions.series[1].data = comparePeriodSorted;
        this.chartOptions.series[2].data = priorPeriodSorted;
        //this.chartOptions.series[3].data = priorYearSorted;
      } else {
        this.chartOptions.xAxis.categories = label;
        this.chartOptions.series[0].data = data["selectedPeriod"];
        this.chartOptions.series[1].data = data["comparePeriod"];
        this.chartOptions.series[2].data = data["priorPeriod"];
        //this.chartOptions.series[3].data = data["priorYear"];
      }

      this.chartOptions.series[0].name =
        "Current Period (" +
        moment(this.$store.getters.selectedPeriodDate[0]).format("DD/MM/YY") +
        " to " +
        moment(this.$store.getters.selectedPeriodDate[1]).format("DD/MM/YY") +
        ")";

      this.chartOptions.series[1].name =
        "Compare Period (" +
        moment(this.$store.getters.comparePeriodDate[0]).format("DD/MM/YY") +
        " to " +
        moment(this.$store.getters.comparePeriodDate[1]).format("DD/MM/YY") +
        ")";

      this.chartOptions.series[2].name =
        "Prior Week (" +
        moment(this.$store.getters.priorPeriodDate[0]).format("DD/MM/YY") +
        " to " +
        moment(this.$store.getters.priorPeriodDate[1]).format("DD/MM/YY") +
        ")";

      // this.chartOptions.series[3].name =
      //   "Prior Year (" +
      //   moment(this.$store.getters.priorYearDate[0]).format("DD/MM/YY") +
      //   " to " +
      //   moment(this.$store.getters.priorYearDate[1]).format("DD/MM/YY") +
      //   ")";

      // FOR PIE CHART
      var pieDataSelectedPeriod = [];
      var pieDataComparisionPeriod = [];
      // var pieDataPriorPeriodPeriod = [];
      var pieDataPriorYearPeriod = [];
      for (var k = 0; k < data.selectedPeriodLabel.length; k++) {
        var pieData3 = [];
        pieData3.push(data.selectedPeriodLabel[k]);
        pieData3.push(data.priorYear[k]);
        pieDataPriorYearPeriod.push(pieData3);
      }
      for (var i = 0; i < data.selectedPeriodLabel.length; i++) {
        var pieData1 = [];
        pieData1.push(data.selectedPeriodLabel[i]);
        pieData1.push(data.selectedPeriod[i]);
        pieDataSelectedPeriod.push(pieData1);
      }
      for (var l = 0; l < data.comparePeriodLabel.length; l++) {
        var pieData4 = [];
        pieData4.push(data.comparePeriodLabel[l]);
        pieData4.push(data.comparePeriod[l]);
        pieDataComparisionPeriod.push(pieData4);
      }
      // for (var j = 0; j < data.priorPeriodLabel.length; j++) {
      //   var pieData2 = [];
      //   pieData2.push(data.priorPeriodLabel[j]);
      //   pieData2.push(data.priorPeriod[j]);
      //   pieDataPriorPeriodPeriod.push(pieData2);
      // }
      // console.log(pieDataSelectedPeriod)
      // pieDataSelectedPeriod = pieDataPriorYearPeriod.sort(
      //   this.compareSecondColumn
      // );
      pieDataSelectedPeriod.sort((a, b) => a[0].localeCompare(b[0]));
      pieDataComparisionPeriod.sort((a, b) => a[0].localeCompare(b[0]));
      pieDataPriorYearPeriod.sort((a, b) => a[0].localeCompare(b[0]));

      // pieDataComparisionPeriod = pieDataComparisionPeriod.sort(
      //   this.compareSecondColumn
      // );

      // pieDataPriorYearPeriod = pieDataPriorYearPeriod.sort(
      //   this.compareSecondColumn
      // );
      // console.log(pieDataSelectedPeriod)
      //this.chartOptions.series[2].visible = priorPeriodLegend;
      //this.chartOptions.series[3].visible = priorYearLegend;
      this.pipChartOptions.series[0].data = pieDataSelectedPeriod;
      this.pipChartOptions.series[1].data = pieDataComparisionPeriod;
      //this.pipChartOptions.series[2].data = pieDataPriorYearPeriod;
      // if (data["selectedPeriod"].length > 0 && data["comparePeriod"].length > 0 && data["priorPeriod"].length > 0)
      this.overlay = false;
    },
    // compareSecondColumn(a, b) {
    //   // if (a[0] === b[0]) {
    //   //   return 0;
    //   // } else {
    //   //   return a[0] < b[0] ? -1 : 1;
    //   // }
    //   console.log(a[0].localeCompare(b[0]));
    //   return a[0].localeCompare(b[0]);
    // },
    // EXPORT CONTENT AS PDF USING THE REFERENCE ID
    pdfClickComparision() {
      var that = this;

      if (
        document.getElementsByClassName("v-navigation-drawer--mini-variant")
          .length != 1
      )
        document.querySelector(".v-navigation-drawer button").click();
      setTimeout(function () {
        if (that.isData) {
          that.showAlert = true;
          var filename = "ZONE COMPARISION.pdf";
          var content = that.$refs.comparision;
          var content1 = that.$refs.contribution;
          var text = that.pdfFilterData();
          var response = expo.createZonePDF(filename, text, content, content1);
          that.alertMessage = response;
        } else {
          that.alertMessage = "No data to export";
        }
      }, 500);
    },
    // EXPORTING VALUES TO CSV FORMAT
    csvClickComparision() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "ZONE COMPARISION.csv";
        var data = this.csvData();
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    // EXPORT CONTENT AS PDF USING THE REFERENCE ID
    pdfClickContribution() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "ZONE CONTRIBUTION.pdf";
        var content = this.$refs.contribution;
        var text = this.pdfFilterData();
        var response = expo.createPDF(filename, text, content);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },
    // EXPORTING VALUES TO CSV FORMAT
    csvClickContribution() {
      this.showAlert = true;
      if (this.isData) {
        var filename = "ZONE CONTRIBUTION.csv";
        var data = this.csvData2();
        var response = expo.createCSV(filename, data);
        this.alertMessage = response;
      } else {
        this.alertMessage = "No data to export";
      }
    },

    pdfFilterData() {
      var reportName = this.getFileName;
      var generationDate = new Date();
      var siteNames = _.map(this.$store.getters.selectedSites, "siteName");
      var zoneNames = _.map(this.$store.getters.selectedZones, "zoneName");
      var operationalHour = this.$store.state.filter.operationalHour;
      var consolidation = this.getChartName();
      var selectedPeriodDate =
        moment(this.$store.getters.selectedPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.selectedPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var selectedComparisonPeriodDate =
        moment(this.$store.getters.comparePeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.comparePeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");

      var priorPeriodDate =
        moment(this.$store.getters.priorPeriodDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorPeriodDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMM-DD-YYYY");
      var priorYearDate =
        moment(this.$store.getters.priorYearDate[0], "YYYY-MM-DD hh:mm:ss")          
          .format("MMM-DD-YYYY") +
        " to " +
        moment(
          this.$store.getters.priorYearDate[1],
          "YYYY-MM-DD hh:mm:ss"
        ).format("YYYY-MM-DD");
      //var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Selected Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Perior Period Date</td> <td> ${priorPeriodDate}</td> </tr> <tr> <td>Prior Year Date</td> <td> ${priorYearDate}</td> </tr> </tbody> </table>`;
      var table = `<table cellpadding=6 cellspacing=10> <h2>${reportName}</h2> <tbody> <tr> <td>Filter</td> <td>Selections</td> </tr> <tr> <td>Created Date</td> <td>${generationDate} </td> </tr> <tr> <td>Sites</td> <td> ${siteNames}</td> </tr> <tr> <td>Zones</td> <td> ${zoneNames}</td> </tr> <tr> <td>Operational Hour</td> <td> ${operationalHour}</td> </tr> <tr> <td>Consolidation</td> <td> ${consolidation}</td> </tr> <tr> <td>Current Period Date</td> <td> ${selectedPeriodDate}</td> </tr> <tr> <td>Comparison Period Date</td> <td> ${selectedComparisonPeriodDate}</td> </tr> <tr> <td>Perior Period Date</td> <td> ${priorPeriodDate}</td> </tr> <tr> <td>Prior Year Date</td> <td> ${priorYearDate}</td> </tr> </tbody> </table>`;
      return table;
    },
    // RECREATING DATA FOR CSV EXPORT
    csvData() {
      var data = this.getChartData;
      var zones = this.$store.state.filter.zones;
      var zoneIds = this.$store.state.filter.selectedZones;
      zones = zones.filter(function (zone) {
        return zoneIds.includes(zone._id);
      });
      var csvData = [];
      var array = _.range(0, zones.length);
      _.each(array, function (value) {
        var csvObj = {};
        var selectedPeriodData = data.selectedPeriod[value];
        var priorPeriodData = data.priorPeriod[value];
        //var priorYearData = data.priorYear[value];
        csvObj["zone"] = zones[value].zoneName;
        csvObj["currentPeriodData"] = selectedPeriodData;
        csvObj["priorWeekData"] = priorPeriodData;
        //csvObj["priorYearData"] = priorYearData;
        csvData.push(csvObj);
      });

      return csvData;
    },
    csvData2() {
      var data = this.getChartData;
      var zones = this.$store.state.filter.zones;
      var zoneIds = this.$store.state.filter.selectedZones;
      zones = zones.filter(function (zone) {
        return zoneIds.includes(zone._id);
      });
      var csvData = [];
      var selectedPeriodTotal = _.sum(data.selectedPeriod);
      var priorPeriodTotal = _.sum(data.priorPeriod);
      //var priorYearTotal = _.sum(data.priorYear);
      var array = _.range(0, zones.length);
      _.each(array, function (value) {
        var csvObj = {};
        var selectedPeriodData = data.selectedPeriod[value];
        var priorPeriodData = data.priorPeriod[value];
        //var priorYearData = data.priorYear[value];
        csvObj["zone"] = zones[value].zoneName;
        csvObj["currentPeriod"] =
          _.round((selectedPeriodData / selectedPeriodTotal) * 100, 2) ||
          0 + "%";
        csvObj["priorWeek"] =
          _.round((priorPeriodData / priorPeriodTotal) * 100, 2) || 0 + "%";
        // csvObj["priorYear"] =
        //   _.round((priorYearData / priorYearTotal) * 100, 2) || 0 + "%";
        csvData.push(csvObj);
      });

      return csvData;
    },
    getsiteIdByZone(zoneId) {
      var zones = this.$store.state.filter.zones;
      var siteId = _.filter(zones, { _id: zoneId })[0]["siteId"];
      return siteId;
    },
    // GET CONSOLIDATION STATE FROM STORE
    getConsolidation() {
      return this.$store.state.filter.consolidation;
    },
    // NAME FOR THE CHART
    getChartName() {
      var consolidation = "";
      switch (this.getConsolidation()) {
        case "hour":
          consolidation = "Hour";
          break;
        case "dayOfMonth":
          consolidation = "Day";
          break;
        case "week":
          consolidation = "Week";
          break;
        case "month":
          consolidation = "Month";
          break;
        case "year":
          consolidation = "Year";
          break;
        default:
          consolidation = "Day";
      }
      return consolidation;
    },
    PageReady(loader = true) {
      if (loader) this.overlay = true;
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);

      this.timer2 = setInterval(() => {
        if (this.$store.state.filter.selectedZones.length > 0) {
          clearInterval(this.timer2);
          this.setChartData();
          this.timer2 = setInterval(() => {
            var data = this.getChartData;
            if (
              data["comparePeriod"].length > 0 &&
              data["priorPeriod"].length > 0 &&
              data["selectedPeriod"].length > 0
            ) {
              clearInterval(this.timer2);
              this.setChart();
              this.timer3 = setInterval(() => {
                this.PageReady(false);
              }, 20000);
            }
          }, 100);
        }
      }, 100);
    },
  },
  computed: {
    // GET PARAMETERS
    getParameters() {
      var payloads = [];
      var slelectedPeriodPayload = {};
      var comparisionPeriodPayload = {};
      var priorPeriodPayload = {};
      var priorYearPayload = {};

      var params = JSON.parse(JSON.stringify(this.params));
      params.showDataByOperationalHours = this.$store.state.filter.operationalHour;
      var zoneIds = this.$store.state.filter.selectedZones;
      var dataFilter = [];
      zoneIds.forEach((zoneId) => {
        var adultMapping = {
          kpiGroupId: "5a41fd655bca800604b146cc",
          kpiId: "5a41fd655bca80kpi14b147cc",
          elementId: "Adult",
          label: "HOURLY",
          color: "#7F186E",
          showOnAxis: "y1",
          mapped: [
            {
              mappedFullSite: 0,
              siteId: this.getsiteIdByZone(zoneId),
              zones: [zoneId],
            },
          ],
        };
        var childMapping = {
          kpiGroupId: "5a41fd655bca800604b146cc",
          kpiId: "5a41fd655bca80kpi14b147cc",
          elementId: "Child",
          label: "HOURLY",
          color: "#7F186E",
          showOnAxis: "y1",
          mapped: [
            {
              mappedFullSite: 0,
              siteId: this.getsiteIdByZone(zoneId),
              zones: [zoneId],
            },
          ],
        };
        dataFilter.push(adultMapping);
        dataFilter.push(childMapping);
      });
      params.dataFilter = dataFilter;

      //SELECTED PERIOD
      var params1 = JSON.parse(JSON.stringify(params));
      params1.from = this.$store.getters.selectedPeriodDate[0];
      params1.to = this.$store.getters.selectedPeriodDate[1];
      params1.from =moment(params1.from).format("YYYY-MM-DD HH:mm:ss");
      slelectedPeriodPayload["params"] = params1;
      slelectedPeriodPayload["mutationId"] = "selectedPeriodData";
      payloads.push(slelectedPeriodPayload);

      //PRIOR PERIOD
      var params2 = JSON.parse(JSON.stringify(params));
      params2.from = this.$store.getters.priorPeriodDate[0];
      params2.to = this.$store.getters.priorPeriodDate[1];
      priorPeriodPayload["params"] = params2;
      priorPeriodPayload["mutationId"] = "priorPeriodData";
      payloads.push(priorPeriodPayload);

      //PRIOR YEAR
      var params3 = JSON.parse(JSON.stringify(params));
      params3.from = this.$store.getters.priorYearDate[0];
      params3.to = this.$store.getters.priorYearDate[1];
      priorYearPayload["params"] = params3;
      priorYearPayload["mutationId"] = "priorYearData";
      payloads.push(priorYearPayload);

      //COMPARISION PERIOD
      var params4 = JSON.parse(JSON.stringify(params));
      params4.from = this.$store.getters.comparePeriodDate[0];
      params4.to = this.$store.getters.comparePeriodDate[1];
      comparisionPeriodPayload["params"] = params4;
      comparisionPeriodPayload["mutationId"] = "comparePeriodData";
      payloads.push(comparisionPeriodPayload);

      return payloads;
    },
    // GET STORE DATA FROM VUEX STORE
    getChartData() {
      var data = {};
      var selectedPeriodData = [];
      var comparePeriodData = [];
      var priorPeriodData = [];
      var PriorYearData = [];
      var selectedPeriodLabel = [];
      var comparePeriodLabel = [];
      var priorPeriodLabel = [];
      var priorYearLabel = [];

      this.$store.state.home.selectedPeriodData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[1];
        var exIndex = selectedPeriodLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          selectedPeriodData[exIndex] = selectedPeriodData[exIndex] + value;
        } else {
          selectedPeriodLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          selectedPeriodData.push(value);
        }
      });

      this.$store.state.home.comparePeriodData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[1];
        var exIndex = comparePeriodLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          comparePeriodData[exIndex] = comparePeriodData[exIndex] + value;
        } else {
          comparePeriodLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          comparePeriodData.push(value);
        }
      });

      this.$store.state.home.priorPeriodData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[1];
        var exIndex = priorPeriodLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          priorPeriodData[exIndex] = priorPeriodData[exIndex] + value;
        } else {
          priorPeriodLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          priorPeriodData.push(value);
        }
      });

      this.$store.state.home.priorYearData.forEach((site) => {
        var value = 0;
        var labelName = site.label.split("::")[1];
        var exIndex = priorYearLabel.indexOf(labelName);
        if (exIndex > -1) {
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          PriorYearData[exIndex] = PriorYearData[exIndex] + value;
        } else {
          priorYearLabel.push(labelName);
          if (site.data.length != 0) {
            site.data.forEach((kpi) => {
              value += kpi.value;
            });
          } else {
            value = 0;
          }
          PriorYearData.push(value);
        }
      });

      data["selectedPeriodLabel"] = selectedPeriodLabel;
      data["comparePeriodLabel"] = comparePeriodLabel;
      data["priorPeriodLabel"] = priorPeriodLabel;
      data["priorYearLabel"] = priorYearLabel;
      data["selectedPeriod"] = selectedPeriodData;
      data["comparePeriod"] = comparePeriodData;
      data["priorPeriod"] = priorPeriodData;
      data["priorYear"] = PriorYearData;
      return data;
    },
    // CHECKING IF DATA IS NOT EMPTY
    isData() {
      var series = this.chartOptions.series;
      return series[0].data.length == 0 &&
        series[1].data.length == 0 &&
        series[2].data.length == 0
        ? false
        : true;
    },
  },
  // updated: function () {
  //   this.overlay = false;
  // },
  created() {
    this.$root.$refs.current_page = this;
    // this.setChartData();
    // this.setChart();
    // this.timer1 = setInterval(this.setChartDataAsync, 3000);
    // this.timer2 = setInterval(this.setChart, 3000);
  },
  beforeDestroy() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
  },
  // WATCHING FOR CHANGE IN THE CONSOLIDATION
  // watch: {
  //   getParameters: function () {
  //     this.overlay = true;
  //     this.setChartData();
  //   },
  //   getChartData: function () {
  //     this.setChart();
  //   },
  //   // overlay(val) {
  //   //   val &&
  //   //     setTimeout(() => {
  //   //       this.overlay = false;
  //   //     }, 10000);
  //   // }
  // },
};
</script>

<style>
</style>